import Grid2 from "@mui/material/Unstable_Grid2";
import React, { FC, useEffect, useState } from "react";
import { anchorsUse, FormItemInterface, getColorForms, parseWebStyle } from "../FormsConsts";

const shapeBorderStyle = {
  сплошная: { borderStyle: "solid" },
  штрих: { borderStyle: "dashed" },
  точки: { borderStyle: "dotted" },
  штрих_и_точка: {},
  без_линии: {},
  "": {},
};

interface TShapeItem extends FormItemInterface {
  Type: "TShape";
  LineStyle: "сплошная" | "штрих" | "точки" | "штрих_и_точка" | "без_линии" | "";
  PenColor?: string;
  ShapeType: "прямоугольник" | "скругленный_прямоугольник" | "эллипс" | "круг" | "";
  WidthLine?: string;
  BrushColor?: string;
  BrushStyle?: string;
  webStyles?: string;
}

interface TShapeProps {
  children: React.ReactNode;
  json: TShapeItem;
}

const TShape: FC<TShapeProps> = ({ children, json }) => {
  const [shapeStyle, setShapeStyle] = useState({});

  useEffect(() => {
    const {
      Width: width,
      Height: height,
      Top: top,
      Left: left,
      BrushColor: bgColor,
      PenColor: borderColor,
      BrushStyle: bgStyle,
      WidthLine: borderWidth,
      LineStyle: borderStyle,
      ShapeType: shapeType,
    } = json;
    const shapeTypeObj = {
      прямоугольник: {},
      скругленный_прямоугольник: { borderRadius: "10%" },
      эллипс: { clipPath: `ellipse(48% 48%)` },
      круг: { borderRadius: "50%" },
      "": {},
    };
    const finalBg = bgStyle === "без_заливки" ? "transparent" : getColorForms(json, bgColor);
    const webStyles = parseWebStyle(json.webStyles);
    let style: React.CSSProperties = {
      ...shapeBorderStyle[borderStyle],
      ...shapeTypeObj[shapeType],
      position: "absolute",
      left: `${left}px`,
      top: `${top}px`,
      width: `${width}px`,
      height: `${height}px`,
      overflowY: "auto",
      overflowX: "hidden",
      display: json.Visible === "0" ? "none" : "",
      zIndex: 0,
      backgroundColor: finalBg,
      borderWidth: `${borderWidth}px`,
      borderColor: `${getColorForms(json, borderColor)}`,
      ...webStyles,
    };
    style = anchorsUse({ json, jsonStyle: style });
    setShapeStyle(style);
  }, [json]);

  return (
    <Grid2 style={shapeStyle} name={json.Name || json.keyName} type={"TShape"}>
      {children}
    </Grid2>
  );
};

export default TShape;
