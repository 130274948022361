import Grid2 from "@mui/material/Unstable_Grid2";
import React, { FC, useEffect, useState } from "react";
import { anchorsUse, FormItemInterface, getColorForms, TextFromServerToBrowser } from "../FormsConsts";

interface TLabelItem extends FormItemInterface {
  Type: "TLabel";
}

interface TLabelProps {
  children: React.ReactNode;
  json: TLabelItem;
  onClick?: void;
}

const TLabel: FC<TLabelProps> = ({ children, json, onClick }) => {
  const [labelStyle, setLabelStyle] = useState({});

  useEffect(() => {
    const { Width: width, Height: height, Top: top, Left: left, AutoSize: autoSize } = json;
    let style: React.CSSProperties = {
      position: "absolute",
      left: `${left}px`,
      top: `${top}px`,
      width: autoSize !== "1" ? `${width}px` : "",
      height: autoSize !== "1" ? `${height}px` : "",
      overflowY: "hidden",
      overflowX: "hidden",
      display: json.Visible === "0" ? "none" : "",
      zIndex: 0,
    };
    style = anchorsUse({ json, jsonStyle: style });
    setLabelStyle(style);
  }, [json]);

  return (
    <Grid2
      style={labelStyle}
      keyName={json.Name || json.keyName}
      type={"TLabel"}
      namePopupMenu={json.NamePopupMenu}
      name={json.Name || json.keyName}
    >
      <TextFromServerToBrowser json={json} onClick={onClick}>
        {children}
      </TextFromServerToBrowser>
    </Grid2>
  );
};

export default TLabel;
