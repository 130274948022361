import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { isAdminAtom } from "../../../../../atoms";
import { useAtomValue } from "jotai";

interface PeriodValue {
  id: number;
  title: string;
}

interface TicketsListTableFilterProps {
  closedChecked: number;
  setClosedChecked: (checked: number) => void;
  onlyMyChecked: number;
  setOnlyMyChecked: (checked: number) => void;
  filterGridByDescription: (value: string) => void;
  period: string;
  setPeriod: (value: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export const periodValues: PeriodValue[] = [
  { id: 0, title: "весь период" },
  { id: 1, title: "год" },
  { id: 2, title: "месяц" },
];

export default function TicketsListTableFilter({
  closedChecked,
  setClosedChecked,
  onlyMyChecked,
  setOnlyMyChecked,
  filterGridByDescription,
  period,
  setPeriod,
  handleSubmit,
}: TicketsListTableFilterProps) {
  const flatProps = {
    options: periodValues.map((option) => option.title),
  };

  const [descriptionInput, setDescriptionInput] = useState<string>("");
  const isAdmin = useAtomValue(isAdminAtom);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      filterGridByDescription(descriptionInput);
      setDescriptionInput(descriptionInput);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid2
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
          style={{ width: "100%" }}
        >
          <Grid2 item xs={2}>
            <FormControl fullWidth variant="outlined">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(closedChecked)}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setClosedChecked(Number(event.target.checked))}
                  />
                }
                label="Не закрытые"
              />
            </FormControl>
          </Grid2>
          <Grid2 item xs={2}>
            <FormControl fullWidth variant="outlined">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(onlyMyChecked)}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setOnlyMyChecked(Number(event.target.checked))}
                    disabled={!isAdmin}
                  />
                }
                label="Только мои"
              />
            </FormControl>
          </Grid2>
          <Grid2 item xs={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="findDescription" size="small">
                Искать в описании
              </InputLabel>
              <OutlinedInput
                id="findDescription"
                name="findDescription"
                value={descriptionInput}
                label={"Искать в описании"}
                style={{ height: "42px" }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setDescriptionInput(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
          </Grid2>
          <Grid2 item xs={3}>
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                disablePortal
                {...flatProps}
                id="period"
                value={period}
                onChange={(e: React.SyntheticEvent, newValue: string | null) => {
                  if (newValue !== null) {
                    setPeriod(newValue);
                  }
                }}
                renderInput={(params) => <TextField {...params} label="Заявки за" />}
              />
            </FormControl>
          </Grid2>
          <Grid2 item xs={2}>
            <FormControl fullWidth variant="outlined">
              <Button
                size="large"
                variant="outlined"
                style={{ textTransform: "none", marginRight: "10px" }}
                type="submit"
              >
                Обновить
              </Button>
            </FormControl>
          </Grid2>
        </Grid2>
      </form>
    </>
  );
}
