import { IconButton } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import React from "react";

export default function CalendarButton(props) {
  return (
    <IconButton
      ref={props.buttonRef}
      disabled={props.disabled}
      aria-describedby={props.id}
      onClick={(ev) => {
        if (props.disabled) return;
        props.handleClick(ev);
      }}
      key={`calendar_${props.itemId}`}
      className="calendar-button"
      style={{
        width: "20px",
        height: "100%",
        borderRadius: "0px",
        padding: "0",
        display: props.EditStyle_Calendar ? "" : "none",
      }}
    >
      <CalendarTodayIcon style={{ maxHeight: "17px" }} />
    </IconButton>
  );
}
