import { DelphiColors } from "../../MainPage/Tools/ProjectX";
import { setColor } from "../testEditor";

export const colorList = [
  { label: "Черный", id: "0", color: setColor(DelphiColors.clBlack) },
  { label: "Бордовый", id: "128", color: setColor(DelphiColors.clMaroon) },
  { label: "Зеленый", id: "32768", color: setColor(DelphiColors.clGreen) },
  { label: "Коричневый", id: "32896", color: setColor(DelphiColors.clOlive) },
  { label: "Темно-синий", id: "8388608", color: setColor(DelphiColors.clNavy) },
  { label: "Пурпурный", id: "8388736", color: setColor(DelphiColors.clPurple) },
  { label: "Темно-зеленый", id: "8421376", color: setColor(DelphiColors.clTeal) },
  { label: "Серый", id: "8421504", color: setColor(DelphiColors.clGray) },
  { label: "Светло-серый", id: "12632256", color: setColor(DelphiColors.clSilver) },
  { label: "Красный", id: "255", color: setColor(DelphiColors.clRed) },
  { label: "Светло-зеленый", id: "65280", color: setColor(DelphiColors.clLime) },
  { label: "Желтый", id: "65535", color: setColor(DelphiColors.clYellow) },
  { label: "Голубой", id: "16711680", color: setColor(DelphiColors.clBlue) },
  { label: "Фиолетовый", id: "16711935", color: setColor(DelphiColors.clFuchsia) },
  { label: "Светло-голубой", id: "16776960", color: setColor(DelphiColors.clAqua) },
  { label: "Белый", id: "16777215", color: setColor(DelphiColors.clWhite) },
  { label: "Подсказка", id: "14811135", color: setColor(DelphiColors.clInfoBk) },
];
