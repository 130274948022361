import React, { useRef, useMemo, useState, useEffect } from "react";
import JoditEditor from "jodit-react";
import { parseCommand, stmjsonClick } from "../MainPage/NotWorkArea(Side&Head)/Header/Header";

const onEditorClick = (e) => {
  if (!e.target) return;
  const target = e.target.closest("a");
  if (!target) return;
  e.preventDefault();
  const command = parseCommand(target.href);
  if (command.command) stmjsonClick(command);
  else if (target.href.includes("mailto")) {
    window.location.href = target.href.match(/mailto:[^]*/)?.[0];
  } else window.open(target.href);
};

const keyPass = [
  "F1",
  "F2",
  "F3",
  "F4",
  "F5",
  "F6",
  "F7",
  "F8",
  "F9",
  "F10",
  "F11",
  "F2",
  "CapsLock",
  "Shift",
  "Alt",
  "Meta",
  "Tab",
  "Control",
  "Insert",
  "Pause",
  "ScrollLock",
  "NumLock",
  "PageUp",
  "PageUpDown",
  "Escape",
  "End",
  "Home",
  "ContextMenu",
];

// Jodit.plugins.add("preparePaste", preparePaste);

const TextEditor = ({ inputValue, setValue, readonly, height, setInputValue }) => {
  const [defaultValue, setDefaultValue] = useState(inputValue.replaceAll("<a ", '<a style="cursor:pointer;" '));
  const boolRef = useRef(false);
  const editor = useRef(null);
  const div = useRef(null);

  useEffect(() => {
    fullHeightAuto();
  }, [editor.current]);

  useEffect(() => {
    setDefaultValue(inputValue.replaceAll("<a ", '<a style="cursor:pointer;" '));
  }, [inputValue]);

  const config = useMemo(
    () => ({
      height: `calc(100% - ${height})`,
      readonly: readonly ? readonly : false,
      addNewLine: false,
      iframe: true,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      saveModeInStorage: true,
      allowTabNavigation: true,
      events: {
        click: onEditorClick,
        change: (newHtmlContent) => {
          if (boolRef.current) {
            boolRef.current = false;
            const textContent = getTextContent();
            setInputValue(newHtmlContent, textContent);
          }
        },
        keydown: (e) => {
          if (keyPass.includes(e.code)) return;
          boolRef.current = true;
        },
        paste: () => {
          boolRef.current = true;
        },
        drop: () => {
          boolRef.current = true;
        },
        beforeCommand: () => {
          boolRef.current = true;
        },
      },
      // extraPlugins: ["preparePaste"],
      allowResizeX: false,
      allowResizeY: false,
      toolbarButtonSize: "small",
      editHTMLDocumentMode: false,
      toolbarAdaptive: false,
      saveModeInStorage: false,
      saveHeightInStorage: false,
      disablePlugins: "add-new-line,about,clean-html,placeholder,class-span,inline-popup",
      buttons: "bold,italic,underline,font,fontsize,indent,outdent,left,brush,undo,redo,source,print",
      removeButtons: ["undo", "redo"],
      uploader: {
        insertImageAsBase64URI: true,
      },
    }),
    [],
  );

  const deleteLink = () => {
    const link = document.getElementsByClassName("jodit-status-bar-link");
    if (link && link.length > 0) {
      link[0].style.display = "none";
    }
  };

  const getTextContent = () => {
    if (!editor.current) return "";
    if (div.current) {
      const contentBlock = div.current.querySelector(".jodit-workplace");
      if (!contentBlock) return "";
      return contentBlock.outerText;
    }
  };

  const fullHeightAuto = (elem) => {
    const JWorkPlace = elem ? elem.parentElement : document.getElementsByClassName("jodit-workplace")[0];
    if (JWorkPlace) {
      // setTimeout(() => {
      //   JWorkPlace.style.height = `calc(100% - ${"33px"})`;
      // }, 1000);
      // JWorkPlace.classList.add("joditHeight");
      // JWorkPlace.firstChild.style.height = "100%";
      if (!elem) {
        JWorkPlace.firstChild.onfocus = function (e) {
          fullHeightAuto(e.target);
          deleteLink();
        };
      }
    }
  };

  return (
    <div style={{ height: `calc(100% - ${height})` }} ref={div}>
      <JoditEditor
        ref={editor}
        value={defaultValue}
        config={config}
        tabIndex={1} // tabIndex of textarea
        onBlur={(newHtmlContent, e) => {
          try {
            if (e.relatedTarget.className.includes("CloseButtonForGrid")) return;
            const textContent = getTextContent();
            fullHeightAuto();
            setValue(newHtmlContent, textContent);
            // setEditorValue(newContent);
          } catch {
            console.error("Потеря фокуса");
            editor.current && editor.current.focus();
          }
        }}
      />
    </div>
  );
};

export default TextEditor;
