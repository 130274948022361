import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { FormEvent, useEffect, useRef, useState } from "react";
import { GridLoader } from "../../../rcsgrid/createGridTools";
import SplitterLocal from "../../../Tools/Splitter";
import { fetchGridData, filterGridDataByDescription, getPeriodValueByName, Ticket } from "./SupportRequestConst";
import TicketsListDetails from "./TicketsListDetails";
import TicketsListTableFilter, { periodValues } from "./TicketsListTableFilter";
import React from "react";
import { useAtomValue } from "jotai";
import { isAdminAtom } from "../../../../../atoms";

interface Column {
  title: string;
  width: number;
  fieldName: string;
}

interface Field {
  Name: string;
  Title: string;
  Width: string;
}

const setCollumsForGridSigns = (): { title: Column[] } => {
  const columns = {
    title: [
      { title: "Время", width: 100, fieldName: "Time" },
      { title: "Номер", width: 100, fieldName: "Number" },
      { title: "Тема", width: 200, fieldName: "Topic" },
      { title: "Срок", width: 100, fieldName: "Term" },
      { title: "Статус", width: 180, fieldName: "Status" },
      { title: "Исполнитель", width: 180, fieldName: "Executor" },
      { title: "Решение", width: 180, fieldName: "Solution" },
      { title: "Пользователь", width: 180, fieldName: "User" },
    ],
  };
  return columns;
};

const getDefaultFieldsSigns = (): Field[] => {
  return [
    { Name: "ID", Title: "ID", Width: "7" },
    { Name: "Index", Title: "Индекс", Width: "7" },
    { Name: "Time", Title: "Время", Width: "10" },
    { Name: "Number", Title: "Номер", Width: "10" },
    { Name: "Topic", Title: "Тема", Width: "10" },
    { Name: "Term", Title: "Срок", Width: "10" },
    { Name: "Status", Title: "Статус", Width: "10" },
    { Name: "Executor", Title: "Исполнитель", Width: "10" },
    { Name: "Solution", Title: "Решение", Width: "10" },
    { Name: "User", Title: "Пользователь", Width: "10" },
    { Name: "Description", Title: "Описание", Width: "10" },
  ];
};

const recordKeys = [
  "ID",
  "Index",
  "Time",
  "Number",
  "Topic",
  "Term",
  "Status",
  "Executor",
  "Solution",
  "User",
  "Description",
];

const cacheRecords: Ticket[] = [];

export default function TicketsList() {
  const [selectedRecordID, setSelectedRecordID] = useState<string>("0");
  const [closedChecked, setClosedChecked] = useState<number>(1);
  const [onlyMyChecked, setOnlyMyChecked] = useState<number>(1);
  const [period, setPeriod] = useState<string>(periodValues[2].title);
  const [description, setDescription] = useState<string>("");
  const [solution, setSolution] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const samplesGridRef = useRef<any>(null);
  const records = useRef<any[]>([]);
  const isAdmin = useAtomValue(isAdminAtom);

  const loadGrid = async (): Promise<void> => {
    const columnsData: { title: Column[] } = setCollumsForGridSigns();
    if (!isAdmin) {
      columnsData.title = columnsData.title.filter((column) => column.title !== "Пользователь");
    }

    const { grid, source } = await GridLoader(
      samplesGridRef.current,
      {},
      {
        defaultFields: getDefaultFieldsSigns(),
        keyField: "ID",
        getRecords: () => records.current,
        recordKeys: recordKeys,
        titleKey: "",
        localGrid: true,
        Columns: columnsData,
        indicator: false,
        canModify: () => false,
      },
    );

    const desc = await grid.source.getFieldText("Description");
    setDescription(desc);
    const sol = await grid.source.getFieldText("Solution");
    setSolution(sol);
    const stat = await grid.source.getFieldText("Status");
    setStatus(stat);

    source.onRecordIndexChanged = function (source: any) {
      source.getFieldText("ID").then(async (id: string) => {
        setSelectedRecordID(id);
        const desc = await grid.source.getFieldText("Description");
        setDescription(desc);
        const sol = await grid.source.getFieldText("Solution");
        setSolution(sol);
        const stat = await grid.source.getFieldText("Status");
        setStatus(stat);
      });
    };

    const gridData = records.current as Ticket[];
    cacheRecords.splice(0, cacheRecords.length);
    cacheRecords.push(...gridData);
  };

  useEffect(() => {
    const periodValue = getPeriodValueByName(period);
    fetchGridData(
      {
        onlyOpen: closedChecked,
        onlyMy: onlyMyChecked,
        period: periodValue,
      },
      records,
      () => loadGrid(),
    );
  }, [closedChecked, onlyMyChecked, period]);

  useEffect(() => {
    if (selectedRecordID !== "0") {
      //console.log("Событие переключения на другую запись!");
    }
  }, [selectedRecordID]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const periodValue = getPeriodValueByName(period);
    fetchGridData(
      {
        onlyOpen: closedChecked,
        onlyMy: onlyMyChecked,
        period: periodValue,
      },
      records,
      () => loadGrid(),
    );
  };

  const updateGridSize = (): void => {
    const grid = samplesGridRef.current.grid;
    if (grid) grid.updateGridSize();
  };

  const filterGridByDescription = async (description: string) => {
    records.current = filterGridDataByDescription(cacheRecords, description);

    if (samplesGridRef.current.grid) {
      await samplesGridRef.current.grid.refreshSource("!");
      const source = samplesGridRef.current.grid.source;
      source.onRecordIndexChanged(source);
    }
  };

  return (
    <Grid2
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      style={{ width: "100%", height: "100%" }}
    >
      <SplitterLocal
        orientation="horizontal"
        sizeSecondary={"50%"}
        onExpand={updateGridSize}
        onCollapse={updateGridSize}
      >
        <Grid2 item>
          <Grid2 item xs={12} style={{ marginTop: "5px" }}>
            <TicketsListTableFilter
              closedChecked={closedChecked}
              setClosedChecked={setClosedChecked}
              onlyMyChecked={onlyMyChecked}
              setOnlyMyChecked={setOnlyMyChecked}
              filterGridByDescription={filterGridByDescription}
              period={period}
              setPeriod={setPeriod}
              handleSubmit={handleSubmit}
            />
          </Grid2>
          <Grid2 style={{ width: "100%", height: "280px" }} item xs={12}>
            <div style={{ position: "relative", height: "280px" }}>
              <div
                rcspanel=""
                ref={samplesGridRef}
                tabIndex={0}
                style={{
                  height: "100%",
                  width: "100%",
                }}
              ></div>
            </div>
          </Grid2>
        </Grid2>

        <Grid2 item xs={12}>
          <TicketsListDetails
            description={description}
            solution={solution}
            recordId={selectedRecordID}
            status={status}
          />
        </Grid2>
      </SplitterLocal>
    </Grid2>
  );
}
