import dayjs, { Dayjs } from "dayjs";
import { AxiosRequest } from "../../../../Url";
import { periodValues } from "./TicketsListTableFilter";
import { getIntOfStrDate } from "../../../Tools/Tools";

export interface Ticket {
  ID: string;
  Index: number;
  Time: string;
  Number: string;
  Topic: string;
  Term: string;
  Status: string;
  Executor: string;
  Solution: string;
  User: string;
  Description: string;
}

interface Filter {
  onlyOpen: number;
  onlyMy: number;
  period: string | undefined;
}

interface ResponseItem {
  $tag: string;
  Время: string;
  Номер: string;
  Тема: string;
  Срок: string;
  Статус: string;
  Исполнитель: string;
  Решение: string;
  Пользователь: string;
  Описание: string;
}

export async function getAttachedFiles(
  files: FileList | File[],
): Promise<Record<string, { ИмяФайла: string; RCDATA: string }>> {
  const filesArray = Array.from(files);
  let result: Record<string, { ИмяФайла: string; RCDATA: string }> = {};
  let index = 0;

  for (const file of filesArray) {
    const rcdata = await getRcdata(file);
    const itemKey = `item${index}`;
    const item = {
      [itemKey]: {
        ИмяФайла: file.name,
        RCDATA: rcdata,
      },
    };
    result = { ...result, ...item };
    index++;
  }

  return result;
}

function getRcdata(file: File): Promise<string> {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target?.result) {
        const rcdata = event.target.result.toString().split(",");
        resolve(rcdata[1]);
      }
    };
  });
}

export function fetchGridData(
  filter: Filter,
  recordsRef: React.MutableRefObject<Ticket[]>,
  afterLoad: () => void,
): void {
  const params = new Map<string, string | number | undefined>();
  params.set("prefix", "config");
  params.set("comand", "GetSupportInfo");
  params.set("Active", filter.onlyOpen);
  params.set("User", filter.onlyMy);
  params.set("Date", filter.period);
  params.set("smart", "1");

  AxiosRequest(true, params).then((res: { error: boolean; [key: string]: any }) => {
    if (!res.error) {
      const data = handleResponse(res);
      recordsRef.current = data;
      afterLoad();
    }
  });
}

function handleResponse(response: Record<string, ResponseItem>): Ticket[] {
  const arrRecords: Ticket[] = [];
  if (response) {
    Object.values(response)
      .filter((item) => item.Номер != null)
      .forEach((item, index) => {
        arrRecords.push({
          ID: item.$tag,
          Index: index,
          Time: item.Время,
          Number: item.Номер,
          Topic: item.Тема,
          Term: item.Срок,
          Status: item.Статус,
          Executor: item.Исполнитель,
          Solution: item.Решение,
          User: item.Пользователь,
          Description: item.Описание,
        });
      });
  }

  return arrRecords;
}

export function getPeriodValueByName(name: string): string | undefined {
  const period = periodValues.find((value) => value.title === name);
  const periodId = period ? period.id : undefined;

  switch (periodId) {
    case 0:
      return "0";
    case 1: {
      const oneYearBeforeDate: Dayjs = dayjs().subtract(1, "year");
      return getIntOfStrDate(oneYearBeforeDate.format("YYYY-MM-DD")).toString();
    }
    case 2: {
      const oneMonthBeforeDate: Dayjs = dayjs().subtract(1, "month");
      return getIntOfStrDate(oneMonthBeforeDate.format("YYYY-MM-DD")).toString();
    }
    default:
      return undefined;
  }
}

export function getCurrentDateTimeForScreenshot(): string {
  const currentDate = dayjs();
  return currentDate.format("YYYY.MM.DD_HH.mm.ss");
}

export function filterGridDataByDescription(array: Ticket[], description: string): Ticket[] {
  if (!(description == null || description.trim() === "")) {
    return array.filter((record) => record.Description.toLowerCase().includes(description.toLowerCase()));
  }

  return array;
}
