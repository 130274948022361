import React, { FC } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import HelpIcon from "@mui/icons-material/Help";
import IconMenuButton from "../../Tools/IconMenuButton";
import QuizIcon from "@mui/icons-material/Quiz";
import { downloadErrorFile, isDeveloper } from "../../Tools/Tools";
import { openModal } from "../../Tools/modalManager";
import DialogInstallScriptsUpdates from "../../Module/Dialogs/DialogInstallScriptsUpdate";
import DialogAboutSite from "../../Module/Dialogs/DialogAboutSite";
import { useAtomValue } from "jotai";
import { appConfig, helpContextAtom, smetaStore } from "../../../../atoms";
import DialogHelpContext from "../../Module/Dialogs/DialogHelpContext";
import { CheckModalBackGrounds } from "../../../TokenProcessing/TokenProcessing";
import { GetOptionsForUser } from "../../../Url";
import SupportRequest from "./SupportRequest/SupportRequest";

const openUpdtInstall = () => {
  openModal(<DialogInstallScriptsUpdates props={{}} />, {
    Title: "Установка обновлений",
    style: { height: `${400}px`, width: `${620}px`, minHeight: `${400}px`, minWidth: `${620}px` },
    hiddenButton: true,
  });
};

const openAboutSite = () => {
  openModal(<DialogAboutSite />, {
    Title: "О сайте",
    style: { height: `${530}px`, width: `${530}px` },
    Buttons: {
      jsonData: { Кнопка$Отмена: "Закрыть" },
      Params: { Buttons: 2 },
    },
    blockMaximize: true,
    blockSaveLocation: true,
    blockResize: true,
  });
};

export const openHelpContextDialog = () => {
  const helpContext = smetaStore.get(helpContextAtom);
  if (CheckModalBackGrounds() === 0 && helpContext) {
    const config = smetaStore.get(appConfig);
    if (config.smeta.openHelpContextInNewTab) {
      window.open(helpContext);
    } else {
      openModal(<DialogHelpContext />, {
        Title: "Справка",
        style: { height: `${500}px`, width: `${620}px` },
        hiddenButton: true,
        blockMaximize: true,
      });
    }
  }
};

const openSupportRequest = () => {
  openModal(<SupportRequest />, {
    Title: "Заявка в службу поддержки",
    style: {
      height: "750px",
      width: "950px",
      minHeight: "750px",
      minWidth: "950px",
      overflow: "hidden",
    },
    hiddenButton: true,
    blockMaximize: true,
    blockSaveLocation: true,
  });
};

const SupportButton: FC = () => {
  const theme = useSelector((state) => state.theme.theme);
  // const isDev: boolean = isDeveloper();
  const isLk = GetOptionsForUser()?.from;
  const helpContext = useAtomValue(helpContextAtom);

  if (isLk === "lk") return null;
  return (
    <IconMenuButton
      title="Справка"
      items={[
        {
          label: "Вызов справки",
          onClick: openHelpContextDialog,
          icon: <QuizIcon className={cn("iconButtonStimate", { light: theme === "light" })} />,
          shortCut: "F1",
          hidden: !helpContext,
        },
        { label: "Заявка в службу поддержки", onClick: openSupportRequest },
        { label: "divider" },
        { label: "Проверить наличие обновлений", onClick: openUpdtInstall },
        { label: "Файл с ошибками", onClick: downloadErrorFile },
        { label: "О сайте...", onClick: openAboutSite },
      ]}
    >
      <HelpIcon className={cn("iconButtonStimate", { light: theme === "light" })} />
    </IconMenuButton>
  );
};

export default SupportButton;
