import React, { FC, CSSProperties, ReactNode } from "react";
import pinned from "./../../../../static/images/pinned.png";
import unpinned from "./../../../../static/images/unpinned.png";

const SmetaIcon: FC<{ src: string; alt: string; className?: string; style?: CSSProperties }> = ({
  src,
  alt,
  className,
  style,
}) => {
  return <img src={src} alt={alt} className={className} style={{ ...style }} />;
};

const PinnedIcon = () => {
  return <SmetaIcon src={pinned} alt="pinned" className="pinned-icon" style={{ height: "20px" }} />;
};

const UnPinnedIcon = () => {
  return <SmetaIcon src={unpinned} alt="unpinned" className="unpinned-icon" style={{ height: "20px" }} />;
};

interface SmetaTabItemProps {
  children: ReactNode;
  style?: CSSProperties;
  pinned?: boolean;
  label?: string;
  closable?: boolean;
  data?: unknown;
  title?: string;
}

const SmetaTabItem: FC<SmetaTabItemProps> = ({ children, style }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "0px",
        display: "inline-block",
        boxSizing: "border-box",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export { PinnedIcon, UnPinnedIcon, SmetaTabItem, SmetaIcon };
