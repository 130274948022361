import { Button, ClickAwayListener, Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { AxiosRequest } from "../../../Url";
import React from "react";
import TestEditor from "../../../Editor/testEditor";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactDOM from "react-dom";
import { CreateBackgroundForModal, tokenProcessingTest } from "../../../TokenProcessing/TokenProcessing";
import DialogSetObjText from "../../Module/Dialogs/DialogSetObjText";
import DialogCreateObj from "../../Module/Dialogs/DialogCreateObj";
import ModalWindow from "../../Module/ModalWindow";
import { getDefaultMenu, getStyleWindow, setColor } from "../../Tools/Tools";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CircleIcon from "@mui/icons-material/Circle";
import CheckBoxMultiCheck from "./CheckBoxMultiCheck";
import LoadingMask from "../../NotWorkArea(Side&Head)/LoadingMask";
import HistoryIcon from "@mui/icons-material/History";
import TextAreaWithButtons from "../../Module/Dialogs/Components/TextAreaWithButtons";
import ContextMenu from "../../NotWorkArea(Side&Head)/ContextMenu";
import DialogHistory from "../../Module/Dialogs/DialogHistory";
import Scrollbars from "react-custom-scrollbars-2";
import ConditionButton from "./ParamsComponents/ConditionButton";
import DialogSaveSample from "../../Module/Dialogs/DialogSaveSample";
import { HandleSamples } from "../../Tools/Requests";
import { openModal } from "../../Tools/modalManager";

// опции стилей текста названия параметра
const FontStyle_Bold = 1;
const FontStyle_Italic = 2;
const FontStyle_Underline = 4;
const FontStyle_StrikeOut = 8;

// опции параметров
const ParamSetOption_Disabled = 1; // +
const ParamSetOption_InConfig = 2; // -
const ParamSetOption_Calculated = 4; // -
const ParamSetOption_Detalization = 8; // -
const ParamSetOption_Markable = 16; // -
const ParamSetOption_Collapsed = 32; // +
const ParamSetOption_Reference = 64; // -
const ParamSetOption_RedNegative = 128; // -
const ParamSetOption_Inspector = 256; // -
const ParamSetOption_Grid = 512; // -
const ParamSetOption_Sortable = 1024; // -
const ParamSetOption_Filterable = 2048; // -
const ParamSetOption_Override = 4096; // -
const ParamSetOption_Hidden = 8192; // +
const ParamSetOption_History = 16384; // +-
const ParamSetOption_CanGroup = 32768; // -
const ParamSetOption_Fixed = 65536; // -
const ParamSetOption_NotEdit = 131072; // -

// функции для сплиттера с возможностью изменения размеров полей
var resize = {};

// функция включения live изменения размеров
function onMouseDown(ev) {
  resize.resizeMode = true;
  resize.target = ev.target;
  resize.col = ev.target.closest("TABLE").firstChild.firstChild;
}
// функция установки размеров после изменения
function onMouseUp(ev, setWidth) {
  if (resize.size !== undefined) {
    setWidth(resize.size);
    resize = {};
  }
}

// функция live изменения размеров
function onMouseMove(ev) {
  if (resize.resizeMode) {
    let size = ev.clientX - resize.target.clientWidth - resize.target.getBoundingClientRect().x;
    size =
      (resize.col.clientWidth / resize.col.parentElement.clientWidth) * 100 +
      (size / resize.col.parentElement.clientWidth) * 100;
    if (size < 30) size = 30;
    if (size > 70) size = 70;
    resize.size = size;
    resize.col.style.width = `${size}%`;
  }
}

// функция получения параметров
function getParams(module, command, groupId, needRefresh, Path, SectionID, prm, reportID) {
  const params = new Map();
  params.set("prefix", module).set("comand", command).set("GroupID", groupId);
  if (needRefresh) params.set("NeedRefresh", needRefresh);
  if (Path) params.set("Path", Path);
  if (SectionID) params.set("SectionID", SectionID);
  if (prm) params.set("prm", prm);
  if (reportID) params.set("ReportID", reportID);
  const json = AxiosRequest(false, params);
  return json;
}

// функция расчета отступов для параметров в завизимости от уровня вложенности
function getPadding(items, item, index) {
  let padding = 15;
  const level = item.Level ? item.Level : 0;

  if (items.length - 1 > index && level < items[index + 1].Level && checkNextItemsVisible(items, item, index)) {
    padding -= 15;
  }
  if (item.Options & ParamSetOption_Markable) {
    padding -= 15;
  }
  if (item.Level) {
    padding += item.Level * 15;
  }
  return padding;
}

// функция для выбора запроса
// возвращает функицю в которую нужно передать data
// пример: getSetParamRequest(selector)({data})
function getSetParamRequest(selector) {
  const requests = {
    marker: (data) => {
      const params = new Map();
      params
        .set("prefix", "programs")
        .set("comand", "SetParamProperty")
        .set("Path", data.Path)
        .set("ID", data.ID)
        .set("GroupID", data.GroupID ? data.GroupID : "0")
        .set("Marked", data.Marked);
      return AxiosRequest(false, params);
    },
    default: (data) => {
      const params = new Map();
      params
        .set("prefix", "programs")
        .set("comand", "SetParamProperty")
        .set("Path", data.Path)
        .set("ID", data.ID)
        .set("GroupID", data.GroupID ? data.GroupID : "0")
        .set("ObjRef", data.ObjRef ? data.ObjRef : "0");
      if (data.CheckState !== undefined) {
        params.set("CheckState", data.CheckState);
      }
      if (data.Value !== undefined) {
        params.set("Value", data.Value);
      }
      if (data.Condition !== undefined) {
        params.set("Condition", data.Condition);
      }
      if (data.MultiSelect || data.Selection) {
        params.set("Selection", data.Selection);
      }
      if (data.ListIndex) {
        params.set("ListIndex", data.ListIndex);
      }
      params.set("TextChanged", data.TextChanged).set("WSM", "1");
      return AxiosRequest(false, params);
    },
  };
  return selector && requests[selector] ? requests[selector] : requests.default;
}

// запрос следующего или предыдущего состояния параметров
function getHistory(data) {
  const params = new Map();
  params.set("prefix", "programs").set("comand", "GetParamDialog").set("Path", data.Path).set("History", data.history);
  return AxiosRequest(true, params);
}

// функция для перекодирования целого числа в цвет

// функция установки стилей для текста названия параметра
function getStyleName(item) {
  return {
    color: item.NameColor ? setColor(item.NameColor) : "",
    fontWeight: item.NameStyle & FontStyle_Bold ? "bold" : "",
    fontStyle: item.NameStyle & FontStyle_Italic ? "italic" : "",
    textDecoration:
      item.NameStyle & FontStyle_Underline ? "underline" : item.NameStyle & FontStyle_StrikeOut ? "line-through" : "",
  };
}

// функция для установки стилей для текста значения параметра
function getStyleColor(item) {
  // костыль
  // понять как кодируется этот цвет
  // if (true) {
  //   return "#aaaaaa";
  // }

  if (item.TextColor !== undefined) {
    return setColor(item.TextColor);
  }

  return "";
}

function getStyleText(item) {
  const style = {};
  if (item.TextStyle !== undefined) {
    style.fontWeight = item.TextStyle & FontStyle_Bold ? "bold" : "";
    style.fontStyle = item.TextStyle & FontStyle_Italic ? "italic" : "";
    style.textDecoration =
      item.TextStyle & FontStyle_Underline ? "underline" : item.TextStyle & FontStyle_StrikeOut ? "line-through" : "";
  }
  return style;
}

function checkNextItemsVisible(items, item, index) {
  let result = false;
  const level = item.Level ? Number(item.Level) : 0;
  for (let i = index + 1; i < items.length; i++) {
    if (level + 1 !== Number(items[i].Level)) return result;
    if (items[i].Options & 1) {
      result = false;
    } else {
      return true;
    }
  }
  return result;
}

// На вход необходимо передать:

// Обязательно:
// Module - Модуль используемый для запросов параметров
// reqCommand - команда используемая для запросов параметров
// data - объект параметров с данными

// По необходимости:
// Path - Путь используемый для запросов параметров(если есть)
// GroupID - ID элемента для которого используются параметры, для запроса параметров под конкретный элемент(по необходимости)
// SectionID - ID секции для которой используются парамеры(по необходимости)
// prm - данные для запроса параметров (по необходимости)
// reportID - ID отчета для запроса параметров (по необходимости)

// Настройки разметки:
// NoHistory - значение true отключает кнопки истории
// NoTitle - значение true отключает заголовок параметров
export default function TestParams(props) {
  // переменная для хранения данных об истории
  const [history, setHistory] = React.useState();
  // полные данные параметров
  const [paramsData, setParamData] = React.useState();
  // данные записей
  const [paramsItems, setParamsItems] = React.useState();
  // записи для отрисовки
  const [paramsJsx, setParamsJsx] = React.useState();

  const [paramsButtons, setParamsButtons] = React.useState([]);

  const [paramsButtonsJsx, setParamsButtonsJsx] = React.useState([]);

  const [title, setTitle] = React.useState("");

  const [filter, setFilter] = React.useState(props.defaultFilter);

  const [loading, setLoading] = React.useState(false);

  // размер 1-ой колонки для контроля
  const [width, setWidth] = React.useState(35);
  // размер контейнера таблицы с параметрами в зависимости от настроек
  const [height, setHeight] = React.useState();
  const [buttonBlockWidth, setbuttonBlockWidth] = React.useState(0);
  // переменная для хранения элемента с редактором
  const editor = React.useRef();

  // карта с функциями редактирования отрисованных редакторов
  const [editionMap, setEditionMap] = React.useState();

  // хук для получения функций редактирования и записи их в карту выше
  const [getEdition, setGetEdition] = React.useState();

  // переменная для динамической отрисовки редактора, контролирует отрисвку по нажатой клавише мыши
  const mouseDown = React.useRef(null);

  // ссылка на контейнер параметров
  const paramsBox = React.useRef();

  const needOpenEditor = React.useRef();

  // Первичная установка объекта параметров
  React.useEffect(() => {
    // получение данных если они не были переданны из вне
    if (props.data) {
      setParamData(props.data);
    }

    paramsBox.current.update = (data) => {
      // setParamsJsx(null);
      // setEditorMap(new Map());
      // setEditionMap(new Map());

      deleteEditor(editor);
      setParamData(data);
    };

    paramsBox.current.focus = () => {
      if (editor.current && editor.current.style && editor.current.style.display !== "none") {
        const input = editor.current.querySelector("input[type=text]");
        if (input) {
          input.focus();
          // проверка на readOnly
          const record = editor.current.closest("TR");
          if (record && record.dataset.editstyle & 64) return;
          input.select();
        }
      } else {
        const record = paramsBox.current.querySelector("TR");
        if (record) record.click();
      }
    };

    // инициализация карт хранения
    setEditionMap(new Map());
    // установка высоты блока параметров
    setHeight(getHeight());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // при изменении данных снаружи они обновляются
  React.useEffect(() => {
    if (props.data) {
      // setEditorMap(new Map());
      // setEditionMap(new Map());
      // setParamsJsx(null);
      deleteEditor(editor);
      setParamData(props.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  React.useEffect(() => {
    if (props.fixHistory && props.setFixHistory) {
      setHistory(props.fixHistory);
      props.setFixHistory(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fixHistory]);

  React.useEffect(() => {
    if (props.currentPage !== undefined && paramsData) {
      setParamsItems(
        paramsData.Items.filter((item) => {
          if (props.currentPage === 0) {
            return item.PageIndex === undefined || Number(item.PageIndex) === 0;
          }

          return Number(item.PageIndex) === props.currentPage;
        }),
      );

      const match = props.pageTitles[props.currentPage].Name.match(/[^\\]+$/);
      if (match) {
        setTitle(match[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentPage]);

  React.useEffect(() => {
    if (history) {
      setHeight(getHeight());
    }
  }, [history]);

  React.useEffect(() => {
    if (filter !== undefined) {
      setHeight(getHeight());
    }
  }, [filter]);

  React.useEffect(() => {
    // обновление параметров при изменении общих данных
    if (paramsData) {
      // скрытие текущего редактора если он был открыт
      if (editor.current) {
        deleteEditor(editor.current);
      }

      // обновление массива записей
      if (props.currentPage !== undefined && paramsData) {
        setParamsItems([
          ...paramsData.Items.filter((item) => {
            if (props.currentPage === 0) {
              return item.PageIndex === undefined || Number(item.PageIndex) === 0;
            }

            return Number(item.PageIndex) === props.currentPage;
          }),
        ]);
        const match = props.pageTitles[props.currentPage].Name.match(/[^\\]+$/);
        if (match) {
          setTitle(match[0]);
        }
      } else {
        setParamsItems([...paramsData.Items]);

        if (paramsData.Title) {
          // любой символ с конца кроме "\"
          const match = paramsData.Title.match(/[^\\]+$/);
          if (match) {
            setTitle(match[0]);
          }
        }
      }

      if (filter === undefined) {
        setFilter(paramsData.Filter ? true : false);
      }
      // обновление данных истории
      setHistory(paramsData.History);

      if (paramsData?.Buttons?.items?.length) {
        const result = [];
        paramsData.Buttons.items.forEach((item, index) => {
          item.Caption && item.Hidden !== "1" && result.push({ ...item, id: index });
        });
        setParamsButtons([...result]);
      }

      paramsBox.current.dialogButtonClick = (id, ProgID) => {
        dialogButtonClick({ currentTarget: { id } }, paramsData.Path, ProgID);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsData]);

  React.useEffect(() => {
    // обновление отрисованных записей
    if (paramsItems) {
      setParamsJsx(getJsxParams(paramsItems, setWidth));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsItems]);

  React.useEffect(() => {
    if (paramsButtons.length === 0) return;
    setParamsButtonsJsx(getParamsButtons(paramsButtons));
  }, [paramsButtons]);

  // заполнение карты с функциями редактирования при отрисовке нового редактора
  React.useEffect(() => {
    if (getEdition) {
      editionMap.set(getEdition.id, {
        setValue: getEdition.setValue,
        setInputValue: getEdition.setInputValue,
        value: getEdition.value,
        inputValue: getEdition.inputValue,
        setSelection: getEdition.setSelection,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEdition]);

  // разворачивание записей при первой отрисовке, если они должны быть развернуты
  React.useLayoutEffect(() => {
    if (paramsJsx) {
      paramsBox.current.querySelectorAll("#expandButtonParams").forEach((el) => {
        if ((Number(el.dataset.collapsed) & ParamSetOption_Collapsed) !== ParamSetOption_Collapsed) {
          el.closest("TR").dataset.collapsed = "1";
          expandRec({ target: el });
        }
      });

      if (needOpenEditor.current) {
        setTimeout(() => {
          if (editor.current) {
            needOpenEditor.current = editor.current.closest("TR");
            renderEditor({ target: needOpenEditor.current });
            needOpenEditor.current = undefined;
            return;
          }
          renderEditor({ target: needOpenEditor.current });
          needOpenEditor.current = undefined;
        }, 1);
      }
    }
  }, [paramsJsx]);

  // функция для получения кнопки разворачивания записей
  function getExpandOrMarkButton(items, item, index) {
    const level = item.Level ? item.Level : 0;
    if (items.length - 1 > index && level < items[index + 1].Level && checkNextItemsVisible(items, item, index)) {
      return (
        <IconButton
          id="expandButtonParams"
          data-collapsed={item.Options}
          name="expandButton"
          onClick={async (ev) => {
            const record = ev.target.closest("tr");
            await collapseReq(
              record.id.split("_")[1],
              props.Path ? props.Path : paramsData.Path,
              record.dataset.collapsed === "1" ? "0" : "1",
            );
            expandRec(ev);
          }}
          style={{
            height: "15px",
            width: "15px",
            transform: Number(item.Options) & ParamSetOption_Collapsed ? "rotate(-90deg)" : "",
            transition: "all 0.2s",
          }}
        >
          <ExpandMoreIcon style={{ height: "15px", width: "15px" }} />
        </IconButton>
      );
    }

    if (Number(item.Options) & ParamSetOption_Markable) {
      return (
        <IconButton
          id="markButtonParams"
          title="Маркировать"
          data-enabled="false"
          onClick={async (ev) => {
            const bttn = ev.target.closest("#markButtonParams");
            const icon = bttn.querySelector("path");
            if (bttn.dataset.enabled === "true") {
              icon.style.color = "#cccccc";
              bttn.dataset.enabled = "false";
              bttn.title = "Маркировать";
            } else {
              icon.style.color = "#006cbf";
              bttn.dataset.enabled = "true";
              bttn.title = "Снять маркировку";
            }
            const record = ev.target.closest("tr");
            record.dataset.marked = bttn.dataset.enabled === "true" ? "1" : "0";

            await getSetParamRequest("marker")({
              Path: props.Path ? props.Path : paramsData.Path,
              ID: record.id.split("_")[1],
              GroupID: paramsData.GroupID,
              Marked: record.dataset.marked,
            });
          }}
          style={{
            height: "5px",
            width: "5px",
          }}
        >
          <CircleIcon style={{ height: "5px", width: "5px", color: "#cccccc" }} />
        </IconButton>
      );
    }
  }

  // запрос сворачивания и разворачивания записи для сервера
  function collapseReq(id, Path, collapsed) {
    const params = new Map();
    params
      .set("prefix", "programs")
      .set("comand", "ParamCollapsed")
      .set("ID", id)
      .set("Path", Path)
      .set("Collapsed", collapsed);
    return AxiosRequest(true, params);
  }

  // Функция скрытия и отображения записей при клике на кнопку expandButton
  function expandRec(ev) {
    // получение кнопки на текущей записи
    let button = ev.target.closest("BUTTON");
    // получение текущей записи
    let record = ev.target.closest("TR");
    // получение уровня вложенности
    let level = record.dataset.level;
    // получение следующей записи
    let nextRec = record.nextElementSibling;

    // получение id родительской записи
    // const parentID = record.id.split("_")[1];

    //выражение для проверки состояния развернутости записи
    const condition = record.dataset.collapsed === "1";
    // сокрытие или раскрытие всех дочерних записей у родительской
    // цикл работает до тех пор, пока уровень вложенности следующей записи больше чем уровень первоначальной записи
    while (nextRec && nextRec.dataset.level > level) {
      // проверка привязки к родительской записи
      // if (nextRec.dataset.parentid === parentID) {
      // проверка на наличие вложенных записей
      const nextButton = nextRec.querySelector("button[name=expandButton]");
      // если вложенные записи были развернуты то они свернутся при сворачивании записей родителя
      if (nextButton && nextRec.dataset.collapsed === "0") {
        nextRec.dataset.collapsed = "1";
        nextButton.style.transform = "rotate(-90deg)";
      }
      // сокрытие или раскрытие записей в зависимости от условия
      nextRec.style.display = condition ? "" : "none";
      // проверка привязки к родительской записи
      if (
        nextRec.dataset.level !== `${Number(level) + 1}` ||
        Number(nextRec.dataset.options) & ParamSetOption_Disabled
        // Number(nextRec.dataset.options) & ParamSetOption_Hidden
      ) {
        nextRec.style.display = "none";
      }
      // } else {
      //   nextRec.style.display = "none";
      // }
      nextRec = nextRec.nextElementSibling;
    }

    // поворот кнопки при сворачивании
    button.style.transform = condition ? "rotate(0deg)" : "rotate(-90deg)";
    record.dataset.collapsed = condition ? "0" : "1";
  }

  function onCloseSelection(data) {
    const editval =
      data.record.dataset.editval !== undefined
        ? data.record.dataset.editval
        : data.record.dataset.value !== undefined
        ? data.record.dataset.value
        : "";
    const value =
      data.record.dataset.value !== undefined
        ? data.record.dataset.value
        : data.record.dataset.editval !== undefined
        ? data.record.dataset.editval
        : "";

    data.setValue({
      label: editval,
      id: data.record.dataset.objref !== undefined ? data.record.dataset.objref : "",
    });
    data.setInputValue(editval);
    data.record.querySelector('[textvalue="true"]').innerText = value;
  }

  // функция на редактирование параметров
  async function onEdit(data) {
    needOpenEditor.current = data.record;

    const textValueDiv = data.record.querySelector('[textvalue="true"]');
    // если нет изменений значения или id, или состояния чекбокса, то устанавливается старое
    if (data.value === undefined) data.value = data.record.dataset.value;
    if (data.tag === undefined) data.tag = data.record.dataset.objref;
    if (data.CheckState === undefined) {
      data.CheckState = data.record.dataset.checkstate;
    }
    if (data.Condition === undefined) {
      data.Condition = data.record.dataset.condition;
    }

    mouseDown.current = false;
    if (data.value !== undefined) textValueDiv.innerText = data.value;
    setLoading(true);
    // получение запроса для редактирования и ответа
    const res = await getSetParamRequest()({
      Path: props.Path ? props.Path : paramsData.Path,
      ID: data.record.id.split("_")[1],
      GroupID: paramsData.GroupID,
      ObjRef: data.tag,
      Value: data.value,
      Condition: data.Condition,
      CheckState: data.CheckState,
      MultiSelect: data.multiselect,
      Selection: data.selection,
      ListIndex: data.listindex,
      TextChanged: data.textchanged !== undefined ? data.textchanged : "1",
    });

    setLoading(false);

    props.onEditParams?.(res, data);
    // Установка значений параметров
    settingValues(res, data);
  }

  // функция установки локальных значений параметров
  function settingValues(res, data) {
    // при наличии Токена установка идет через токен процесс
    if (res) {
      if (res.Token) {
        tokenProcessingTest(res, {
          func: (val) => {
            settingValues(val, data);
          },
        });
        return;
      }

      // открытие окна с выбором элементов при неточном вводе в ручную
      if (res.Selection && res.Selection.length) {
        if (res.Selection.length === 1) {
          onEdit({
            ...data,
            value: res.Selection[0].text,
            tag: res.Selection[0].id,
          });
          return;
        }

        openModal(
          <DialogSetObjText
            list={res.Selection}
            onEdit={onEdit}
            record={data.record}
            CheckState={data.CheckState}
            onClose={() => onCloseSelection(data)}
          />,
          {
            Title: "Выберите элемент из списка",
            style: getStyleWindow(),
            hiddenButton: true,
            blockMaximize: true,
            onClose: () => onCloseSelection(data),
          },
        );
        return;
      }

      // открытие окна с созданием нового объекта
      if (res.CreateNew) {
        openModal(
          <DialogCreateObj
            onEdit={onEdit}
            inputNameObj={res.CreateNew.Text}
            spanText={res.CreateNew.Label}
            CLSID={res.CreateNew.CLSID}
            ObjType={data.datatype}
            Module={data.type}
            groups={res.CreateNew.Groups}
            record={data.record}
            CheckState={data.CheckState}
          />,
          {
            Title: "Создать новый объект",
            style: getStyleWindow(`${res.CreateNew.CLSID}${res.CreateNew.Groups ? "Group" : ""}`),
            hiddenButton: true,
            blockMaximize: true,
          },
        );
        return;
      }

      if (!res.Values) return;

      // обновление значений из ответа
      res.Values.forEach((item) => {
        // редактируемая запись
        const rec = data.record;
        // контейнер со значением параметра
        const textValueDiv = rec.querySelector('[textvalue="true"]');

        let inpValue = item.EditVal ? item.EditVal : item.Value !== undefined ? item.Value : "";

        let txtFldValue = item.Value !== undefined ? item.Value : item.EditVal !== undefined ? item.EditVal : "";

        const index = rec.id.split("_")[2];
        const paramitem = paramsData.Items[index];
        paramitem.EditVal = inpValue;
        paramitem.Value = item.Value ? item.Value : "";
        paramitem.ObjRef = item.ObjRef ? item.ObjRef : "0";
        if (item.CheckState) {
          paramitem.CheckState = item.CheckState;
        }

        if (data.multiselect) {
          if (item.Selection !== undefined) {
            paramitem.Selection = item.Selection;
          }
        }

        // заполнение записи данными
        rec.dataset.editval = inpValue;
        rec.dataset.value = item.Value ? item.Value : "";
        rec.dataset.objref = item.ObjRef ? item.ObjRef : "0";

        if (item.CheckState !== undefined) {
          if (data.setStateCheckBox) data.setStateCheckBox(item.CheckState);
          rec.dataset.checkstate = item.CheckState;
        }

        textValueDiv.innerText = txtFldValue;

        textValueDiv.style.color =
          textValueDiv.innerText < 0 && rec.dataset.options & ParamSetOption_RedNegative ? "red" : getStyleColor(item);

        if (data.multiselect) {
          if (item.Selection !== undefined) {
            rec.dataset.selection = item.Selection;
          }
        }

        // обновление значения в редакторе
        if (editor.current) {
          const edition = editionMap.get(rec.id);
          if (edition) {
            if (data.fixVal) {
              inpValue = data.fixVal(inpValue);
            }
            edition.setValue({
              label: inpValue,
              id: item.ObjRef ? item.ObjRef : "0",
            });
            edition.setInputValue(inpValue);
            if (data.multiselect) {
              if (item.Selection !== undefined) {
                edition.setSelection(item.Selection);
              } else {
                edition.setSelection("");
              }
            }
          }
        }
      });

      setParamData({ ...paramsData });
      // при необходимости обновить все записи перерисуются (запрос с данными по необходимости)
      needRefreshParams(res);
    }
  }

  async function needRefreshParams(res) {
    if (res.NeedRefresh === "1" && !props.notRefresh) {
      if (props.getRefreshParams) {
        const json = await props.getRefreshParams(props, paramsData);
        deleteEditor(editor);
        setParamData(json);
        return;
      }
      if (props.Module === "documents") {
        // временный способ получения данных параметров
        let params = new Map();
        params.set("prefix", "documents");
        params.set("comand", "GetDocumentLayout");
        params.set("DocCfgID", props.id);
        params.set("SectionID", props.SectionID);
        params.set("GridState", 1);
        AxiosRequest(false, params).then((res) => {
          deleteEditor(editor);
          setParamData(res.Params);
        });
        return;
      }
      setLoading(true);
      getParams(
        props.Module,
        props.reqCommand,
        props.GroupID,
        "1",
        paramsData.Path,
        props.SectionID,
        props.prm,
        props.reportID,
      ).then((res) => {
        deleteEditor(editor);
        setParamData(res);
        setLoading(false);
      });
    }
  }

  // функция открытия модального окна в редакторе
  async function onEditButtonClick(data) {
    if (data.subData.props.editParam !== undefined) {
      const params = new Map();
      params
        .set("prefix", "programs")
        .set("comand", "EditParamProperty")
        .set("ID", data.subData.props.record.id.split("_")[1])
        .set("Path", props.Path ? props.Path : paramsData.Path)
        .set("WSM", "1");
      const json = await AxiosRequest(true, params);
      if (json && json.Token)
        tokenProcessingTest(json, {
          subData: data.subData,
          func: (res) => {
            needRefreshParams(res);
          },
        });
      return;
    }

    if (data.json) {
      tokenProcessingTest(data.json, data.subData);
      return;
    }

    if (data.datatype <= 12 && data.datatype > 0) {
      CreateBackgroundForModal(
        <ModalWindow Title={data.subData.props.record.dataset.name} style={getStyleWindow()} hiddenButton={true}>
          <TextAreaWithButtons
            props={{
              ...data.subData.props,
              setValue: data.setValue,
              setInputValue: data.setInputValue,
              Title: data.subData.props.record.dataset.name,
            }}
          />
        </ModalWindow>,
      );
      return;
    }
  }

  // функция значений для выпадающего списка в редакторе
  async function onDropDownList(data, checklist) {
    const params = new Map();
    params
      .set("prefix", "programs")
      .set("comand", "GetParamValues")
      .set("Path", props.Path ? props.Path : paramsData.Path)
      .set("ID", data.record.id.split("_")[1]);
    const json = await AxiosRequest(true, params);
    if (json && json.Items) {
      return json.Items.map((item, index) => {
        if (typeof item !== "object") {
          if (checklist) {
            return { label: item, id: `${Math.pow(2, index)}` };
          }
          return { label: item, id: `${index}` };
        }
        if (checklist) {
          return { label: item.text, id: `${Math.pow(2, index)}` };
        }
        return { label: item.text, id: item.id };
      });
    }
  }

  // функция для рендера или сокрытия/раскрытия редактора
  function renderEditor(ev) {
    if (!ev.target) return;
    // выбранная запись
    const record = ev.target.closest("TR");

    if (!record) return;
    // проверка на отображение редактора
    if (record.querySelector('div[editor="true"]').style.display !== "none") return;

    record.style.backgroundColor = "whitesmoke";

    // контейнер для редактора
    const editorDiv = record.querySelector('[editor="true"]');
    // контейнер для текста параметров
    const textValueDiv = record.querySelector('[textvalue="true"]');
    // удаление(скрытие) другого отрисованного редактора
    if (editor.current && editor.current !== editorDiv) {
      deleteEditor(editor.current);
    }

    editorDiv.style.display = "flex";
    textValueDiv.style.display = "none";
    // отрисовка редактора
    ReactDOM.render(
      <>
        <TestEditor
          placeholder=" "
          styleInput={getStyleText({ TextStyle: record.dataset.textstyle })}
          disabled={record.dataset.locked === "1" ? true : false}
          groupid={record.dataset.groupid}
          current={record.dataset.current}
          fieldname={record.dataset.fieldname}
          name={record.dataset.name}
          type={record.dataset.module}
          record={record}
          objref={record.dataset.objref}
          value={record.dataset.editval}
          datatype={record.dataset.datatype}
          editStyle={record.dataset.editstyle}
          checkstate={record.dataset.checkstate}
          onEdit={onEdit}
          onEditButtonClick={onEditButtonClick}
          onDropDownList={onDropDownList}
          getEdition={setGetEdition}
          editParam={record.dataset.onedit}
          multisel={record.dataset.options & ParamSetOption_Detalization}
          selection={record.dataset.selection}
          editMask={record.dataset.editmask}
          // disabled={false}
          func={settingValues}
        />
      </>,
      editorDiv,
    );
    // }

    // отрисованный редактор
    editor.current = editorDiv;

    // фокус на поле ввода
    const input = editorDiv.querySelector("input[type=text]");
    if (input) {
      input.focus();
      // проверка на readOnly
      if (record.dataset.editstyle & 64) return;
      input.select();
    } else {
      const textArea = editorDiv.querySelector("textarea");
      if (!textArea) return;
      textArea.focus();
      // проверка на readOnly
      if (record.dataset.editstyle & 64) return;
      textArea.select();
    }
  }

  // функция скрытия редактора и отображения текста параметров
  function deleteEditor(editor) {
    if (editor && editor.style && editor.style.display !== "none") {
      const record = editor.closest("TR");
      record.style.backgroundColor = "";
      const textValueDiv = record.querySelector('[textvalue="true"]');
      editor.style.display = "none";
      textValueDiv.style.display = "flex";
    }
  }

  // функция на нажатие стрелок
  // проверяет следующий элемент на отображение и рисует в нем редактор
  function onKeyArrow(ev) {
    let record;
    switch (ev.key) {
      case "ArrowDown":
        record = ev.target.closest("TR").nextElementSibling;
        if (record) {
          if (record.style.display === "none") {
            while (record !== null) {
              record = record.nextElementSibling;
              if (record && record.style.display !== "none") {
                renderEditor({ target: record });
                break;
              }
            }
          } else {
            renderEditor({ target: record });
          }
        }

        break;
      case "ArrowUp":
        record = ev.target.closest("TR").previousElementSibling;
        if (record) {
          if (record.style.display === "none") {
            while (record !== null) {
              record = record.previousElementSibling;
              if (record && record.style.display !== "none") {
                renderEditor({ target: record });
                break;
              }
            }
          } else {
            renderEditor({ target: record });
          }
        }
        break;
      default:
        break;
    }
  }

  // функция установки размеров блока с кнопками
  function getButtonColWidth(options, other) {
    let res = 0;
    if (options & ParamSetOption_History) {
      res += 20;
    }
    if (other) {
      res += other.Conditions ? 20 : 0;
    }
    return res;
  }

  // Функция отрисовки записей для таблицы параметров
  function getJsxParams(items) {
    let colWidth;
    const returnArr = items.map((item, index) => {
      const expandButton = getExpandOrMarkButton(items, item, index);
      // условие для отрисовки чекбокса отдельно
      const editStyle = item.EditStyle & 4 ? item.EditStyle - 4 : item.EditStyle;

      colWidth = getButtonColWidth(item.Options, {
        Conditions: item.Conditions,
      });
      if (buttonBlockWidth < colWidth) {
        setbuttonBlockWidth(colWidth);
      }
      return (
        <tr
          onMouseEnter={(ev) => {
            // hover
            const record = ev.target.closest("TR");
            if (!record) return;
            if (editor.current && editor.current.closest("TR") !== record) record.style.backgroundColor = "#f5f5f5";
            // динамический рендер редактора
            if (mouseDown) {
              if (mouseDown.current) renderEditor(ev);
            }
          }}
          onMouseLeave={(ev) => {
            // hover off
            const record = ev.target.closest("TR");
            if (!record) return;
            if (editor.current && editor.current.closest("TR") !== record) record.style.backgroundColor = "";
          }}
          onClick={(ev) => {
            renderEditor(ev);
          }}
          onKeyDown={(ev) => {
            onKeyArrow(ev);
          }}
          style={{
            display:
              item.Level ||
              Number(item.Options) & ParamSetOption_Disabled ||
              Number(item.Options) & ParamSetOption_Hidden
                ? "none"
                : "",
          }}
          // данные параметра хранятся внутри записи
          id={`param_${item.ID}_${index}`}
          key={`param-key_${item.ID}_${index}`}
          data-datatype={item.DataType}
          data-editstyle={editStyle}
          data-fieldname={item.FieldName}
          data-name={item.Name}
          data-mapid={item.MapID}
          data-module={item.Module}
          data-options={item.Options}
          data-orderno={item.OrderNo}
          data-shortname={item.ShortName}
          data-level={item.Level ? item.Level : 0}
          data-parentid={item.ParentID}
          data-collapsed={(Number(item.Options) & ParamSetOption_Collapsed) === 32 ? "1" : "0"}
          data-value={item.Value}
          data-editval={item.EditVal !== undefined ? item.EditVal : item.Value}
          data-objref={item.ObjRef}
          data-groupid={paramsData.GroupID}
          data-current={item.Current}
          data-checkstate={item.CheckState}
          data-marked={"0"}
          data-locked={item.Locked}
          data-onedit={item.OnEdit}
          data-condition={item.Condition !== undefined ? item.Condition : item.Conditions ? "1" : undefined}
          data-textstyle={item.TextStyle}
          data-selection={item.Selection}
          data-editmask={item.EditMask}
        >
          {/* Имя параметра */}
          <td
            id={`name_${item.ID}`}
            title={item.Name}
            style={{
              height: "27px",
              borderCollapse: "collapse",
              backgroundColor: "#c9c8c82e",
              border: "0.1px solid",
              borderRight: "0",
              borderColor: "#e0e0e0",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              userSelect: "none",
              paddingLeft: getPadding(items, item, index),
              fontSize: "90%",
            }}
          >
            {expandButton}
            <span style={getStyleName(item)}>{item.Name}</span>
          </td>
          {/* Колонка для кнопок история, выражение и тд */}
          <td
            style={{
              borderCollapse: "collapse",
              backgroundColor: "#c9c8c82e",
              overflow: "hidden",
              border: "0.1px solid",
              borderLeft: "0",
              borderRight: "0",
              borderColor: "#e0e0e0",
              height: "27px",
              width: getButtonColWidth(item.Options, {
                Conditions: item.Conditions,
              }),
            }}
          >
            <IconButton
              onClick={() => {
                const paramsRequest = new Map();
                paramsRequest
                  .set("prefix", "programs")
                  .set("Path", props.Path ? props.Path : paramsData.Path)
                  .set("ID", item.ID);
                openModal(
                  <DialogHistory
                    paramsRequest={paramsRequest}
                    Module={item.Module}
                    EditStyle={item.EditStyle}
                    DataType={item.DataType}
                    Options={item.Options}
                    Values={item.Values}
                    onSelect={(json) => {
                      settingValues(
                        { Values: [json.CheckState === undefined ? { ...json, CheckState: "0" } : json] },
                        {
                          record: paramsBox.current.querySelector(`#param_${item.ID}_${index}`),
                        },
                      );
                    }}
                  />,
                  {
                    Title: item.Name,
                    style: getStyleWindow(),
                    hiddenButton: true,
                    blockMaximize: true,
                    blockSaveLocation: true,
                  },
                );
              }}
              style={{
                height: "15px",
                width: "15px",
                display: item.Options & ParamSetOption_History ? "" : "none",
              }}
            >
              <HistoryIcon style={{ height: "15px", width: "15px" }} />
            </IconButton>
            <ConditionButton
              visible={item.Conditions ? true : false}
              conditions={item.Conditions}
              condition={item.Condition ? item.Condition : "1"}
              onEdit={onEdit}
              recID={`param_${item.ID}_${index}`}
              paramsBoxRef={paramsBox}
            />
          </td>
          {/* Разделитель */}
          <td
            onMouseDown={onMouseDown}
            style={{
              cursor: "col-resize",
              userSelect: "none",
              backgroundColor: "#e0e0e0",
            }}
            id="splitter-ID"
          >
            <br></br>
          </td>
          {/* Значение параметра */}
          <td
            id={`value_${item.ID}`}
            style={{
              borderCollapse: "collapse",
              overflow: "hidden",
              border: "0.1px solid",
              borderLeft: "0",
              borderColor: "#e0e0e0",
              height: "27px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              display: "flex",
            }}
          >
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid
                item
                style={{
                  width: "15px",
                  marginRight: "5px",
                  display: item.EditStyle & 4 ? "" : "none",
                }}
              >
                {/* чекбокс с множеством состояний */}
                <CheckBoxMultiCheck onEdit={onEdit} MultiCheckSet={item.MultiCheckSet} CheckState={item.CheckState} />
              </Grid>
              {/* текстовое значение параметра */}
              <Grid
                item
                style={{
                  fontSize: "0.75rem",
                  paddingLeft: "3px",
                  width: item.EditStyle & 4 ? "calc(100% - 20px)" : "100%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  letterSpacing: "0px",
                  display: "flex",
                  color: item.Value < 0 && item.Options & ParamSetOption_RedNegative ? "red" : getStyleColor(item),
                  ...getStyleText(item),
                }}
                textvalue={"true"}
              >
                {item.Value}
              </Grid>

              {/* контейнер для редактора */}
              <Grid
                item
                style={{
                  display: "none",
                  height: "100%",
                  width: item.EditStyle & 4 ? "calc(100% - 20px)" : "100%",
                }}
                editor={"true"}
              ></Grid>
            </Grid>
          </td>
        </tr>
      );
    });

    return returnArr;
  }

  // функция для получения высоты параметров
  function getHeight() {
    const historyHeight = props.NoHistory ? 0 : history ? 30 : 0;
    const title = props.NoTitle ? 0 : 30;
    const filterHeight = filter ? 30 : 0;
    const correction = historyHeight + title + filterHeight;
    return `calc(100% - ${correction}px)`;
  }

  // функция для вызова истории
  async function historyClick(num) {
    const json = await getHistory({
      Path: props.Path ? props.Path : paramsData.Path,
      history: num,
    });
    // обновление параметров
    if (json) {
      // setEditorMap(new Map());
      // setEditionMap(new Map());
      setParamsJsx(null);
      deleteEditor(editor);
      setParamData({ ...json });
    }
  }

  async function ContextMenuHandler(data) {
    const comand = data.value;
    let json;
    switch (comand) {
      case "SelectSample":
        deleteEditor(editor);
        json = await HandleSamples({
          Path: props.Path ? props.Path : paramsData.Path,
          Command: "load",
          Name: data.name,
        });
        deleteEditor(editor);
        setParamsJsx(null);
        setParamData(json);

        break;
      case "DeleteSample":
        json = await HandleSamples({
          Path: props.Path ? props.Path : paramsData.Path,
          Command: "drop",
          Name: data.name,
        });
        if (json.Result === "1") {
          paramsData.Sample = undefined;
          deleteEditor(editor);
          setParamsJsx(null);
          setParamData({ ...paramsData });
        }
        break;
      case "SaveSample":
        CreateBackgroundForModal(
          <ModalWindow Title={"Сохранение шаблона параметров"} style={getStyleWindow("Sample")} hiddenButton={true}>
            <DialogSaveSample
              props={{
                Path: props.Path ? props.Path : paramsData.Path,
                value: paramsData.Sample,
              }}
            />
          </ModalWindow>,
        );
        break;
      case "ClearValues":
        json = await HandleSamples({
          Path: props.Path ? props.Path : paramsData.Path,
          Command: "reset",
          SectionID: props.SectionID,
        });
        deleteEditor(editor);
        setParamsJsx(null);
        setParamData({ ...json });
        break;
      case "FilterParams":
        setFilter(!filter);
        break;
      default:
        break;
    }
  }

  async function onMenuClick() {
    const params = new Map();
    params.set("prefix", "programs").set("comand", "HandleSamples");
    const json = await AxiosRequest(true, params, {
      Path: props.Path ? props.Path : paramsData.Path,
      Command: "list",
    });
    return { json: json, Sample: paramsData.Sample, filter: filter };
  }

  async function filterOnEdit(data) {
    let json = await HandleSamples({
      Path: props.Path ? props.Path : paramsData.Path,
      Command: "filter",
      Name: data.value,
    });
    setParamsJsx(null);
    setParamData({ ...json });
  }

  const dialogButtonClick = async (ev, Path, ProgID) => {
    const params = new Map();
    const id = ev.currentTarget.id;
    params.set("prefix", "programs");
    params.set("comand", "DialogButtonClick");
    params.set("Path", Path);
    params.set("ProgID", ProgID || "0");
    params.set("WSM", "1");
    params.set("ID", Number(id) + 1);
    let json = await AxiosRequest(true, params);
    json.Token &&
      tokenProcessingTest(json, {
        func: (res) => {
          needRefreshParams(res);
        },
      });
  };

  const getParamsButtons = (buttons) => {
    return buttons.map((item, index) => {
      const margin = 32 * index;
      return (
        <Button
          onClick={(ev) => dialogButtonClick(ev, paramsData.Path)}
          id={item.id}
          key={`paramsButton-${index}`}
          variant="outlined"
          style={{
            position: "fixed",
            textTransform: "none",
            width: "100px",
            height: "28px",
            borderRadius: "0",
            marginTop: `${margin}px`,
          }}
        >
          {item?.Caption}
        </Button>
      );
    });
  };

  return (
    // при клике вне контейнера параметров редактор скроется
    <ContextMenu
      for={"params"}
      Menu={getDefaultMenu("HandleParams", {
        NoClearState: props.NoClearState ? true : false,
        NoSaveSample: props.NoSaveSample ? true : false,
      })}
      onRightClick={onMenuClick}
      onMenuItemClick={ContextMenuHandler}
    >
      <ClickAwayListener
        onClickAway={(ev) => {
          mouseDown.current = false;

          if (!editor.current || editor.current.style.display === "none") {
            return;
          }

          if (
            ev.target.closest("[checklist]") ||
            ev.target.closest("[calendar]") ||
            (ev.target.closest(".background_ModalDialog") &&
              ev.target.closest(".background_ModalDialog") !== paramsBox.current.closest(".background_ModalDialog")) ||
            ev.target.closest("LI") ||
            ev.target.classList.contains("MuiBackdrop-root") ||
            ev.target.closest("#applyButton")
          ) {
            return;
          }
          if (editor.current) {
            editor.current.closest("TR").style.backgroundColor = "";
            deleteEditor(editor.current);
          }
        }}
      >
        <Box
          id={"paramsBox"}
          ref={paramsBox}
          onMouseDown={(ev) => {
            mouseDown.current = true;
          }}
          onMouseUp={() => {
            mouseDown.current = false;
          }}
          style={{ height: "100%", backgroundColor: "#FFFFFF" }}
        >
          {loading && (
            <div style={{ height: "100%", width: "100%", position: "absolute", zIndex: "1", opacity: "0.5" }}>
              <LoadingMask />
            </div>
          )}
          {/* <Box style={{ height: "max-content" }}> */}
          <Scrollbars
            autoHide
            style={{
              height: props.NoTitle ? "0" : "32px",
              width: "100%",
              border: "1px solid #eeeeee",
              backgroundColor: "#fafafa",
            }}
          >
            <div
              style={{
                display: props.NoTitle ? "none" : "inline-flex",
                height: "30px",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  whiteSpace: "nowrap",
                  width: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              >
                {title}
              </div>
            </div>
          </Scrollbars>
          <Box
            style={{
              height: "30px",
              width: "100%",
              display: filter ? "inline-flex" : "none",
              alignItems: "center",
              paddingLeft: "3px",
              border: "1px solid #eeeeee",
            }}
          >
            <div style={{ fontSize: "12px" }}>Фильтр:</div>
            <div
              style={{
                height: "26px",
                border: "1px solid #cccccc",
                width: "100%",
              }}
            >
              <TestEditor
                value={paramsData && paramsData.Filter ? paramsData.Filter : ""}
                onEdit={filterOnEdit}
                placeholder=""
              />
            </div>
          </Box>
          {/* контейнер с таблицей параметров */}
          <Scrollbars style={{ height: height }} autoHide>
            <Box
              style={{
                height: height,
                display: "flex",
                flexDirection: "row",
              }}
            >
              {paramsJsx ? (
                <table
                  onMouseMove={onMouseMove}
                  onMouseUp={(ev) => onMouseUp(ev, setWidth)}
                  style={{
                    height: "fit-content",
                    borderSpacing: "0px",
                    tableLayout: "fixed",
                    flex: "1 1 0",
                    width: "fit-content",
                    border: "1px solid #e0e0e0",
                    fontFamily: "Roboto",
                    fontSize: "10pt",
                  }}
                >
                  <colgroup>
                    <col style={{ width: `${width}%` }} />
                    <col style={{ width: `${buttonBlockWidth}px` }} />
                    <col
                      style={{
                        width: "2px",
                      }}
                    />
                    <col
                      style={{
                        minWidth: "120px",
                      }}
                    />
                  </colgroup>
                  <tbody>{paramsJsx}</tbody>
                </table>
              ) : (
                <LoadingMask />
              )}
              {paramsButtonsJsx?.length > 0 && (
                <div style={{ width: "100px", height: "inherit" }}>{paramsButtonsJsx}</div>
              )}
            </Box>
          </Scrollbars>

          {/* контейнер для кнопок истории */}
          <Box
            style={{
              height: "30px",
              width: "100%",
              display: props.NoHistory || !history ? "none" : "",
            }}
          >
            <IconButton
              disabled={history === "2"}
              onClick={() => {
                historyClick(-1);
              }}
              style={{ height: "100%", width: "30px", borderRadius: "0" }}
            >
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
            <IconButton
              disabled={history === "1"}
              onClick={() => {
                historyClick(1);
              }}
              style={{
                height: "100%",
                width: "30px",
                borderRadius: "0",
                marginLeft: "calc(100% - 60px)",
              }}
            >
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          </Box>
        </Box>
      </ClickAwayListener>
    </ContextMenu>
  );
}
