import { setColor } from "../../../../../Editor/testEditor";
import { ImgURLSRC } from "../../../../../Url";
import { getIcons, getMulticheckIconForTree } from "../../../../Tools/Tools";
import { expandIcon, IconFolder, IconList } from "./treeIcons";

// создание тела для законченной ветви
function createSingleItem(data) {
  const newItem = document.createElement("LI");
  newItem.style.borderLeft = "1px solid #eeeeee";
  newItem.style.marginLeft = "11px";

  newItem.id = `tree-item_${data.id}`;
  newItem.key = `tree-item_${data.id}`;

  newItem.selected = data.id === data.props.ObjRef || data.id === data.res.Current;

  newItem.dataset.noDel = data.item.noDel;
  newItem.dataset.noEdit = data.item.noEdit;
  return newItem;
}

// создание тела для незаконченной ветви
function createGroupItem(data) {
  const newItemsGroup = document.createElement("LI");
  newItemsGroup.style.borderLeft = "1px solid #eeeeee";
  newItemsGroup.style.marginLeft = "11px";

  newItemsGroup.id = `tree-group_${data.id}`;
  newItemsGroup.key = `tree-group_${data.id}`;

  newItemsGroup.selected = data.id === data.props.ObjRef || data.id === data.res.Current;

  newItemsGroup.dataset.noDel = data.item.noDel;
  newItemsGroup.dataset.noEdit = data.item.noEdit;
  return newItemsGroup;
}

// создание котента для конечной ветви
function createSingleItemContent(data) {
  const content = document.createElement("div");
  content.id = data.id;
  content.classList.add("tree-item");
  content.style.paddingLeft = "23px";
  content.tabIndex = 0;
  //   content.addEventListener("mouseenter", onMouseEnter);
  //   content.addEventListener("mouseleave", onMouseLeave);
  content.dataset.noDel = data.item.noDel;
  content.dataset.noEdit = data.item.noEdit;
  return content;
}

// создание котента для незаконченной ветви
function createGroupItemContent(data) {
  const content = document.createElement("div");
  content.id = data.id;
  content.classList.add("tree-item");
  content.tabIndex = 0;
  //   content.addEventListener("mouseenter", onMouseEnter);
  //   content.addEventListener("mouseleave", onMouseLeave);
  content.dataset.noDel = data.item.noDel;
  content.dataset.noEdit = data.item.noEdit;
  return content;
}

// создание общего контейнера для конечной ветви
function createSingleItemContainer(data) {
  const ul = document.createElement("UL");
  if (data.items.length - 1 > data.index) ul.style.borderBottom = "1px solid #eeeeee";
  ul.style.listStyleType = "none";
  ul.style.paddingLeft = "12px";
  return ul;
}

// создание общего контейнера для незаконченной ветви
function createGroupItemContainer(data) {
  const ul = document.createElement("UL");
  if (data.items.length - 1 > data.index) ul.style.borderBottom = "1px solid #eeeeee";
  ul.style.listStyleType = "none";
  ul.style.paddingLeft = "12px";
  ul.group = "true";
  ul.classList.add("tree-group");
  ul.expanded = data.item.items ? "true" : "false";
  return ul;
}

// создание чекбокса
function createCheckbox(data) {
  const checbox = document.createElement("input");
  checbox.type = "checkbox";
  checbox.style.display = data.selectionMode.current || data.props.checkModeOnly ? "" : "none";
  checbox.classList.add("tree-item-checkbox");
  checbox.checked = data.selection.current.indexOf(data.id) !== -1 ? true : false;
  return checbox;
}

function createCheckboxMultiCheck(data) {
  const checbox = document.createElement("div");
  checbox.style.display = data.props.MultiCheckSet ? "" : "none";
  checbox.classList.add("tree-item-checkbox-mc");
  checbox.checkState = data.item.CheckState;
  checbox.id = `mc_${data.id}`;
  checbox.innerHTML = getMulticheckIconForTree(data.item.CheckState);
  // checbox.checked = selection.current.indexOf(id) !== -1 ? true : false;
  return checbox;
}

// создание кнопки распахивания
function createExpandButton(data) {
  const expandButton = document.createElement("button");
  expandButton.classList.add("expandButton");
  expandButton.expanded = data.item.items ? "true" : "false";
  expandButton.style.transform = data.item.items ? "" : "rotate(-90deg)";
  expandButton.innerHTML = expandIcon;
  return expandButton;
}

function getIcon(data) {
  const json = getIcons(data.image);
  if (json) {
    return ImgURLSRC(json);
  }
}

// Создание текста и иконки для элемента в дереве
export function getInnerContent(data) {
  let src;
  if (data.image) {
    src = data.image;
  }
  data.values.current[data.id] = data.text;

  // текст
  const innerText = document.createElement("div");
  innerText.innerText = data.text;
  innerText.title = data.text;
  innerText.id = `text-tree-item_${data.id}`;
  // стили
  innerText.style.display = "grid";
  innerText.style.alignItems = "center";
  innerText.style.color = setColor(data.item.Color) || "";

  // иконка
  const img = document.createElement("img");
  if (src) {
    img.src = src;
    img.style.width = "21px";
  } else {
    img.src = data.group ? IconFolder : IconList;
  }
  img.style.marginRight = "5px";

  return [innerText, img];
}

export function createFullItem(data) {
  // тело элемента
  const newItem = createSingleItem(data);

  // рабочая часть элемента
  const div = createSingleItemContent(data);

  // визуальный выбор элемента
  if (newItem.selected) {
    div.classList.add("tree-item-selected");
  }

  // получение иконки если она есть
  let icon;
  if (data.image) {
    icon = getIcon(data);
  }
  // визуальный контент элемента
  const innerContent = getInnerContent({ ...data, text: data.text || "", group: false, image: icon });
  const checbox = createCheckbox(data);
  const multiCheckbox = createCheckboxMultiCheck(data);
  // текст
  div.insertAdjacentElement("afterbegin", innerContent[0]);
  // иконка
  div.insertAdjacentElement("afterbegin", innerContent[1]);
  // чекбокс
  div.insertAdjacentElement("afterbegin", checbox);

  div.insertAdjacentElement("afterbegin", multiCheckbox);
  // общий контейнер
  const ul = createSingleItemContainer(data);

  // сборка всех элементов в один список
  // рабочая область(текст и кнопки)
  ul.appendChild(div);
  // тело
  ul.appendChild(newItem);
  return ul;
}

export function createFullGroupItem(data) {
  // тело элемента в которое будут добавляться ветви
  const newItemsGroup = createGroupItem(data);
  // рабочая часть элемента
  const div = createGroupItemContent(data);
  // визуальный выбор элемента
  if (newItemsGroup.selected) {
    div.classList.add("tree-item-selected");
  }
  // получение иконки если она есть
  let icon;
  if (data.image) {
    icon = getIcon(data);
  }
  // визуальный контент элемента
  const innerContent = getInnerContent({ ...data, text: data.text || "", group: true, image: icon });
  const checbox = createCheckbox(data);
  const multiCheckbox = createCheckboxMultiCheck(data);
  const expandButton = createExpandButton(data);
  // текст
  div.insertAdjacentElement("afterbegin", innerContent[0]);
  // иконка
  div.insertAdjacentElement("afterbegin", innerContent[1]);
  // чекбоксы
  div.insertAdjacentElement("afterbegin", checbox);
  div.insertAdjacentElement("afterbegin", multiCheckbox);
  // кнопка распахивания элемента
  div.insertAdjacentElement("afterbegin", expandButton);
  // общий контейнер
  const ul = createGroupItemContainer(data);

  // сборка всех элементов в один список
  // рабочая область(текст и кнопки)
  ul.appendChild(div);
  // тело
  ul.appendChild(newItemsGroup);
  return ul;
}
