import Tabs, { TabItem } from "smart-webcomponents-react/tabs";

import { RenderMessageBox } from "../../../../TokenProcessing/TokenProcessing";
import { AxiosRequest } from "../../../../Url";
import IMemo from "../../../Sections/ElementsSections/Forms/Components/IMemo";
import TicketsClosingDetailPanel from "./TicketsClosingDetailPanel";
import React from "react";

interface TicketsListDetailsProps {
  description: string;
  solution: string;
  recordId: string;
  status: string;
}

interface Feedback {
  comment: string;
  close: number;
  grade: number;
  files: File[];
}

export default function TicketsListDetails({ description, solution, recordId, status }: TicketsListDetailsProps) {
  const sendTaskFeedback = (feedback: Feedback): void => {
    const params = new Map<string, string | number>();
    params.set("prefix", "config");
    params.set("comand", "CloseSupportReq");
    params.set("ID", recordId);
    params.set("Text", feedback.comment);
    params.set("Rating", feedback.grade);
    params.set("Flag", feedback.close);

    AxiosRequest(true, params).then((res: { error: boolean; Info: string }) => {
      if (res.error) {
        RenderMessageBox(1, 1, "При отправке заявки возникла ошибка!");
      } else {
        RenderMessageBox(2, 1, res.Info);
      }
    });
  };

  return (
    <Tabs id="supportRequestForm" style={{ width: "100%", height: "100%" }}>
      <TabItem
        id="Description"
        label="Описание"
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
      >
        <IMemo
          Text={description}
          readOnly={true}
          style={{
            height: "100%",
            width: "100%",
            resize: "none",
            overflow: "auto",
            outline: "none",
          }}
        />
      </TabItem>
      <TabItem
        id="Solution"
        label="Решение"
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
      >
        <IMemo
          Text={solution}
          readOnly={true}
          style={{
            height: "100%",
            width: "100%",
            resize: "none",
            overflow: "auto",
            outline: "none",
          }}
        />
      </TabItem>
      <TabItem
        id="Closing"
        label="Закрытие"
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          display: "inline-block",
        }}
      >
        <TicketsClosingDetailPanel status={status} sendTaskFeedback={sendTaskFeedback} />
      </TabItem>
    </Tabs>
  );
}
