import CheckBoxMultiCheck from "../../../Sections/ElementsSections/CheckBoxMultiCheck";
import { CheckData } from "../DialogInstallScriptsUpdate";
import styles from "./styles.module.css";
import React from "react";

interface Props {
  onEdit: (data: CheckData) => Promise<void>;
  label: string;
  CheckState: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function CheckBoxWithLabel({ label, onEdit, CheckState }: Props) {
  return (
    <div className={styles.checkBoxContainer}>
      <div>
        <CheckBoxMultiCheck onEdit={onEdit} CheckState={CheckState} />
      </div>
      <div className={styles.dialogDayTypelabel}>{label}</div>
    </div>
  );
}
