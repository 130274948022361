import { handleTableAccounts, handleTableDoc } from "../Tools/Requests";
import { booksGrid } from "./GridFunctions/booksGrid";
import { clsBookGrid } from "./GridFunctions/clsbookGrid";
import { groupingOrg } from "./GridFunctions/organizationsGrid/groupingOrgGrid";
import { accountsGrid } from "./GridFunctions/organizationsGrid/accountsGrid";
import { organizationsGrid } from "./GridFunctions/organizationsGrid/organizationsGrid";
import { createGrid, createRecordSource } from "./rcsgrid";
import { dbviewGrid } from "./GridFunctions/dbviewGrid";
import { kladrGrid } from "./GridFunctions/kladrGrid";
import { personsGrid } from "./GridFunctions/personsGrid";
import { documentsGrid } from "./GridFunctions/organizationsGrid/documentsOrgGrid";
import { defaultGrid } from "./GridFunctions/defaultGrid";
import { accToolsGrid } from "./GridFunctions/acctools";
import { certGrid } from "./GridFunctions/certGrid";
import { localGrid } from "./GridFunctions/localGrid";
import { programsGrid } from "./GridFunctions/programsGrid";
import { departmentsGrid } from "./GridFunctions/departmentsGrid";
import { accountsTableGrid } from "./GridFunctions/accountsTableGrid";
import { accountsPlanTableGrid } from "./GridFunctions/accountsPlanTableGrid";
import { accountsAnaliticTableGrid } from "./GridFunctions/accountsAnaliticTableGrid";
import { tablesGrid } from "./GridFunctions/tablesGrid";
import { paytypesGrid } from "./GridFunctions/paytypesGrid";
import { cadresGrid } from "./GridFunctions/cadresGrid";
import { paytypesSettingsGrid } from "./GridFunctions/paytypesSettingsGrid";
import { tablesDistractionsGrid } from "./GridFunctions/tablesDistractionsGrid";
import chartOfCalculations from "./GridFunctions/chartOfCalculations";
import { scheduleGrid } from "./GridFunctions/scheduleGrid";

export const checkAvailabilityFromCanModify = (flag, grid) => {
  let available = true;
  if (grid) {
    available = !grid.canModify(flag, undefined, grid);
  }
  return available;
};

export async function CheckMultiSelect(grid, field, separator) {
  let multiSelect = grid.getMultiSelect(),
    returnStr = "";

  if (multiSelect.length > 0) {
    for (const index of multiSelect) {
      {
        returnStr += await grid.getActiveSource().getFieldText(field ? field : "ID", Number(index));
        if (index !== multiSelect[multiSelect.length - 1]) returnStr += separator ? separator : ",";
      }
    }
  } else {
    returnStr += grid.getActiveSource().getFieldTextSync(field ? field : "ID");
  }
  return returnStr;
}

async function createModule(func, properties) {
  if (!func) return;
  await func(...properties);
}

//Создание Grid.
// Аргументы:
//  1.gridPanel = jsx-Элемент где будет создан Grid (пример с использованием хука const gridPanelRef = useRef(): GridLoader(gridPanel = gridPanelRef.current, props, {}, func)
//  2.props = Свойства компонента (props.ID, props.ObjType, props.Module)
//  3.data = Объект с данными для post запроса HandleTable(если никакие данные не нужны передается пустой объект {})
// если не передать параметр data , то ему будет присвоено значение по умолчанию
export async function GridLoader(gridPanel, props, data = {}) {
  if (!gridPanel.getAttribute("rcsgrid_panel")) gridPanel.setAttribute("rcsgrid_panel", "");

  if (data.refresh) {
    if (data.localGrid) {
      return localGrid(gridPanel.grid, data.source, props, data);
    }
    return refreshGrid(gridPanel.grid, props, data);
  }

  let grid;
  let source;
  if (gridPanel !== null) {
    gridPanel.innerHTML = "";
    gridPanel.grid = new createGrid(gridPanel);
    grid = gridPanel.grid;
    if (data.onGetImageIndex) grid.onGetImageIndex = data.onGetImageIndex;
    if (data.changeGridStyles) grid.changeGridStyles = data.changeGridStyles;
    source = new createRecordSource();
    // grid.setOptions(0);
    if (data.indicator === false) {
      grid.ShowIndicatorF(false);
    }
    if (data.canModify !== undefined && data.canModify !== null) {
      grid.canModify = data.canModify;
    }
    if (data.localGrid) {
      return localGrid(grid, source, props, data);
    }

    //здесь можно добавлять отрисовку других модулей
    const properties = [grid, source, props, data];
    const modules = {
      books: () => createModule(booksGrid, properties),
      dbview: () => createModule(dbviewGrid, properties),
      clsbook: () => createModule(clsBookGrid, properties),
      address: () => createModule(kladrGrid, properties),
      organizations_group: () => createModule(groupingOrg, properties),
      persons: () => createModule(personsGrid, properties),
      staff: () => createModule(personsGrid, properties),
      documents: () => createModule(documentsGrid, properties),
      acctools: () => createModule(accToolsGrid, properties),
      storobj: () => createModule(organizationsGrid, properties),
      cert: () => createModule(certGrid, properties),
      programs: () => createModule(programsGrid, properties),
      Programs: () => createModule(programsGrid, properties),
      departments: () => createModule(departmentsGrid, properties),
      cadres: () => createModule(cadresGrid, properties),
      salary: () => createModule(chartOfCalculations, properties),
      schedule: () => createModule(scheduleGrid, properties),
      tables: () => createModule((data.distractions && tablesDistractionsGrid) || tablesGrid, properties),
      paytypes: () => createModule((data.settings && paytypesSettingsGrid) || paytypesGrid, properties),
      organizations: () => createModule((data.main && organizationsGrid) || (data.sub && accountsGrid), properties),
      accounts: () =>
        createModule(
          (data.plan && accountsPlanTableGrid) || (data.analitic && accountsAnaliticTableGrid) || accountsTableGrid,
          properties,
        ),
      default: () => createModule(defaultGrid, properties),
    };

    const createSelectedModule = modules[props.Module] || props.Module.default;

    await createSelectedModule();
  }

  if (!grid || !source) return;
  return { grid, source };
}

// Функция для обновления таблицы с другим токеном
async function refreshGrid(grid, props, data) {
  switch (data.token) {
    case "HandleAccounts":
      data.source.onHandleRequest = handleTableAccounts(props.Module, data.ObjType, data.GroupID, props.SectionID);
      break;
    case "HandleObjParams":
      data.source.onHandleRequest = handleTableDoc(props.Module, data.DocCfgID, data.GroupID, props.SectionID);
      break;
    case "HandleParams":
      data.source.onHandleRequest = handleTableDoc(props.Module, data.DocCfgID, data.GroupID, props.SectionID);
      break;
    default:
      data.source.onHandleRequest = handleTableDoc(props.Module, data.DocCfgID, data.GroupID, props.SectionID);
      break;
  }
  // data.source.close();
  // data.source.open();
  await grid.setSource(data.source);
  await data.source.first();
  await grid.refreshSource();
  await grid.updateGridSize();
  return { grid, source: data.source };
}
