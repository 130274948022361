import React from "react";

import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import utf8 from "utf8";
import { tokenProcessingTest } from "../TokenProcessing/TokenProcessing";
import { AxiosRequest, XMLrequest } from "../Url";
import TextEditor from "./TextEditor";

export default function DialogHtml(props) {
  const defaultValueRef = React.useRef(null);
  const [editorValue, setEditorValue] = React.useState("");

  React.useEffect(() => {
    if (props.htmlInput) {
      const mime = MIMEToHtml(props.htmlInput);
      if (mime !== "") {
        setEditorValue(mime);
      }
    }
  }, []);

  function InputButton(event, RequestID) {
    let params = new Map(),
      data,
      json,
      ClickedButton = event.target.value;
    if (ClickedButton === "1") {
      data = { Result: ClickedButton, $content: HtmlToMIME(editorValue) };
    } else {
      data = { Result: ClickedButton };
    }
    params.set("prefix", "project");
    params.set("comand", "ResumeRequest");
    params.set("RequestID", RequestID);
    params.set("WSM", "1");
    json = XMLrequest(params, data);
    tokenProcessingTest(json, { propsId: props.id });
  }

  var DMap = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    17: 17,
    18: 18,
    19: 19,
    20: 20,
    21: 21,
    22: 22,
    23: 23,
    24: 24,
    25: 25,
    26: 26,
    27: 27,
    28: 28,
    29: 29,
    30: 30,
    31: 31,
    32: 32,
    33: 33,
    34: 34,
    35: 35,
    36: 36,
    37: 37,
    38: 38,
    39: 39,
    40: 40,
    41: 41,
    42: 42,
    43: 43,
    44: 44,
    45: 45,
    46: 46,
    47: 47,
    48: 48,
    49: 49,
    50: 50,
    51: 51,
    52: 52,
    53: 53,
    54: 54,
    55: 55,
    56: 56,
    57: 57,
    58: 58,
    59: 59,
    60: 60,
    61: 61,
    62: 62,
    63: 63,
    64: 64,
    65: 65,
    66: 66,
    67: 67,
    68: 68,
    69: 69,
    70: 70,
    71: 71,
    72: 72,
    73: 73,
    74: 74,
    75: 75,
    76: 76,
    77: 77,
    78: 78,
    79: 79,
    80: 80,
    81: 81,
    82: 82,
    83: 83,
    84: 84,
    85: 85,
    86: 86,
    87: 87,
    88: 88,
    89: 89,
    90: 90,
    91: 91,
    92: 92,
    93: 93,
    94: 94,
    95: 95,
    96: 96,
    97: 97,
    98: 98,
    99: 99,
    100: 100,
    101: 101,
    102: 102,
    103: 103,
    104: 104,
    105: 105,
    106: 106,
    107: 107,
    108: 108,
    109: 109,
    110: 110,
    111: 111,
    112: 112,
    113: 113,
    114: 114,
    115: 115,
    116: 116,
    117: 117,
    118: 118,
    119: 119,
    120: 120,
    121: 121,
    122: 122,
    123: 123,
    124: 124,
    125: 125,
    126: 126,
    127: 127,
    1027: 129,
    8225: 135,
    1046: 198,
    8222: 132,
    1047: 199,
    1168: 165,
    1048: 200,
    1113: 154,
    1049: 201,
    1045: 197,
    1050: 202,
    1028: 170,
    160: 160,
    1040: 192,
    1051: 203,
    164: 164,
    166: 166,
    167: 167,
    169: 169,
    171: 171,
    172: 172,
    173: 173,
    174: 174,
    1053: 205,
    176: 176,
    177: 177,
    1114: 156,
    181: 181,
    182: 182,
    183: 183,
    8221: 148,
    187: 187,
    1029: 189,
    1056: 208,
    1057: 209,
    1058: 210,
    8364: 136,
    1112: 188,
    1115: 158,
    1059: 211,
    1060: 212,
    1030: 178,
    1061: 213,
    1062: 214,
    1063: 215,
    1116: 157,
    1064: 216,
    1065: 217,
    1031: 175,
    1066: 218,
    1067: 219,
    1068: 220,
    1069: 221,
    1070: 222,
    1032: 163,
    8226: 149,
    1071: 223,
    1072: 224,
    8482: 153,
    1073: 225,
    8240: 137,
    1118: 162,
    1074: 226,
    1110: 179,
    8230: 133,
    1075: 227,
    1033: 138,
    1076: 228,
    1077: 229,
    8211: 150,
    1078: 230,
    1119: 159,
    1079: 231,
    1042: 194,
    1080: 232,
    1034: 140,
    1025: 168,
    1081: 233,
    1082: 234,
    8212: 151,
    1083: 235,
    1169: 180,
    1084: 236,
    1052: 204,
    1085: 237,
    1035: 142,
    1086: 238,
    1087: 239,
    1088: 240,
    1089: 241,
    1090: 242,
    1036: 141,
    1041: 193,
    1091: 243,
    1092: 244,
    8224: 134,
    1093: 245,
    8470: 185,
    1094: 246,
    1054: 206,
    1095: 247,
    1096: 248,
    8249: 139,
    1097: 249,
    1098: 250,
    1044: 196,
    1099: 251,
    1111: 191,
    1055: 207,
    1100: 252,
    1038: 161,
    8220: 147,
    1101: 253,
    8250: 155,
    1102: 254,
    8216: 145,
    1103: 255,
    1043: 195,
    1105: 184,
    1039: 143,
    1026: 128,
    1106: 144,
    8218: 130,
    1107: 131,
    8217: 146,
    1108: 186,
    1109: 190,
  };

  function UnicodeToWin1251(s) {
    var L = [];
    for (var i = 0; i < s.length; i++) {
      var ord = s.charCodeAt(i);
      if (!(ord in DMap)) throw "Character " + s.charAt(i) + " isn't supported by win1251!";
      L.push(String.fromCharCode(DMap[ord]));
    }
    return L.join("");
  }

  function MIMEToHtml(mime, win1251) {
    if (!mime.includes("MIME-Version: 1.0") && !mime.includes("Mime-Version: 1.0")) {
      const fixedMime = mime.replaceAll("\r\n", "<br/>").replaceAll("\n", "<br/>");
      return `<p>${fixedMime}</p>`;
    }

    //if (!mime.includes("Content-Transfer-Encoding: base64"))
    /*if (mime.includes("Received: from mailx.krista.ru")) {
      return mailMimeToHtml(mime);
    } else if (mime.includes("<html>")) {
      return Html(mime);
    } else if (mime.includes("Content-Location")) {
      return ContentHtml(mime);
    } else {*/
    let html;
    let pos = 0;
    let img = [];
    let imgTypeArr = [];
    let imgName = [];
    let resultBegin;
    let type;
    let posName;
    let endName;
    let imgMap = new Map();
    let imgExt = new Map();
    var doc = document.createElement("doc");
    if (!mime.includes("-=_BOUNDARY_LINE_")) {
      let win8bit = false;
      if (mime.includes("Content-Transfer-Encoding: 8bit") || mime.includes("<html>") || mime.includes("<HTML>")) {
        if (!mime.includes("Content-Transfer-Encoding: quoted-printable")) {
          win8bit = true;
        }
      }
      /*if (mime.includes("Content-Transfer-Encoding: 8bit")) {
       win8bit = true; 
       let utf8dec = new TextDecoder();
       var winMIME = UnicodeToWin1251(mime);
       var Uint8Arr = Uint8Array.from(winMIME.split("").map(x => x.charCodeAt()));
       mime = utf8dec.decode(Uint8Arr);
      }*/
      html = "<div>Ошибка отображения данных<div>";
      let params = new Map();
      params.set("prefix", "stmport");
      params.set("comand", "EMLToMHT");
      AxiosRequest(true, params, { $content: mime }).then((fixedMime) => {
        if (fixedMime.content && fixedMime.content.includes("-=_BOUNDARY_LINE_"))
          setEditorValue(MIMEToHtml(fixedMime.content, win8bit));
      });
      return "";
      //запрос к серверу на преобразование mime
    } else {
      let utf8decoder;
      if (mime.includes('charset="koi8-r"')) {
        utf8decoder = new TextDecoder("koi8-r");
      } else if (mime.includes('charset="cp1251"')) {
        utf8decoder = new TextDecoder("cp1251");
      } else if (mime.includes('harset="windows-1251"') || win1251) {
        utf8decoder = new TextDecoder("windows-1251");
      } else {
        utf8decoder = new TextDecoder();
      }

      while (pos !== -1) {
        resultBegin = pos;
        pos = mime.indexOf("Content-Transfer-Encoding: base64", pos + 1);
        if (pos !== -1) {
          if (resultBegin === 0) {
            html = mime.substring(pos + 37, mime.indexOf("------=_BOUNDARY_LINE_", pos) - 4);
          } else {
            let buf = mime.substring(pos + 37, mime.indexOf("------=_BOUNDARY_LINE_", pos) - 4);
            img.push(buf);
            type = mime.indexOf(".", pos - 8);
            imgTypeArr.push(mime.substring(type + 1, pos - 3));
            posName = mime.indexOf("<", pos - 70);
            endName = mime.indexOf(">", pos - 70);
            imgMap.set(mime.substring(posName + 1, endName), buf);
            imgExt.set(mime.substring(posName + 1, endName), mime.substring(type + 1, pos - 3));
          }
        }
      }

      try {
        html = atob(html);
        var uint8 = Uint8Array.from(html.split("").map((x) => x.charCodeAt()));
        html = utf8decoder.decode(uint8);
        if (html.includes("Mime-Version: 1.0") || html.includes("MIME-Version: 1.0")) {
          let params = new Map();
          params.set("prefix", "stmport");
          params.set("comand", "EMLToMHT");
          AxiosRequest(true, params, { $content: html }).then((fixedMime) => {
            if (fixedMime.content) setEditorValue(MIMEToHtml(fixedMime.content));
          });
          return "";
          //запрос к серверу на преобразование mime
        }
      } catch (_) {
        html = "<div>Ошибка отображения данных<div>";
      }
    }

    if (img.length == 0) {
      if (html.includes("<html>") || html.includes("<HTML>")) {
        return htmlOnly(html);
      }
    }

    html = html.trim();
    doc.innerHTML = html;
    let title = doc.querySelector("title");
    if (title !== null) {
      var para = document.createElement("div");
      let parent = title.parentNode;
      parent.replaceChild(para, title);
    }
    let i = 0;
    // let w = "";
    // let h = '"';
    let spanList = doc.querySelectorAll("img");
    [...spanList].forEach((el) => {
      let key = el.src.substring(4, el.src.length);
      var para = document.createElement("img");
      if (el.width !== 0) {
        para.width = el.width;
        para.height = el.height;
        para.src = "data:image/" + imgExt.get(key) + ";base64," + imgMap.get(key);
        // para.src = "data:image/" + imgTypeArr[i] + ";base64," + img[i++];
        //para.src ='height='+el.height+' width='+el.width+' data:image/'+imgTypeArr[i]+';base64,'+img[i++];
      } else {
        para.src = "data:image/" + imgExt.get(key) + ";base64," + imgMap.get(key);
      }
      let parent = el.parentNode;
      parent.replaceChild(para, el);
    });
    defaultValueRef.current = doc.innerHTML;
    return doc.innerHTML;
    //}
  }

  function mailMimeToHtml(mime) {
    let pos = 0;
    let end = 0;
    let html = "";
    pos = mime.indexOf("Content-Type: text/html; charset=utf-8");
    end = mime.indexOf("----ALT--", pos + 100);
    html = mime.substring(pos + 77, end - 4);
    html = atob(html);
    html = utf8.decode(html);
    return html;
  }

  function Html(mime) {
    let pos = 0;
    let end = 0;
    let html = "";
    pos = mime.indexOf("Content-Transfer-Encoding: base64");
    if (pos != -1) {
      pos = mime.indexOf("\r\n\r\n", pos + 1);
      end = mime.indexOf("\r\n\r\n", pos + 5);
      html = mime.substring(pos + 4, end);
      html = atob(html);
      html = utf8.decode(html);
    } else {
      pos = mime.indexOf("<html>");
      end = mime.indexOf("</html>");
      html = mime.substring(pos, end + 7);
      //html = atob(html);
      html = utf8.decode(html);
    }
    return html;
  }
  function htmlOnly(mime) {
    let pos = 0;
    let end = 0;
    let html = "";
    pos = mime.indexOf("<html>");
    if (pos == -1) {
      pos = mime.indexOf("<HTML>");
    }
    end = mime.indexOf("</html>");
    if (end == -1) {
      end = mime.indexOf("</HTML>");
    }
    html = mime.substring(pos, end + 7);
    return html;
  }

  function ContentHtml(mime) {
    let pos = 0;
    let end = 0;
    let html = "";
    pos = mime.indexOf("Content-Location");
    if (pos != -1) {
      pos = mime.indexOf("\r\n\r\n", pos + 1);
      end = mime.indexOf("\r\n\r\n", pos + 5);
      html = mime.substring(pos + 4, end);
      html = atob(html);
      html = utf8.decode(html);
    }
    return html;
  }

  function HtmlToMIME(textValue) {
    if (textValue === "") return "";
    let str = textValue;
    let result = "";
    let pos = 0;
    let img = [];
    let imgTypeArr = [];
    let imgBegin, resultBegin, resultEnd, imgEnd, imgEnd1;
    let imgType, base, imgAll;
    imgEnd = 0;
    function imageMime(number, img, imgType) {
      return (
        "\r\nContent-Type: image/" +
        imgType +
        '; name="image' +
        number +
        "." +
        imgType +
        '"\r\n' +
        "Content-ID: <image" +
        number +
        "." +
        imgType +
        ">\r\n" +
        "Content-Transfer-Encoding: base64\r\n\r\n" +
        img +
        "\r\n\r\n------=_BOUNDARY_LINE_"
      );
    }

    while (pos !== -1) {
      resultBegin = pos;
      pos = str.indexOf("<img", pos + 1);
      resultEnd = pos;
      let height, width;
      let beg, end;
      if (pos !== -1) {
        beg = str.indexOf("width", pos + 1) + 7;
        end = str.indexOf('"', beg);
        width = beg === 6 ? 0 : str.substring(beg, end);
        beg = str.indexOf("height", pos + 1) + 8;
        end = str.indexOf('"', beg);
        height = beg === 7 ? 0 : str.substring(beg, end);

        base = str.indexOf(";base64", pos + 21);
        imgType = str.substring(base - 3, base);
        imgTypeArr.push(imgType);
        imgEnd1 = imgEnd;
        imgBegin = str.indexOf("base64", pos) + 7;
        imgEnd = str.indexOf('"', imgBegin);
        imgAll = str.indexOf("src=", pos) + 5;

        const img1 = new Image();
        img1.src = str.substring(imgAll, imgEnd);

        if (width === 0 && height === 0) {
          width = img1.width;
          height = img1.height;
        } else if (width === 0 && height !== 0) {
          let px = height.indexOf("px", 0);
          height = px > 1 ? height.substring(0, height.length - 2) : height;
          width = Math.round((img1.widt / img1.height) * Number(height)) + "px";
        } else if (width !== 0 && height === 0) {
          let px = width.indexOf("px", 0);
          width = px > 1 ? width.substring(0, width.length - 2) : width;
          height = Math.round((img1.height / img1.width) * Number(width)) + "px";
        }

        result +=
          str.substring(resultBegin === 0 ? 0 : imgEnd1 + 2, resultEnd) +
          "<img width=" +
          width +
          "px height=" +
          height +
          ' alt="" src="cid:image' +
          img.length.toString() +
          "." +
          imgType +
          '">';
        img.push(str.substring(imgBegin, imgEnd));
        imgEnd = str.indexOf(">", pos) - 1;
      }
    }

    result += str.substring(imgEnd !== undefined && imgEnd !== 0 ? imgEnd + 2 : 0, str.length);

    let mime =
      "MIME-Version: 1.0\r\n" +
      'Content-Type: multipart/related; boundary="----=_BOUNDARY_LINE_"\r\n\r\n' +
      "------=_BOUNDARY_LINE_\r\n" +
      'Content-Type: text/html; charset="utf-8"\r\n' +
      "Content-Transfer-Encoding: base64\r\n\r\n" +
      btoa(utf8.encode(result)) +
      "\r\n\r\n------=_BOUNDARY_LINE_";

    for (let i = 0; i < img.length; i++) {
      mime += imageMime(i, img[i], imgTypeArr[i]);
    }
    mime += "--\r\n";

    return mime;
  }

  const handleChanges = (val) => {
    setEditorValue(val);
    if (props.onBlur && defaultValueRef.current !== val) {
      //
      props.onBlur(HtmlToMIME(val));
    }
  };

  const handleOnChange = (htmlVal, textVal) => {
    if (props.onChange && defaultValueRef.current !== htmlVal) {
      const mime = HtmlToMIME(htmlVal);
      props.onChange(mime, textVal || mime);
    }
  };
  return (
    <div style={{ height: "100%" }}>
      <TextEditor
        inputValue={editorValue}
        setInputValue={handleOnChange}
        setValue={handleChanges}
        height={props.HiddenButton ? "0px" : "35px"}
        readonly={props.readonly}
      />
      {!props.HiddenButton ? (
        <Box
          style={{
            marginLeft: "auto",
            width: "100%",
            height: "35px",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Button
            size="small"
            variant="outlined"
            value={1}
            onClick={(e) => InputButton(e, props.RequestID)}
            style={{ textTransform: "none" }}
            className="button_Modal_Select"
          >
            Выбрать
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={(e) => InputButton(e, props.RequestID)}
            style={{ textTransform: "none", marginLeft: 10 }}
            className="button_Modal_Close"
          >
            Отмена
          </Button>
        </Box>
      ) : null}
    </div>
  );
}
