import React, { FC, ReactNode, useState } from "react";
import IconButton from "@mui/material/IconButton/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Typography from "@mui/material/Typography";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Divider, ListItemIcon, ListItemText, SvgIconTypeMap } from "@mui/material";

interface Item {
  label: string;
  onClick?: (item?: Item) => void | null;
  shortCut?: string;
  icon?: OverridableComponent<SvgIconTypeMap> | JSX.Element;
  hidden?: boolean;
}

interface IconMenuProps {
  items: Item[];
  title: string;
  children: ReactNode;
}

const IconMenuButton: FC<IconMenuProps> = ({ items, title, children }) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const hasIcon = items.some((item) => item.icon !== undefined && !item.hidden);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Tooltip title={title}>
        <IconButton onClick={handleOpenUserMenu}>{children}</IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "25px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {items.map((item) => {
          if (item.label === "divider") {
            return <Divider key={item.label} />;
          }
          {
            return (
              <MenuItem
                key={item.label}
                style={{ display: item.hidden ? "none" : "flex" }}
                onClick={() => {
                  handleCloseUserMenu();
                  item.onClick && item.onClick(item);
                }}
              >
                <ListItemIcon style={{ minWidth: hasIcon ? "36px" : "0px" }}>{item.icon || null} </ListItemIcon>
                <ListItemText style={{ paddingLeft: "0px" }} sx={{ p: 0 }}>
                  {item.label}
                </ListItemText>
                <Typography variant="body2" color="text.secondary">
                  {item.shortCut}
                </Typography>
              </MenuItem>
            );
          }
        })}
      </Menu>
    </>
  );
};

export default IconMenuButton;
