import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Alert from "@mui/material/Alert/Alert";
import Badge from "@mui/material/Badge/Badge";
import Box from "@mui/material/Box/Box";
import IconButton from "@mui/material/IconButton/IconButton";
import Popper from "@mui/material/Popper/Popper";
import Button from "@mui/material/Button/Button";
import Typography from "@mui/material/Typography/Typography";
// import FormGroup from "@mui/material/FormGroup/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
// import Switch from "@mui/material/Switch/Switch";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Grid from "@mui/material/Grid/Grid";
import Stack from "@mui/material/Stack/Stack";
import AlertTitle from "@mui/material/AlertTitle/AlertTitle";
import NotificationsIcon from "@mui/icons-material//NotificationsOutlined";
import InputIcon from "@mui/icons-material//Input";
import {
  AxiosRequest,
  CreateCokies,
  deleteURLparam,
  get_cookie,
  GetOptionsForUser,
  getURLparam,
  hasURLparam,
  PingServer,
  XMLrequest,
} from "../../../Url";
import useTheme from "../../../Hooks/useTheme.jsx";
import cn from "classnames";
import { isEmptyObject } from "../../Tools/Tools";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
import { toast } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import CheckIcon from "@mui/icons-material/Check";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Link from "@mui/material/Link";
import UserProfile from "./UserProfile";
import {
  CreateBackgroundForModal,
  RemoveAllBG,
  SetLoadingCursor,
  shellExecute,
  tokenProcessingTest,
} from "../../../TokenProcessing/TokenProcessing";
import FormsMainFile from "../../Sections/ElementsSections/Forms/FormsMainFile";
import ModalWindow from "../../Module/ModalWindow";
import CountDown from "./CountDown";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../../store";
import { setSelectedSlice } from "../../../../store/SideBarSlice";
import { getUrlSearh } from "../../Tools/urlParams";
import { SxProps, Theme } from "@mui/system";
import { useAtom, useAtomValue } from "jotai";
import { appConfig, hideToastNotificationsAtom, isAuthorized } from "../../../../atoms";
import SupportButton from "./SupportButton";
import {
  createGridStateString,
  saveDocumentState,
} from "../../Sections/ElementsSections/SectionDocuments/DocumentsConsts";

const stmjsonUrl = async () => {
  const hasStmJson = hasURLparam("stmjson");
  if (hasStmJson) {
    const command = parseCommand(atob(getURLparam("stmjson")));
    const ablResp = await execABLProgram(command);
    tokenProcessingTest(ablResp);
    deleteURLparam("stmjson");
  }
};

interface AblCommand {
  command: string;
  token?: string;
  module: string;
  params: object;
}
export const execABLProgram = async (command: AblCommand | null, id?: string) => {
  if (command === null || command.module !== "programs") return console.error("Ошибка запроса execABLProgram");
  const params = new Map();
  params.set("prefix", "programs");
  params.set("comand", "execABLProgram");
  for (const [key, value] of Object.entries(command.params)) {
    params.set(key, value);
  }
  if (id) params.set("Notification", id);
  params.set("WSM", "1");
  const json = await AxiosRequest(true, params);
  tokenProcessingTest(json);
};

type ParsedData = {
  [key: string]: string | ParsedData;
};

function parseString(input: string): ParsedData {
  const result: ParsedData = {};

  // убираем stmjson в начале строки
  input = input.replace(/^stmjson:/, "");

  // разбиваем строку на части по запятым. но игнорируем запятые внутри params
  const parts = input.match(/(?:[^,{}]+|\{[^{}]*\})+/g);

  if (!parts) return result;

  for (const part of parts) {
    // разбираем параметр с ключом и значением
    const match = part.match(/^([^:={]+)[:=](.+)$/);
    if (match) {
      const key = match[1].trim();
      let value = match[2].trim();

      // если значение params то парсим его рекурсивно
      if (value.startsWith("{") && value.endsWith("}")) {
        value = value.slice(1, -1);
        result[key] = parseString(value);
      } else {
        result[key] = value.replace(/^"(.*)"$/, "$1"); // убираем кавычки
      }
    } else {
      if (!result["command"]) {
        result["command"] = part.trim();
      }
    }
  }

  return result;
}

export const parseCommand = (inputCommand: string): AblCommand | null => {
  try {
    const reg = /[A-zА-я0-9|?&\-_.\s]+/g;
    const checkJsonStyle = new RegExp("stmjson:[A-Za-z_]+:[A-Za-z_]");

    let result = inputCommand.replaceAll('"', "");
    result = result.replaceAll(`'`, "");

    if (result.match(checkJsonStyle)) {
      result = result.replaceAll("stmjson:", "stmjson:module,");
    } else {
      result = result.replaceAll("=", ":");
    }

    const set: any = new Set(result.match(reg));

    for (let el of set) {
      result = result.replaceAll(el, '"' + el + '"');
    }

    return JSON.parse("{" + result + "}");
  } catch {
    return parseString(inputCommand);
  }
};

const createTokenNavigate = (stmJson: AblCommand) => {
  tokenProcessingTest({
    token: "Navigate",
    Params: { PageID: stmJson.params.pageid || stmJson.params.PageID, RequestID: "0" },
  });
};

const stmJsonClient = (stmJson: AblCommand) => {
  const tokens = {
    navigate: createTokenNavigate,
  };
  return tokens[stmJson.command] || null;
};

const stmJsonRequest = (stmJson: AblCommand, id: string) => {
  return execABLProgram(stmJson, id);
};

const stmJsonShell = (stmJson: AblCommand) => {
  const operations = {
    open: shellExecute,
    download: null,
  };
  if (operations[stmJson?.params.operation])
    operations[stmJson?.params.operation]({
      Params: { FileName: stmJson.params.filename, RCDATA: stmJson.params.rcdata },
    });
};

export const stmjsonClick = (stmJson: AblCommand, id?: string) => {
  //todo заменить HandleNotifClick
  const commands = {
    navigate: createTokenNavigate,
    client: stmJsonClient,
    request: stmJsonRequest,
    shell: stmJsonShell,
  };
  if (commands[stmJson.command]) commands[stmJson.command](stmJson, id);
};

export function saveDocStates() {
  const callBacks = store.getState().theme.unloadCallbacks;
  if (callBacks && callBacks.length > 0) {
    callBacks.forEach((item: any) => {
      if (item.DocCfgID) {
        const stringState: any = createGridStateString(item.source.saveState());
        const details =
          item.source.details && item.source.details.activeDetails ? item.source.details.activeDetails : undefined;
        saveDocumentState(item.DocCfgID, item.secId, stringState, details);
      }
    });
  }
}

const DashboardNavbar = (props: any) => {
  const config = useAtomValue(appConfig);
  const hideToastNotifications = useAtomValue(hideToastNotificationsAtom);
  const theme: any = useSelector<any>((state) => state.theme.theme);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSessionActive, setIsSessionActive] = useAtom(isAuthorized);
  // const pattern = new RegExp(/from=(.*)#/);
  // const drx = get_cookie("LastLogin").replaceAll(",","/")
  const drx = get_cookie("LastLogin").split(",");
  const { notifications, markAsRead, unreadCount, remove } = useNotificationCenter();
  const [showUnreadOnly, setShowUnreadOnly] = useState<boolean>();
  const [isOpen, setIsOpen] = useState(false);
  const [anim, setAnim] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [HeadUpdate, setHeadUpdate] = useState<undefined | Object>({});
  // const bc = new BroadcastChannel('test_channel');
  const updateNotificationsButton = (): SxProps<Theme> => {
    switch (theme) {
      case "light":
        return {
          color: "var(--font-color-light)",
        };
      case "blue":
        return {
          color: "var(--font-color-blue)",
          borderColor: "rgba(255, 255, 255, 0.5)",
          "&:hover": {
            borderColor: "rgb(255, 255, 255)",
            backgroundColor: "rgba(255, 255, 255, 0.04)",
          },
        };
      default:
        return {};
    }
  };

  const sessionCheck = async () => {
    if (isSessionActive === false) {
      backToLoginPage();
    } else {
      const pingJson = await PingServer(HandleNotification, setHeadUpdate);
      if (!isEmptyObject(pingJson)) {
        Notifications(pingJson);
      }
      setShowUnreadOnly(false);
    }
  };

  React.useEffect(() => {
    stmjsonUrl();
  }, []);

  useEffect(() => {
    sessionCheck();
  }, []);

  useEffect(() => {
    // bc.addEventListener('message', function (e) {
    // if (window.location.origin !== "http://localhost:3000")
    window.addEventListener("beforeunload", beforeunloadhandler);

    window.addEventListener("storage", StorageChange);
    return () => {
      window.removeEventListener("storage", StorageChange);
      window.removeEventListener("beforeunload", beforeunloadhandler);
    };
  }, []);

  const StorageChange = (event: StorageEvent) => {
    if (event.newValue === "leave") {
      HandleSingOut();
    }
  };

  function backToLoginPage() {
    window.removeEventListener("beforeunload", beforeunloadhandler);
    let navigateUrl: any = "/";
    setIsSessionActive(false);
    if (GetOptionsForUser()?.authed_by === "esia" && config.lk.doEsia?.logout) {
      localStorage.setItem("login", "");
      window.location.href = config.lk.doEsia.logout;
    } else {
      localStorage.setItem("login", "");
      if (GetOptionsForUser()?.from && GetOptionsForUser()?.from !== "lk") {
        window.location.href = GetOptionsForUser()?.from.replaceAll("@", "/");
      } else {
        if (GetOptionsForUser()?.from && GetOptionsForUser()?.from === "lk" && config.smeta.hideEnter === undefined) {
          navigate("/lk");
        } else {
          if (hasURLparam("parsed")) {
            navigateUrl += getUrlSearh();
          }
          navigate(navigateUrl);
        }
      }
    }
  }

  const beforeunloadhandler = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = true;
    // localStorage.setItem("login", "");

    saveDocStates();
    window.addEventListener("mousemove", mousemovehandler);
  };

  const mousemovehandler = () => {
    const licguid: any = sessionStorage.getItem("LicG");
    setTimeout(() => {
      localStorage.setItem("login", licguid);
    }, 3000);
    window.removeEventListener("mousemove", mousemovehandler);
  };

  const HandleNotification = (json: any) => {
    if (json !== undefined) {
      if (json.content !== undefined && json.content.substring(0, 40) === "Восстановлено подключение к конфигурации") {
        toast(json.content, {
          type: "info",
          position: "bottom-right",
          style: { width: "350px" },
          onOpen: () => {
            setTimeout(() => {
              remove("restoreConnetction");
            }, 1);
          },
          toastId: "restoreConnetction",
          data: {
            Time: `${new Date()}`,
            Title: "",
            Content: json.content,
            Options: 4,
          },
        });
      } else if (json.ID !== undefined) {
        RaiseToast(json);
      }
    }
  };

  const addNotification = () => {
    remove("109203");
    // const comand =
    //   'stmjson:module,command=request,module=programs,token=ExecABLProgram,params:{Name=ShowRequestDoc,params="ОД|4157498|Служба технадзора ЯНАО.drx"}';
    // let jsonCommandData = ParseCommand(comand);
    // RaiseToast({
    //   Options: 4,
    //   Command: jsonCommandData,
    //   Time: "11.01.2023 21:09:53",
    //   Title: "Title",
    //   content: "Открыть",
    //   ID: 1,
    // });
    // bc.postMessage('This is a test message.');
  };

  const toggleNotificationCenter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (isOpen === true) {
      setTimeout(() => {
        setIsOpen(!isOpen);
      }, 499);
    } else {
      setIsOpen(!isOpen);
    }
    setAnim(!anim);
  };

  function HandleNotifications(comand: string, ID?: string | number) {
    let params = new Map();
    params.set("prefix", "audit");
    params.set("comand", "HandleNotifications");
    params.set("Command", comand);
    if (ID) params.set("ID", ID);
    params.set("smart", "1");
    return AxiosRequest(true, params);
  }

  function HandleNotificationClick(command: any, options: number, id: any) {
    let interval: any;

    function setInterval(int: any) {
      interval = int;
    }
    switch (command.command) {
      case "navigate":
        tokenProcessingTest({
          token: "Navigate",
          Params: { PageID: command.params.pageid, RequestID: "0" },
        });
        break;
      case "client":
        if (command.token === "exit") {
          const login = localStorage.getItem("login");
          if (login === "") {
            return;
          }
          CreateBackgroundForModal(
            <ModalWindow
              Title="Внимание"
              style={{ height: `${180}px`, width: `${380}px` }}
              Buttons={{
                jsonData: {
                  Кнопка$Выбрать: "Выйти",
                  Кнопка$Отмена: "Остаться",
                },
                Params: { Buttons: "3" },
              }}
              onClose={() => {
                clearInterval(interval);
                let params = new Map();
                params.set("prefix", "project").set("comand", "info").set("info", "3");
                XMLrequest(params);
              }}
              onSelect={() => {
                clearInterval(interval);
                HandleSingOut();
              }}
              resizeMode={false}
            >
              <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                <Typography>
                  Через
                  <CountDown
                    initialSeconds={30}
                    getInterval={setInterval}
                    onTimerEnd={HandleSingOut}
                    style={{ display: "inline" }}
                  />{" "}
                  произойдет автоматический выход из сессии. Продолжить работу?
                </Typography>
              </Grid>
            </ModalWindow>,
          );
        }
        if (command.token === "navigate")
          tokenProcessingTest({
            Token: "Navigate",
            Break: "1",
            Params: { PageID: `${command.params.PageID}`, RequestID: "0" },
          });
        break;
      case "request":
        execABLProgram(command, id);
        break;
    }
    if (options & NotifyFlag_ImdClose) {
      markAsDone(id);
      setTimeout(() => {
        remove(id);
      }, 500);
    }
  }

  const NotifyFlag_Immediately = 1; // выполнить команду сразу как получишь уведомление
  const NotifyFlag_CanClose = 2; // всплывающее уведомление и возможность закончить (закрыть) уведомление
  // const NotifyFlag_Balloon = 4; // всплывающее уведомление
  const NotifyFlag_ImdClose = 8; //всплывающее уведомление и после обработки уведомления, сразу его закрыть

  function Notifications(jsonFromPing?: any) {
    //  valueAny: any, options: number;
    HandleNotifications("list").then((json) => {
      if (!isEmptyObject(json) && json.Items) {
        json = json.Items.items;
        for (const value of Object.values(json)) {
          RaiseToast(value);
        }
      }
    });
  }

  async function finishAll() {
    for (const value of Object.values(notifications)) {
      if (value.data && value.data.Options && value.data.Options & 2) {
        await markAsDone(value.id);
      }
    }
    Notifications();
  }

  function RaiseToast(value: any) {
    let jsonCommandData: any;
    try {
      if (value.Command) jsonCommandData = parseCommand(value.Command);
    } catch {}
    const options = Number(value.Options);
    toast(`${value.Title}` + value.content === undefined ? "" : `${value.Title || ""}: ${value.content}`, {
      toastId: value.ID,
      type: "info",
      position: "bottom-right",
      style: {
        display: options & NotifyFlag_Immediately || isOpen === true ? "none" : "flex",
      },
      onClick: () => {
        HandleNotificationClick(jsonCommandData, options, value.ID);
      },
      data: {
        Command: jsonCommandData,
        Time: value.Time,
        Title: value.Title,
        Content: value.content,
        Options: options,
      },
    });
    if (options & NotifyFlag_Immediately) {
      HandleNotificationClick(jsonCommandData, options, value.ID);
    }
    if (hideToastNotifications) toast.dismiss();
  }

  async function markAsDone(id: string | number) {
    remove(id);
    await HandleNotifications("delete", id);
  }

  const HandleSingOut = () => {
    let params = new Map();
    params.set("comand", "leave");
    AxiosRequest(true, params).then(() => {
      CreateCokies("drx", "");
      CreateCokies("LicG", "");
      RemoveAllBG();
      dispatch(
        setSelectedSlice({
          ID: "",
          CLSID: "",
          Name: "",
          Image: "",
        }),
      );
      backToLoginPage();
    });
  };

  const handleClickAway = () => {
    setAnim(false);
    setTimeout(() => {
      setIsOpen(false);
    }, 499);
  };

  return (
    <AppBar
      className={cn("headerStimate", { light: theme === "light" })}
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar
        sx={{}}
        style={{
          minHeight: 48,
          height: 48,
          paddingLeft: !isEmptyObject(HeadUpdate) ? "0px" : "24px",
        }}
      >
        {!isEmptyObject(HeadUpdate) ? (
          <FormsMainFile head={true} jsonFormData={HeadUpdate} hideSectionTools style={{ overflow: "hidden" }} />
        ) : (
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2 }}
            className={cn("fontColorStimate", { light: theme === "light" })}
          >
            {/* {drx.replace(".drx", "")} */}
            {drx[2] + ` [${drx[0].replace(".drx", "")}]` + ` [${drx[1]}]`}
          </Typography>
        )}

        <Box sx={{ flexGrow: 1 }} />
        <Tooltip title="Уведомления">
          <IconButton id={"NotificationIcon"} onClick={toggleNotificationCenter}>
            <Badge badgeContent={unreadCount} color="primary">
              <NotificationsIcon
                className={cn("iconButtonStimate", {
                  light: theme === "light",
                })}
              />
            </Badge>
          </IconButton>
        </Tooltip>
        <IconButton
          style={{
            display: window.location.origin === "http://localhost:3000" ? "" : "none",
          }}
          size="large"
          onClick={addNotification}
          id="HeadTestButton"
        >
          <InputIcon className={cn("iconButtonStimate", { light: theme === "light" })} />
        </IconButton>
        <SupportButton />
        <UserProfile leave={HandleSingOut} />
      </Toolbar>
      <Popper
        onDoubleClick={() => {}}
        open={isOpen}
        anchorEl={anchorEl}
        // onResize={undefined}
        // onResizeCapture={undefined}
        style={{ inset: "-7px auto auto -69px", zIndex: "10", width: "450px" }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Grow in={anim} style={{ transformOrigin: "500px -100px 0px" }} {...(isOpen ? { timeout: 500 } : {})}>
            <Grid container direction="column" justifyContent="center" alignItems="center" style={{ width: "100%" }}>
              <Grid
                item
                className={cn("headerStimate", { light: theme === "light" })}
                sx={{
                  padding: "8px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  border: "1px solid",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <Typography
                  variant="h5"
                  className={cn("fontColorStimate", {
                    light: theme === "light",
                  })}
                >
                  Центр уведомлений
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  width: "100%",
                  borderWidth: "0px 1px 1px 1px",
                  borderStyle: "solid",
                }}
              >
                <Stack
                  sx={{
                    height: "400px",
                    width: "100%",
                    padding: "12px",
                    background: "#f1f1f1",
                    overflowY: "auto",
                  }}
                  spacing={2}
                >
                  {(!notifications.length || (unreadCount === 0 && showUnreadOnly)) && (
                    <Grid item>
                      <h4 style={{ textAlign: "center" }}>У вас нет уведомлений!</h4>
                    </Grid>
                  )}
                  {(showUnreadOnly ? notifications.filter((v) => !v.read) : notifications).map(
                    (notification: any, index: number) => {
                      return (
                        <Alert
                          key={`header-alert_${index}`}
                          severity={"info"}
                          action={
                            Number(notification.data.Options) & NotifyFlag_CanClose ? (
                              notification.read ? (
                                <CheckIcon />
                              ) : (
                                <Tooltip title="Завершить">
                                  <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    onClick={() => markAsDone(notification.id)}
                                  >
                                    <MarkChatReadIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            ) : (
                              false
                            )
                          }
                        >
                          <AlertTitle>
                            {notification.data.Time} {notification.data.Title}
                          </AlertTitle>

                          {!isEmptyObject(notification.data.Command) ? (
                            <Link
                              onClick={() =>
                                HandleNotificationClick(
                                  notification.data.Command,
                                  notification.data.Options,
                                  notification.id,
                                )
                              }
                              style={{ cursor: "pointer" }}
                              underline="hover"
                            >
                              {notification.data.Content}
                            </Link>
                          ) : (
                            notification.data.Content
                          )}
                        </Alert>
                      );
                    },
                  )}
                </Stack>
              </Grid>

              <Grid
                item
                style={{
                  width: "100%",
                  borderWidth: "0px 1px 1px 1px",
                  borderStyle: "solid",
                  borderRadius: "0px 0px 5px 5px",
                }}
                className={cn("headerStimate", { light: theme === "light" })}
                sx={{
                  padding: "8px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  sx={updateNotificationsButton()}
                  style={{
                    textTransform: "none",
                  }}
                  value={"refresh"}
                  onClick={Notifications}
                >
                  Обновить
                </Button>
                <Button
                  variant="outlined"
                  sx={updateNotificationsButton()}
                  style={{
                    textTransform: "none",
                  }}
                  value={"refresh"}
                  onClick={finishAll}
                >
                  Прочитать всё
                </Button>
                {/* <FormGroup sx={{ color: "var(--font-color-light)" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={toggleFilter}
                        checked={showUnreadOnly}
                      />
                    }
                    label="Показать только не завершенные"
                  />
                </FormGroup> */}
              </Grid>
            </Grid>
          </Grow>
        </ClickAwayListener>
      </Popper>
    </AppBar>
  );
};

export default DashboardNavbar;
