import { atom, createStore } from "jotai";
import { pluginLinks } from "./components/MainPage/Tools/Consts";
import { ConfigLk, ConfigSmeta, GetOptionsForUser } from "./components/Url";

export const smetaStore = createStore();
const userOptions = GetOptionsForUser();

export const isAuthorized = atom(false);
export const hasStartPageAtom = atom(false);
export const appConfig = atom<{ smeta: ConfigSmeta; lk: ConfigLk }>({
  smeta: { basename: "", pluginLinks },
  lk: {},
});
//salary
export const renderingIdAtom = atom({ renderingId: 0, personId: 0 });
export const groupIdAtom = atom(0);
export const salaryRangeAtom = atom({ year: "", month: "", range: "0" });
export const leavesOpenAtom = atom(false);
export const isAdminAtom = atom<boolean>(userOptions?.isAdmin);
export const isSuperUserAtom = atom<boolean>(userOptions?.isSuperUser);
export const configEnabledAtom = atom<boolean>(userOptions?.configEnabled);
export const personsTableCurrentId = atom(null);
export const helpContextAtom = atom<string | undefined>(userOptions?.helpContext);
export const sectionPersonsData = atom({});
export const userRightsAtom = atom<{ [key: string | number]: string }>({});
export const hideToastNotificationsAtom = atom<boolean>(userOptions?.hideToastNotifications || false);
