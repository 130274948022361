import { TabItem, Tabs } from "smart-webcomponents-react/tabs";
import CreateTicket from "./CreateTicket";
import TicketsList from "./TicketsList";
import React from "react";

export default function SupportRequest() {
  return (
    <Tabs id="supportRequestForm" style={{ width: "100%", height: "100%" }}>
      <TabItem
        id="NewTicket"
        label="Новая заявка"
        animation="none"
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
      >
        <CreateTicket />
      </TabItem>
      <TabItem
        id="TicketsList"
        label="Список заявок"
        animation="none"
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
      >
        <TicketsList />
      </TabItem>
    </Tabs>
  );
}