import { Grid } from "@mui/material";
import Switch from "@mui/material/Switch";
import React, { useState } from "react";
import { store } from "../../../../store";
import { setAnimations, setOneTabEqualOneSession, setTheme } from "../../../../store/themeSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import { UpdateOptionsForUser, get_cookie } from "../../../Url";
import TestEditor from "../../../Editor/testEditor";
import { isDeveloper } from "../../Tools/Tools";
import { useAtom } from "jotai";
import { hideToastNotificationsAtom } from "../../../../atoms";
import { toast } from "react-toastify";

// список для редактора
function getListForThemeEditor() {
  return [
    { label: "Бежевая", id: 0 },
    { label: "Синяя", id: 1 },
  ];
}

const localizeThemeEn = {
  Бежевая: "light",
  Синяя: "blue",
};

const localizeThemeRu = {
  light: "Бежевая",
  blue: "Синяя",
};

export default function ThemeChange(props) {
  const [checked, setChecked] = useState(store.getState().theme.animations);
  const [hideToastNotifications, setHideToastNotifications] = useAtom(hideToastNotificationsAtom);

  // текст для темы
  const [themeVal, setThemeVal] = useState(localizeThemeRu[store.getState().theme.theme]);

  function onEditTheme(data) {
    setThemeVal(data.value);

    // обновление внутренних данных редактора
    data.setValue({ label: data.value, id: data.tag });
    // если инпут не контролируемый то нужно обновлять значение тут:
    // data.setInputValue(data.value);
    store.dispatch(setTheme(localizeThemeEn[data.value]));
    UpdateOptionsForUser({ theme: localizeThemeEn[data.value] });
  }
  return (
    <Grid container direction={"column"} justifyContent={"center"} alignItems={"flex-start"} sx={{ m: 0, pl: 2 }}>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              disabled={!isDeveloper()}
              checked={checked}
              onChange={(event) => {
                setChecked(event.target.checked);
                store.dispatch(setAnimations(event.target.checked));
                UpdateOptionsForUser({ animations: event.target.checked });
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          sx={{ display: "none" }}
          label="Анимации"
          labelPlacement="end"
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={hideToastNotifications}
              onChange={(event) => {
                setHideToastNotifications(event.target.checked);
                UpdateOptionsForUser({ hideToastNotifications: event.target.checked });
                if (event.target.checked) toast.dismiss();
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Скрыть всплывающие уведомления"
          labelPlacement="end"
        />
      </Grid>
      <Grid
        style={{
          height: "27px",
          width: "250px",
          display: "none",
          alignItems: "center",
        }}
        item
      >
        <div style={{ width: "50px" }}>Тема:</div>
        <div
          style={{
            height: "27px",
            border: "1px solid #eeeeee",
          }}
        >
          <TestEditor
            // контролируемый input с помощью состояния
            value={themeVal}
            // функиця редактирования
            onEdit={onEditTheme}
            // стиль выпадающего списка = 1
            editStyle={65}
            // первый вариант списка
            onDropDownList={getListForThemeEditor}
            // альтернативный вариант списка
            // list={"Текущая тема\r\nТема 2"}
          />
        </div>
      </Grid>
    </Grid>
  );
}
