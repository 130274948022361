import { useAtomValue } from "jotai";
import React, { FC, IframeHTMLAttributes, useEffect, useRef } from "react";
import { helpContextAtom } from "../../../../atoms";
import Grid2 from "@mui/material/Unstable_Grid2";
import { IconButton } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { closeModal } from "../../Tools/modalManager";

const DialogHelpContext: FC = () => {
  const helpContextUrl = useAtomValue(helpContextAtom);
  const iframeRef = useRef<IframeHTMLAttributes | null>(null);
  useEffect(() => {
    if (!helpContextUrl) closeModal();
  }, []);

  return (
    <Grid2
      container
      direction="column"
      sx={{
        height: "100%",
        alignItems: "streach",
      }}
    >
      <Grid2 item>
        <IconButton
          onClick={() => {
            iframeRef.current.src = helpContextUrl;
          }}
        >
          <HomeIcon fontSize="small" />
        </IconButton>
      </Grid2>
      <Grid2 item sx={{ flex: "1 1 auto" }}>
        <iframe ref={iframeRef} src={helpContextUrl} style={{ height: "97%", width: "100%" }} />
      </Grid2>
    </Grid2>
  );
};

export default DialogHelpContext;
