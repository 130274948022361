/* eslint-disable @typescript-eslint/naming-convention */
import { getParent } from "../../MainPage/Tools/Requests";
import { ViewClass } from "../../MainPage/Tools/Consts";
import React, { lazy, Suspense } from "react";
import LoadingMask from "../../MainPage/NotWorkArea(Side&Head)/LoadingMask";

const DialogBook = lazy(() => import("../../MainPage/Module/Dialogs/DialogBook"));
const DialogClsBook = lazy(() => import("../../MainPage/Module/Dialogs/DialogClsBook"));
const DialogPerson = lazy(() => import("../../MainPage/Module/Dialogs/DialogPerson"));
const DialogOrganizations = lazy(() => import("../../MainPage/Module/Dialogs/DialogOrganisations"));
const TestTree = lazy(() => import("../../MainPage/Windows/ViewData/Tree/testTree"));
const DialogDepartments = lazy(() => import("../../MainPage/Module/Dialogs/DialogDepartments"));
const DialogPeriod = lazy(() => import("../../MainPage/Module/Dialogs/DialogPeriod"));
const DialogPeriodTime = lazy(() => import("../../MainPage/Module/Dialogs/DialogPeriodTime"));
const DialogPeriodCurrentYear = lazy(() => import("../../MainPage/Module/Dialogs/DialogPeriodCurrentYear"));
const DialogChildren = lazy(() => import("../../MainPage/Module/Dialogs/DialogChildren"));
const DialogStartAndEndDate = lazy(() => import("../../MainPage/Module/Dialogs/DialogStartAndEndDate"));
const DialogOficialStructure = lazy(() => import("../../MainPage/Module/Dialogs/DialogOficialStructure"));
const DialogAcctools = lazy(() => import("../../MainPage/Module/Dialogs/DialogAcctools"));
const DialogAddress = lazy(() => import("../../MainPage/Module/Dialogs/DialogAddress"));
const DialogDoc = lazy(() => import("../../MainPage/Module/Dialogs/DialogDoc"));
const DialogNFA = lazy(() => import("../../MainPage/Module/Dialogs/DialogNFA"));
const DialogDocTabs = lazy(() => import("../../MainPage/Module/Dialogs/DialogDocTabs"));
const DialogMultiSection = lazy(() => import("../../MainPage/Module/Dialogs/DialogMultiSection"));
const DialogTabsForReports = lazy(() => import("../../MainPage/Module/Dialogs/DialogTabsForReports"));
const DialogCalendar = lazy(() => import("../../MainPage/Module/Dialogs/DialogCalendar"));
const DialogHtml = lazy(() => import("../../Forms/DialogHtml"));
const TypesOfWorkTime = lazy(() => import("../../MainPage/Sections/ElementsSections/Salary/TypesOfWorkTime"));
const DialogPaytypes = lazy(() => import("../../MainPage/Module/Dialogs/DialogPayTypes"));
const DialogDistractions = lazy(() => import("../../MainPage/Module/Dialogs/DialogDistractions"));
const DialogChartOfCalculations = lazy(() => import("../../MainPage/Module/Dialogs/DialogChartOfCalculations"));

const getSuspenseForContent = (content) => {
  return <Suspense fallback={<LoadingMask />}>{content}</Suspense>;
};

const getDialogBook = (json, subData) => {
  const props = {
    RequestID: json?.Params?.RequestID,
    ...subData.props,
    ObjType: json?.Params?.ObjType || subData.props.ObjType,
    KeyField: json.KeyField || json.FieldName || "ID",
    CLSID: json.CLSID,
    EditorMode: true,
    style: { height: 300, width: 400 },
    ObjRef: subData.props.data?.ObjRef || subData.props.ObjRef,
    Module: json.Module || subData.props.Module,
    ID: subData.props.ObjRef,
  };
  return getSuspenseForContent(<DialogBook props={props} />);
};

const getDialogClsBook = (json, subData) => {
  const props = {
    RequestID: json?.Params?.RequestID,
    ...subData.props,
    KeyField: json.KeyField || json.FieldName || "Code",
    CLSID: json.CLSID,
    EditorMode: true,
    style: { height: 300, width: 400 },
    ID: json.ID,
  };
  return getSuspenseForContent(<DialogClsBook props={props} />);
};

const getDialogPerson = (json, subData) => {
  const props = {
    RequestID: json?.Params?.RequestID,
    ...subData.props,
    CLSID: json.CLSID,
    DlgType: json.DlgType,
    NodeID: json.NodeID,
  };

  return getSuspenseForContent(<DialogPerson props={props} />);
};

const getDialogOrganizations = (json, subData) => {
  const props = {
    RequestID: json?.Params?.RequestID,
    ...subData.props,
    CLSID: json.CLSID,
    NodeID: json.NodeID,
    Title: json.title,
    ObjRef: json.ParentID || subData.props.ObjRef,
    Selector: json.Selector,
  };
  return getSuspenseForContent(<DialogOrganizations props={props} />);
};

const getDialogTree = (json, subData) => {
  const props = {
    RequestID: json?.Params?.RequestID,
    ...subData.props,
    CLSID: json.CLSID,
    showButtons: true,
  };
  return getSuspenseForContent(<TestTree props={props} />);
};

const getDialogDepartments = (json, subData) => {
  const props = {
    RequestID: json?.Params?.RequestID,
    ...subData.props,
    CLSID: json.CLSID,
    ID: json.ID,
  };
  return getSuspenseForContent(<DialogDepartments props={props} />);
};

const getDialogPeriod = (json, subData) => {
  return getSuspenseForContent(
    <DialogPeriod
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
        CLSID: json.CLSID,
        Info: json.Info,
      }}
    />,
  );
};

const getDialogPeriodTime = (json, subData) => {
  return getSuspenseForContent(
    <DialogPeriodTime
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
        CLSID: json.CLSID,
      }}
    />,
  );
};

const getDialogPeriodCurrentYear = (json, subData) => {
  return getSuspenseForContent(
    <DialogPeriodCurrentYear
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
        CLSID: json.CLSID,
      }}
    />,
  );
};

const getDialogChildren = (json, subData) => {
  const parentData = getParent(json.PersonID);
  const parent = parentData?.Text || "";

  return getSuspenseForContent(
    <DialogChildren
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
        CLSID: json.CLSID,
        PersonID: json.PersonID,
        parent: parent,
      }}
    />,
  );
};

const getDialogStartAndEndDate = (json, subData) => {
  return getSuspenseForContent(
    <DialogStartAndEndDate
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
        CLSID: json.CLSID,
      }}
    />,
  );
};

const getDialogOficialStructure = (json, subData) => {
  return getSuspenseForContent(
    <DialogOficialStructure
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
        CLSID: json.CLSID,
        NoFormServer: json.NoFormServer,
        ControlHeight: json.ControlHeight,
        NodeID: json.NodeID,
        ObjRef: json.ID,
      }}
    />,
  );
};

const getDialogAcctools = (json, subData) => {
  return getSuspenseForContent(
    <DialogAcctools
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
        CLSID: json.CLSID,
      }}
    />,
  );
};

const getDialogAddress = (json, subData) => {
  return getSuspenseForContent(
    <DialogAddress
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
      }}
    />,
  );
};

const getDialogDoc = (json, subData) => {
  return getSuspenseForContent(
    <DialogDoc
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
        CLSID: json.CLSID,
        Title: json.title,
      }}
    />,
  );
};

const getDialogNFA = (json, subData) => {
  return getSuspenseForContent(
    <DialogNFA
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
        CLSID: json.CLSID,
        NodeID: json.NodeID,
        Title: json.title,
        Module: "storobj",
      }}
    />,
  );
};
const getDialogDocTabs = (json, subData) => {
  return json.SelectDocument
    ? getSuspenseForContent(
        <DialogDocTabs
          props={{
            RequestID: json?.Params?.RequestID,
            ...subData.props,
            CLSID: json.CLSID,
            Title: json.title,
            SelectDocument: json.SelectDocument,
          }}
        />,
      )
    : getDialogDoc(json, { ...subData, props: { PlaneView: "1", ...subData.props, detailsOpen: true } });
};

const getDialogMultiSection = (json, subData) => {
  return getSuspenseForContent(
    <DialogMultiSection
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
        json: json,
      }}
    />,
  );
};

const getDialogTabsForReports = (json, subData) => {
  return getSuspenseForContent(
    <DialogTabsForReports
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
        json: json,
      }}
    />,
  );
};

const getDialogCalendar = (json, subData) => {
  return getSuspenseForContent(
    <DialogCalendar
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
        json: json,
      }}
    />,
  );
};

const getDialogChartOfCalculations = (json, subData) => {
  return getSuspenseForContent(
    <DialogChartOfCalculations
      props={{
        RequestID: json.Params ? json.Params.RequestID : undefined,
        ...subData.props,
        ...json,
      }}
    />,
  );
};

const getDialogHtml = (json, subData) => {
  return getSuspenseForContent(
    <DialogHtml
      htmlInput={subData.props.content || ""}
      RequestID={subData.props.RequestID}
      Buttons={subData.props.Buttons}
      Module={json.Module}
    />,
  );
};

const getDialogTypesOfWorkTime = (json, subData) => {
  return getSuspenseForContent(
    <TypesOfWorkTime
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
        json: json,
      }}
    />,
  );
};

const getDialogPaytypes = (json, subData) => {
  return getSuspenseForContent(
    <DialogPaytypes
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
      }}
    />,
  );
};

const getDialogDistractions = (json, subData) => {
  return getSuspenseForContent(
    <DialogDistractions
      props={{
        RequestID: json?.Params?.RequestID,
        ...subData.props,
      }}
    />,
  );
};

const getDialogDefault = (json, subData) => {
  if (json.CLSID) {
    return <div style={{ width: "100%", height: "100%" }}>Функционал в разработке</div>;
  }

  if (json.DlgType) {
    const dlgTypes = {
      tree: getDialogTree,
      complex: getDialogPerson,
    };
    return (
      dlgTypes[json.DlgType]?.(json, subData) || (
        <div style={{ width: "100%", height: "100%" }}>Функционал в разработке</div>
      )
    );
  }

  return <div style={{ width: "100%", height: "100%" }}>Функционал в разработке</div>;
};

export default function HandleTable({ json, subData }) {
  let content;

  content = {
    [ViewClass.TableRep]: getDialogBook,
    [ViewClass.ClsBook]: getDialogClsBook,
    [ViewClass.PersonBook]: getDialogPerson,
    [ViewClass.OrgBook]: getDialogOrganizations,
    [ViewClass.TreeView]: getDialogTree,
    [ViewClass.Departs]: getDialogDepartments,
    [ViewClass.CalcRange]: getDialogPeriod,
    [ViewClass.LongTime]: getDialogPeriodTime,
    [ViewClass.MonthRange]: getDialogPeriodCurrentYear,
    [ViewClass.ChildSel]: getDialogChildren,
    [ViewClass.DateRange]: getDialogStartAndEndDate,
    [ViewClass.SelApp]: getDialogOficialStructure,
    [ViewClass.AccCors]: getDialogAcctools,
    [ViewClass.Address]: getDialogAddress,
    [ViewClass.DocBook]: getDialogDoc,
    [ViewClass.StgBook]: getDialogNFA,
    [ViewClass.DocSite]: getDialogDocTabs,
    [ViewClass.MultiTab]: getDialogMultiSection,
    [ViewClass.HTMLView]: getDialogTabsForReports,
    [ViewClass.CellObj]: getDialogTabsForReports,
    [ViewClass.Calendar]: getDialogCalendar,
    [ViewClass.HTMLEdit]: getDialogHtml,
    [ViewClass.TimeCfg]: getDialogTypesOfWorkTime,
    [ViewClass.PayTypeSet]: getDialogPaytypes,
    [ViewClass.Absences]: getDialogDistractions,
    [ViewClass.Ranges]: getDialogChartOfCalculations,
  };

  return content[json.CLSID]?.(json, subData) || getDialogDefault(json, subData);
}
