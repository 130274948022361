import { Link, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useAtomValue } from "jotai";
import React, { useEffect, useState } from "react";
import { appConfig, isAdminAtom } from "../../../../atoms";
import { AxiosRequest } from "../../../Url";
import { Rights } from "../../Tools/ProjectX";
import { selectNewLicenseToInstall } from "../../Tools/Tools";

const DialogAboutSite = () => {
  const {
    smeta: { stimateVersion: version },
  } = useAtomValue(appConfig);
  const userIsAdmin = useAtomValue(isAdminAtom);

  const [info, setInfo] = useState({
    CFGVer: "",
    Configuration: "",
    OCXVer: "",
    Release: "",
    Rights: "",
    SystemName: "",
    Using: "",
    Version: "",
  });

  useEffect(() => {
    const params = new Map();
    params.set("comand", "info").set("Info", "1").set("JSON", "1");
    AxiosRequest(true, params).then((resp: typeof info) => {
      setInfo(resp);
    });
  }, []);

  const resetLicense = (rcdata: string) => {
    const params = new Map();
    params.set("comand", "ResetLicense");
    AxiosRequest(true, params, { RCDATA: rcdata }).then((resp: typeof info) => {
      if (resp.SystemName && resp.Using) setInfo(resp);
    });
  };

  return (
    <Grid2
      direction={"column"}
      spacing={1}
      sx={{
        alignItems: "center",
        justifyContent: "stretch",
        pl: 3,
        pr: 3,
      }}
    >
      <Grid2 item>
        <Grid2
          container
          direction={"column"}
          sx={{
            alignItems: "center",
            justifyContent: "stretch",
          }}
        >
          <Grid2 item>
            <Typography variant="h5">
              Автоматизированная система
              {/* <br /> */}
            </Typography>
          </Grid2>
          <Grid2 item>
            <Typography variant="h4">{info.SystemName}</Typography>
          </Grid2>
          <Grid2 item>
            <Typography variant="h6" gutterBottom>
              {info.Configuration}
            </Typography>
          </Grid2>
          <Grid2 item>
            <Typography variant="subtitle2" gutterBottom>
              {info.Release}
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 item>
        <Grid2
          container
          direction={"column"}
          sx={{
            alignItems: "flex-start",
            justifyContent: "stretch",
            borderTop: "1px solid gray",
            borderBottom: "1px solid gray",
          }}
        >
          <Grid2 item>
            <Typography variant="subtitle2" gutterBottom sx={{ whiteSpace: "pre-wrap" }}>
              {info.Using}
            </Typography>
          </Grid2>
          {(userIsAdmin || userIsAdmin || Number(info.Rights) === Rights.All) && (
            <Grid2
              item
              sx={{
                width: "100%",
              }}
            >
              <Grid2 container direction={"row-reverse"}>
                <Grid2 item>
                  <Link
                    underline="hover"
                    onClick={() => {
                      selectNewLicenseToInstall().then((fileData) => {
                        if (fileData && fileData.reqData.FileName && fileData.files) {
                          const fileName = fileData.reqData.FileName;
                          const rcdata = fileData.files[fileName].rcdata;
                          resetLicense(rcdata);
                        }
                      });
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    Обновить лицензию
                  </Link>
                </Grid2>
              </Grid2>
            </Grid2>
          )}
          {/* <Grid2 item> {info.Release}</Grid2> */}
        </Grid2>
      </Grid2>
      <Grid2 item>
        <Grid2
          container
          direction={"column"}
          sx={{
            alignItems: "flex-start",
            justifyContent: "stretch",
          }}
        >
          <Grid2 item>
            <Typography variant="subtitle2" gutterBottom sx={{ whiteSpace: "pre-wrap" }}>
              {`Версия сайта: ${version}`}
            </Typography>
          </Grid2>
          <Grid2 item>
            <Typography variant="subtitle2" gutterBottom sx={{ whiteSpace: "pre-wrap" }}>
              {`Версия сервера: ${info.Version}`}
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default DialogAboutSite;
