import { defaultFieldInfo } from "../../../Tools/Tools";

export function getscheduleColumns(codeField: { displayWidth: number }) {
  let columns = {
    title: [
      {
        fieldName: "ID",
        title: "ID",
        width: 60,
        level: 0,
        hide: true,
        hideInGrid: true,
        hideInCard: true,
        fieldInfo: { ...defaultFieldInfo },
      },
      {
        fieldName: "Code",
        title: "Код",
        width: codeField ? codeField.displayWidth * 15 : 120,
        level: 0,
        fieldInfo: { ...defaultFieldInfo, EditStyle: "64" },
        collapseRecord: true,
      },
      {
        fieldName: "Name",
        title: "Наименование",
        width: 300,
        level: 0,
        fieldInfo: { ...defaultFieldInfo },
      },
      {
        fieldName: "DayMask",
        title: "DayMask",
        width: 120,
        level: 0,
        hide: true,
        hideInGrid: true,
        hideInCard: true,
        fieldInfo: { ...defaultFieldInfo, EditStyle: "64" },
      },
      {
        fieldName: "ModeID",
        title: "ModeID",
        width: 120,
        level: 0,
        hide: true,
        hideInGrid: true,
        hideInCard: true,
        fieldInfo: { ...defaultFieldInfo },
      },
      {
        fieldName: "WorkRange",
        title: "Время работы",
        width: 120,
        level: 0,
        fieldInfo: { ...defaultFieldInfo, EditStyle: "64" },
      },
      {
        fieldName: "WorkStart",
        title: "Начало",
        width: 120,
        level: 1,
        fieldInfo: { ...defaultFieldInfo, EditMask: "##-##" },
      },
      {
        fieldName: "WorkEnd",
        title: "Конец",
        width: 120,
        level: 1,
        fieldInfo: { ...defaultFieldInfo, EditMask: "##-##" },
      },
      {
        fieldName: "BreakRange",
        title: "Перерыв",
        width: 120,
        level: 0,
        fieldInfo: { ...defaultFieldInfo, EditStyle: "64" },
      },

      {
        fieldName: "BreakStart",
        title: "Начало",
        width: 120,
        level: 1,
        fieldInfo: { ...defaultFieldInfo, EditMask: "##-##" },
      },
      {
        fieldName: "BreakEnd",
        title: "Конец",
        width: 120,
        level: 1,
        fieldInfo: { ...defaultFieldInfo, EditMask: "##-##" },
      },

      {
        fieldName: "HourNorm",
        title: "Норма часов",
        width: 120,
        level: 0,
        fieldInfo: { ...defaultFieldInfo, EditStyle: "64" },
      },
      {
        fieldName: "DayNorm",
        title: "Дневная",
        width: 120,
        level: 1,
        fieldInfo: { ...defaultFieldInfo, EditStyle: "64" },
      },
      {
        fieldName: "EveningNorm",
        title: "Вечерняя",
        width: 120,
        level: 1,
        fieldInfo: { ...defaultFieldInfo, EditStyle: "64" },
      },
      {
        fieldName: "NightNorm",
        title: "Ночная",
        width: 120,
        level: 1,
        fieldInfo: { ...defaultFieldInfo, EditStyle: "64" },
      },
      {
        fieldName: "UnitNorm",
        title: "Выработка",
        width: 120,
        level: 0,
        fieldInfo: { ...defaultFieldInfo },
      },
    ],
  };

  return columns;
}
