import { AxiosRequest } from "../../../Url";
import DialogDayType from "../../Module/Dialogs/DialogDayType";
import { openModal } from "../../Tools/modalManager";
import { EditStyle } from "../../Tools/ProjectX";
import { defaultFieldInfo, getRightsData, getStyleWindow } from "../../Tools/Tools";
import { parseColumns } from "../GridTools/parseColumnTools";

export async function tablesDistractionsGrid(grid, source, props, data) {
  source.onHandleRequest = handleRequest(props);

  await source.open();

  let columns = {
    title: [
      {
        fieldName: "ID",
        title: "ID",
        width: 30,
        level: 0,
        hide: true,
        hideInGrid: true,
        hideInCard: true,
        fieldInfo: { ...defaultFieldInfo },
        collapseRecord: true,
      },
      {
        fieldName: "Mask",
        title: "№ п.п.",
        width: 60,
        level: 0,
        fieldInfo: { ...defaultFieldInfo },
        collapseRecord: true,
      },
      {
        fieldName: "Name",
        title: "Наименование",
        width: 160,
        level: 0,
        fieldInfo: {
          ...defaultFieldInfo,
          onEditField: true,
          squareColorField: "Color",
          EditStyle: EditStyle.ColorBox + EditStyle.Ellipsis,
        },
      },
      {
        fieldName: "ShortName",
        title: "Сокр.",
        width: 60,
        level: 0,
        fieldInfo: { ...defaultFieldInfo },
      },
      {
        fieldName: "DayMask",
        title: "Влияет на дни",
        width: 160,
        level: 0,
        fieldInfo: { ...defaultFieldInfo, EditStyle: "16", onEditButtonClick: openDayType },
      },
      {
        fieldName: "PayTypeCode",
        title: "Вид оплаты",
        width: 160,
        level: 0,
        fieldInfo: { ...defaultFieldInfo, EditStyle: "17", DataType: "2380", Module: "paytypes" },
      },
      {
        fieldName: "Code",
        title: "Code",
        width: 30,
        level: 0,
        hide: true,
        hideInGrid: true,
        hideInCard: true,
        fieldInfo: { ...defaultFieldInfo },
      },
      {
        fieldName: "Options",
        title: "Options",
        width: 120,
        level: 0,
        hide: true,
        hideInGrid: true,
        hideInCard: true,
        fieldInfo: {
          ...defaultFieldInfo,
        },
      },
    ],
  };

  if (data.json) {
    source.rights = getRightsData(data.json.Rights);

    columns = parseColumns(data.json, columns);
    grid.setColumns(columns);
    source.setKeyFieldName("ID");
    source.rights = getRightsData(data.json.Rights);
  } else grid.setColumns(columns);

  if (data.setSelectedRecord) {
    source.onRecordIndexChanged = async function (source) {
      data.setSelectedRecord(source.getFieldTextSync("ID"));
    };
  }

  source.rights = getRightsData("-1");

  await grid.setSource(source);

  if (props.ID) {
    await grid.refreshSource(props.ID);
  } else await grid.refreshSource();
  await grid.updateGridSize();
  if (data.setCountRecords) data.setCountRecords(source.recordCount);
  if (data.setSelectedRecord) {
    data.setSelectedRecord(source.getFieldTextSync("ID"));
  }
}

function handleRequest(props) {
  return function onHandleRequest(request) {
    const params = new Map();
    params.set("prefix", props.Module).set("comand", "HandleTable").set("ObjType", props.ObjType);
    return AxiosRequest(true, params, request);
  };
}

const openDayType = (data) => {
  openModal(<DialogDayType props={data.props} />, {
    Title: "Маска типов дней",
    style: getStyleWindow("DayTypes"),
    hiddenButton: true,
  });
};
