import React, { useEffect, useRef, useState } from "react";
import { tokenProcessingTest } from "../../../../TokenProcessing/TokenProcessing";
import { AxiosRequest } from "../../../../Url";
import ContextMenu from "../../../NotWorkArea(Side&Head)/ContextMenu";
import { ViewClass } from "../../../Tools/Consts";
import { SmetaTabItem } from "../../../Tools/Tabs/PinIcons";
import SmetaTabs from "../../../Tools/Tabs/SmetaTabs";
import { clickInFrame, createPdfIframe, isEmptyObject } from "../../../Tools/Tools";

interface CreateIframeProps {
  html?: string;
  viewIdent: string;
}

interface ReportObj {
  RCDATA?: string;
  Buttons?: string;
  CLSID: string;
  Fixed?: string;
  Caption?: string;
  Module?: string;
  Title: string;
  ViewIdent: string;
  content?: string;
  Notes?: string;
}

type ReportItems = Array<ReportObj>;

interface ReportData {
  CLSID: string;
  Items?: ReportItems;
  TabIndex?: string;
  ViewIdent: string;
  content?: string;
  Module?: string;
}

interface TabsForReportsProps {
  reportData: ReportData;
  ID?: string;
  groupId?: string;
  onTabIndexChange?: (block?: string, needRefresh?: string | boolean) => Promise<void>;
  onViewIdentChange?: (viewIdent: string) => void;
  onReportModuleChange?: (reportModule: string) => void;
}

export default function TabsForReports(props: TabsForReportsProps) {
  const contextMenuFunc = useRef(null);
  const selectedViewIdentRef = useRef("");
  const [tabsData, setTabsData] = useState<ReportData>();
  const [tabIndex, setTabIndex] = useState(0);
  const reportDataModule = useRef("cellobj");

  const pageChangedReq = async (viewIdent: string) => {
    const params = reptabsParams(viewIdent, "PageChanged");
    const pageChangedResponse = await AxiosRequest(false, params);
    return pageChangedResponse;
  };

  const onPageChanged = async (index = 0, viewIdent: string, reportModule: string) => {
    setTabIndex(isNaN(index) ? 0 : index);
    selectedViewIdentRef.current = viewIdent;
    const pageChangedResponse = await pageChangedReq(viewIdent);
    if (pageChangedResponse.NeedRefresh === "1" && props.onTabIndexChange) {
      await props.onTabIndexChange(undefined, true);
    }
    if (props.onViewIdentChange) {
      await props.onViewIdentChange(viewIdent);
    }
    props.onReportModuleChange?.(reportModule);
  };

  useEffect(() => {
    try {
      if (!isEmptyObject(props.reportData)) {
        const reports = props.reportData;
        reportDataModule.current = reports.Module || "cellobj";
        let reportViewIdent = "";
        const commonViewIdent = reports.ViewIdent;
        const selectedReportTabIndex = Number(reports.TabIndex ?? 0);
        selectedViewIdentRef.current = commonViewIdent;
        if (reports.Items) {
          reportViewIdent = reports.Items[selectedReportTabIndex].ViewIdent;
          selectedViewIdentRef.current += `.${reportViewIdent}`;
        }
        onPageChanged(selectedReportTabIndex, selectedViewIdentRef.current, reportDataModule.current);
        if (!isNaN(selectedReportTabIndex)) setTabIndex(Number(selectedReportTabIndex));
        setTabsData(reports);
      } else {
        reportDataModule.current = "cellobj";
        props.onReportModuleChange?.(reportDataModule.current);
      }
    } catch (err) {
      console.log(err);
    }
  }, [props.reportData]);

  const reptabsParams = (viewIdent: string, comand: string) => {
    const params = new Map();
    params.set("prefix", "reptabs");
    params.set("comand", comand);
    params.set("ViewIdent", viewIdent);
    return params;
  };

  const updateArrayOfItems = (content: object, index: number, tabs = tabsData): ReportData => {
    if (tabs && tabs.Items && tabs.hasOwnProperty("Items")) {
      const items = tabs.Items.map((item, i) => {
        if (i === index) return { ...item, ...content };
        return item;
      });
      return { ...tabs, Items: items, CLSID: tabs.CLSID || "" };
    } else {
      return { ...(tabs || {}), ...content, CLSID: tabs?.CLSID || "", ViewIdent: tabs?.ViewIdent || "" };
    }
  };

  const closeTabPageReq = async (viewIdent: string) => {
    const params = reptabsParams(viewIdent, "CloseTabPage");
    await AxiosRequest(true, params);
  };

  const getPageContetReq = async (viewIdent: string) => {
    const params = reptabsParams(viewIdent, "GetPageContent");
    params.set("HTML", 0);
    const pageContent = await AxiosRequest(false, params);
    return pageContent;
  };

  const onTabClose = async (indices, data) => {
    await closeTabPageReq(data.oldData.viewIdent); //запрос на закрытие вкладки
    const items = tabsData && tabsData.Items ? [...tabsData.Items] : []; //копируем массив для удаления таба
    items.splice(indices.oldIndex, 1); //удаляем таб
    let tabs = { ...tabsData, Items: items }; //данные что бы обновить табы
    await onPageChanged(indices.newIndex, data.newData.viewIdent, data.newData.reportModule); //сообщаем серверу что сменили вкладку
    if (!items[indices.newIndex]?.content) {
      //если у новой вкладки нет контента
      const pageContent = await getPageContetReq(data.newData.viewIdent); //получаем контент для новой вкладки
      tabs = updateArrayOfItems(pageContent, indices.newIndex, tabs); //обновляем массив табов с новым контентом
    }
    setTabsData(tabs); //устанавливаем новые табы
  };

  const onTabPin = async (pinned, index, data) => {
    const params = reptabsParams(data.viewIdent, "FixupTabPage");
    params.set("Fixed", !pinned ? "1" : "0");
    await AxiosRequest(true, params);
    const newTabsData = updateArrayOfItems({ Fixed: !pinned ? "1" : "0" }, index);
    setTabsData(newTabsData);
  };

  const singleTab = (tab: ReportObj, index: number) => {
    const viewIdent = tabsData ? tabsData.ViewIdent + "." + tab.ViewIdent : tab.ViewIdent;
    const content = getTabContet(tab, viewIdent);
    const canPin = Number(tab.Buttons) & 2 ? true : false;
    const pinned = tab.Fixed === "1";
    const reportModule = tab.Module || "cellobj";
    return (
      <SmetaTabItem
        key={index}
        pinned={canPin ? pinned : undefined}
        closable={Number(tab.Buttons) & 1 ? true : false}
        label={tab.Title}
        data={{ viewIdent, reportModule }}
        title={tab.Notes}
      >
        {content}
      </SmetaTabItem>
    );
  };

  const renderTabs = () => {
    //может прийти один отчёт без табов
    if (!tabsData) return null;
    if (tabsData.content) return singleTab(tabsData, 0);
    if (!tabsData.Items) return;
    return tabsData.Items.map((tab, index) => singleTab(tab, index));
  };

  const onFrameLoad = (e, viewIdent) => {
    const iframe = e.target;
    clickInFrame(viewIdent, iframe, { ID: props.ID, linkRef: true, changeText: true });
    iframe.contentDocument.oncontextmenu = function (e) {
      e.preventDefault();
      const rect = iframe.getBoundingClientRect();
      contextMenuFunc.current({
        clientX: e.clientX + rect.x,
        clientY: e.clientY + rect.y,
        preventDefault: function () {
          e.preventDefault();
        },
      });
    };
  };

  const createSimpleIframe = (html = "", viewIdent: string): JSX.Element => {
    const fixedHtml = String(html).replaceAll("overflow: hidden;", "").replaceAll('onClick="ClickCells(event)"', "");
    const iframe = (
      <iframe
        srcDoc={fixedHtml}
        onLoad={(e) => onFrameLoad(e, viewIdent)}
        height="100%"
        className="iframeStimateCustom"
        style={{ width: "100%", height: "100%" }}
      />
    );
    return iframe;
  };

  function getTabContet(form: ReportObj, viewIdent: string) {
    const iframeContentHandlers = {
      [ViewClass.HTMLView]: createSimpleIframe, // простой отчёт
      [ViewClass.PDFView]: createPdfIframe, // PDF
    };
    const handler = iframeContentHandlers[form.CLSID] || createSimpleIframe;
    return handler(form.content || form.RCDATA, viewIdent);
  }

  const onTabIndexChange = async (index, tabContent, dataAboutTab) => {
    await onPageChanged(index, dataAboutTab.viewIdent, dataAboutTab.reportModule);
    if (!tabContent.firstChild.srcDoc) {
      const pageContent = await getPageContetReq(dataAboutTab.viewIdent);
      if (!isEmptyObject(pageContent)) {
        const newTabsData = updateArrayOfItems(pageContent, index);
        setTabsData(newTabsData);
      }
    }
  };

  function getTabPosition() {
    if (!isEmptyObject(props.reportData)) {
      if (props.reportData.Items) {
        return "bottom";
      } else {
        return "hidden";
      }
    } else {
      return "hidden";
    }
  }

  function contextMenuData() {
    const params = new Map();
    params.set("prefix", reportDataModule.current);
    params.set("comand", "GetDocumentMenu");
    params.set("CLSID", ViewClass.CellObj);
    return AxiosRequest(true, params);
  }

  function execMenuHandler(data) {
    const params = new Map();
    const viewIdent = selectedViewIdentRef.current.split(".");
    params.set("prefix", reportDataModule.current);
    params.set("comand", "ExecMenuHandler");
    params.set("ProgID", data.ID);
    params.set("ViewIdent", viewIdent.length > 1 ? viewIdent[1] : viewIdent[0]);
    params.set("WSM", "1");
    AxiosRequest(true, params).then((res) => {
      tokenProcessingTest(res);
    });
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {props.reportData.NoContextMenu ? (
        <SmetaTabs
          position={getTabPosition()}
          onTabPin={onTabPin}
          onTabClose={onTabClose}
          onTabIndexChange={onTabIndexChange}
          tabIndex={tabIndex}
          style={{ height: "100%" }}
          id={`ReportsTabs${props.groupId}`}
        >
          {renderTabs()}
        </SmetaTabs>
      ) : (
        <ContextMenu
          for={"reports"}
          onRightClick={contextMenuData}
          getContextMenuFunction={(func) => {
            contextMenuFunc.current = func;
          }}
          onMenuItemClick={execMenuHandler}
        >
          <SmetaTabs
            position={getTabPosition()}
            onTabPin={onTabPin}
            onTabClose={onTabClose}
            onTabIndexChange={onTabIndexChange}
            tabIndex={tabIndex}
            style={{ height: "100%" }}
            id={`ReportsTabs${props.groupId}`}
          >
            {renderTabs()}
          </SmetaTabs>
        </ContextMenu>
      )}
    </div>
  );
}
