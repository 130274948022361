import React from "react";
import styles from "../styles.module.css";
import { AxiosRequest } from "../../../../../Url";
import * as mime from "react-native-mime-types";

interface Props {
  id: string;
  label: string;
}

interface UpdatesHelp {
  AlfaTesting: string;
  BetaTesting: string;
  ConfigVersion: string;
  FileName: string;
  RCDATA: string;
  SupLicGUID: string;
  mimeType: string;
}

const getUpdatesHelp = async (id: string) => {
  const params = new Map([
    ["prefix", "updates"],
    ["comand", "GetUpdatesHelp"],
    ["ID", id],
    ["Client", "1"],
  ]);
  return AxiosRequest(true, params);
};

function downloadReport(data: UpdatesHelp) {
  const a = document.createElement("a");
  a.href = "data:" + data.mimeType + ";base64," + data.RCDATA;
  a.download = `${data.FileName}`;
  a.click();
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function NotesButton({ id, label }: Props) {
  const onClick = async () => {
    const json = await getUpdatesHelp(id);
    downloadReport({ ...json, mimeType: mime.lookup(json.FileName).slice(-1)[0] });
  };

  return (
    <a title={`Скачать описание обновления: ${label}`} className={styles.dialogRef} onClick={onClick}>
      {label}
    </a>
  );
}
