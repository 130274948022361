// <item
// ID="9754"
// Name="Обновление № 243"
// Version="1"
// Date="43637"
// Notes="Доработки по зарплате"
// Help="1"
// GUID="{F8E903F1-0B95-415F-B9CC-A4068627C4C1}"
// Status="Установлено [вер.1] (18.10.2019 18:08:19) [Администратор]"/>

import { defaultFieldInfo } from "../../../../Tools/Tools";

export const getDefaultFieldsDlgInstScrptsUpdt = () => {
  return [
    {
      Name: "ID",
      Title: "ID",
      Width: "7",
    },
    {
      Name: "Name",
      Title: "Наименование",
      Width: "10",
    },
    {
      Name: "Date",
      Title: "Дата",
      Width: "20",
    },
    {
      Name: "Version",
      Title: "Версия",
      Width: "7",
    },
    {
      Name: "Status",
      Title: "Статус",
      Width: "30",
    },
    {
      Name: "Notes",
      Title: "Описание",
      Width: "30",
    },
    {
      Name: "Help",
      Title: "",
      Width: "30",
    },
    {
      Name: "GUID",
      Title: "",
      Width: "30",
    },
  ];
};

export const getColumnsDlgInstScrptsUpdt = () => {
  return {
    title: [
      {
        fieldName: "ID",
        title: "ID",
        width: 50,
        hide: true,
        hideInGrid: true,
        hideInCard: true,
        fieldInfo: {
          ...defaultFieldInfo,
        },
      },
      {
        fieldName: "Name",
        title: "Наименование",
        width: 200,
        fieldInfo: {
          ...defaultFieldInfo,
        },
      },
      {
        fieldName: "Date",
        title: "Дата",
        width: 100,
        fieldInfo: {
          ...defaultFieldInfo,
        },
      },
      {
        fieldName: "Version",
        title: "Версия",
        width: 100,
        fieldInfo: {
          ...defaultFieldInfo,
        },
      },
      {
        fieldName: "Status",
        title: "Статус",
        width: 100,
        fieldInfo: {
          ...defaultFieldInfo,
        },
      },
      {
        fieldName: "Notes",
        title: "Описание",
        width: 200,
        fieldInfo: {
          ...defaultFieldInfo,
        },
      },
    ],
  };
};
