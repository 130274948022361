import React, { FC } from "react";
import Link from "@mui/material/Link";
import { Typography } from "@mui/material";

export interface FormItemInterface {
  Anchors?: string;
  Left?: number;
  Top?: number;
  Type?: string | boolean;
  Right?: number;
  Bottom?: number;
  AutoSize?: string;
  "Back-color"?: string;
  "Font-name"?: string;
  "Font-color"?: string;
  "Font-family"?: string;
  "Font-style"?: string;
  "Font-size"?: string;
  fontSizeParent?: string;
  Reference?: string;
  widthFromParent: string;
  heightFromParent: string;
  Alignment?: string;
  BorderWidth?: string;
  Enabled?: string;
  Parent: string;
  Name: string;
  Width: string;
  Height: string;
  Visible?: string;
  EventHandler?: string;
  NamePopupMenu?: string;
  OnMouseDown?: string;
  ShowCaption?: boolean;
  ShowHint?: boolean;
  TabOrder?: number;
  Tag?: number;
  Text?: string;
  Target?: string;
  ParentColor?: string;
  ClientColor?: string;
}
const fontWeight = {
  жирный: "bold",
  курсив: "italic",
  подчеркнутый: "underline",
};

const converAlignment = {
  центр: "center",
  влево: "left",
  вправо: "right",
};

interface AnchorsUseParams {
  json: FormItemInterface;
  jsonStyle: React.CSSProperties;
}

interface AnchorsValues {
  [key: string]: { [key: string]: string };
}

export const parseWebStyle = (styles?: string) => {
  if (!styles) return {};
  try {
    const parsedJson = JSON.parse(styles);
    return parsedJson;
  } catch (err) {
    console.error("Ошибка парсинга webStyles", err);
    return {};
  }
};

export const anchorsUse = ({ json, jsonStyle }: AnchorsUseParams): React.CSSProperties => {
  const {
    Anchors: anchors,
    Left: left,
    Top: top,
    Type: type,
    Right: rightFromServer,
    Bottom: bottomFromServer,
    Width: width,
    Height: height,
  } = json;
  if (anchors !== undefined) {
    let styles = { ...jsonStyle, left: "", top: "" };
    const leftLocal = left || 0,
      anchorsArr = anchors.split(",");
    let topLocal = top || 0,
      returnj = jsonStyle;

    let right = Number(json.widthFromParent) - Number(left) - Number(width);
    right = isNaN(right) ? undefined : right;
    let bottom = Number(json.heightFromParent) - Number(top) - Number(height);
    bottom = isNaN(bottom) ? undefined : bottom;
    const w = anchorsArr.includes("лево") && anchorsArr.includes("право"),
      h = anchors.includes("верх") && anchors.includes("низ");

    topLocal = type === "TTabPagePanel" ? 0 : topLocal;
    const anchorsValues: AnchorsValues = {
      лево: { left: `${leftLocal}px` },
      право: {
        right: `${rightFromServer || right || 0}px`,
      },
      верх: { top: `${topLocal}px` },
      низ: {
        bottom: `${bottomFromServer || bottom || 0}px`,
      },
    };
    styles = anchorsArr.reduce((acc, value) => {
      return { ...acc, ...anchorsValues[value] };
    }, styles);
    if (w) {
      if (type !== "TButton" && returnj.width) {
        // if (!returnj.width.includes("%"))
        //   returnj = Object.assign(returnj, {
        //     minWidth: returnj.width,
        //   });
        styles = { ...styles, width: "" };
      }
    }
    if (h) {
      if (type !== "TButton" && returnj.height) {
        // if (!returnj.height.includes("%"))
        //   returnj = Object.assign(returnj, {
        //     minHeight: returnj.height,
        //   });
        styles = { ...styles, height: "" };
      }
    }
    return styles;
  } else {
    return { ...jsonStyle, left: `${left}px`, top: `${top}px` };
  }
};

export const getColorForms = (json: FormItemInterface, color = "") => {
  const { ClientColor: clientColor } = json;
  if (json.Type === "TSectionPanel") return "rgb(255, 255, 255)";
  if (!color && json.Type !== "TSectionPanel") return "";
  if (json.ParentColor === "1" && clientColor === "") {
    return "inherit";
  }
  let colorArr = color?.split(":"),
    checkColor = color;

  if (clientColor) {
    colorArr = clientColor !== "-1" ? clientColor.split(":") : colorArr;
    checkColor = clientColor !== "-1" ? clientColor : color;
  }

  const colorMap: { [key: string]: string } = {
    Черный: "black",
    Бордовый: "rgb(124,10,2)",
    Зеленый: "green",
    Коричневый: "brown",
    "Темно-синий": "darkblue",
    Пурпурный: "purple",
    "Темно-зеленый": "darkgreen",
    Серый: "gray",
    "Светло-серый": "lightgray",
    Красный: "red",
    "Светло-зеленый": "lightgreen",
    Желтый: "yellow",
    Голубой: "rgb(0, 191, 255)",
    Фиолетовый: "rgb(138, 43, 226)",
    "Светло-голубой": "aqua",
    Белый: "white",
    Подсказка: "rgb(245, 245, 220)",
    прозрачный: "transparent",
    без_заливки: "transparent",
  };

  if (colorArr[1] === undefined) {
    return colorMap[checkColor] || "inherit";
  } else {
    return `rgb(${colorArr[0]},${colorArr[1]},${colorArr[2]})`;
  }
};

export const TextFromServerToBrowser: FC<{
  json: FormItemInterface;
  keyName: string;
  fontSizeFromParent: string;
  onClick: void;
  children: React.ReactNode;
}> = ({ json, onClick, children }) => {
  const {
    AutoSize: autoSize,
    "Font-color": fontColor,
    "Font-family": fontFamily,
    "Font-name": fontName,
    "Font-style": fontStyle,
    "Font-size": fontSize,
    Reference: referenceLink,
    Text: text,
    Alignment: alignment,
    BorderWidth: borderWidth,
    Enabled: enabled,
  } = json;
  const enabledFinal = enabled === "1" || enabled === undefined,
    fontColorFinal = enabledFinal ? fontColor : "grey",
    fontSizeFinal = Number(fontSize || json.fontSizeParent);
  const textStyles = {
    width: autoSize === "1" ? "100%" : `${json.Width}px`,
    fontSize: `${isNaN(fontSizeFinal) ? 10 : fontSizeFinal + 2}px`,
    fontWeight: fontWeight[fontStyle] || "normal",
    fontStyle: fontWeight[fontStyle] || "normal",
    textDecoration: fontWeight[fontStyle] || "normal",
    overflow: "hidden",
    color: !enabledFinal ? "gray" : getColorForms(json, fontColorFinal),
    textAlign: converAlignment[alignment],
    padding: `${(Number(borderWidth) / 100) * 50}px`,
    fontFamily: fontFamily || fontName || "inherit",
    whiteSpace: json.WordWrap !== "1" ? "pre" : "normal",
    wordWrap: json.WordWrap !== "1" ? "break-word" : "normal",
  };
  // parseInt(FontSize, 10)*0,13}px`,
  if (referenceLink === "1" && enabledFinal) {
    return (
      <Link
        keyName={json.Name || json.keyName}
        name={json.Name || json.keyName}
        namePopupMenu={json.NamePopupMenu}
        component="button"
        variant="body2"
        underline="hover"
        onClick={enabledFinal && onClick}
        style={{ ...textStyles, zIndex: "2" }}
      >
        <Typography style={textStyles}>{text}</Typography>
        {children}
      </Link>
    );
  } else {
    return (
      <Typography
        keyName={json.Name || json.keyName}
        name={json.Name || json.keyName}
        onClick={json.EventHandler && json.Type === "TLabel" && enabledFinal ? onClick : null}
        style={textStyles}
      >
        {text}
        {children}
      </Typography>
    );
  }
};
