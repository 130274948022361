export enum Action {
  Initialize = 1,
  Finalize = 2,
  LinktoDlg = 3,
  UnlinkDlg = 4,
  InitData = 5,
  DoneData = 6,
  Activate = 7,
  CloseQuery = 8,
  CloseSession = 9,
  Navigate = 10,
  UpdateSkin = 11,
}

export enum RegEntryType {
  Void = 0,
  Integer = 1,
  Float = 2,
  String = 3,
  Boolean = 4,
  Stream = 5,
  Table = 6,
}

export enum ParamSetOption {
  Disabled = 1,
  InConfig = 2,
  Calculated = 4,
  Detalization = 8,
  Markable = 16,
  Collapsed = 32,
  Reference = 64,
  RedNegative = 128,
  Inspector = 256,
  Grid = 512,
  Sortable = 1024,
  Filterable = 2048,
  Override = 4096,
  Hidden = 8192,
  History = 16384,
  CanGroup = 32768,
  Fixed = 65536,
  NotEdit = 131072,
}

export enum EditStyle {
  PickList = 1,
  Calendar = 2,
  Check = 4,
  UpDown = 8,
  Ellipsis = 16,
  CheckList = 32,
  ReadOnly = 64,
  EditList = 128,
  ColorBox = 256,
  AutoList = 512,
  Password = 1024,
}

export enum Rights {
  Insert = 1,
  Modify = 2,
  Delete = 4,
  Access = 8,
  All = 15,
}

export enum Button {
  Ok = 1,
  Cancel = 2,
  Yes = 4,
  No = 8,
  Abort = 16,
  Retry = 32,
  Ignore = 64,
  All = 128,
  NoToAll = 256,
  YesToAll = 512,
  Execute = 1024,
}

export enum XMLNodeFlag {
  Hidden = 128,
  Record = 256,
  RawXML = 512,
  Base64 = 1024,
}

export enum Param {
  Money = 0,
  Percent = 1,
  Days = 2,
  Hours = 3,
  Date = 4,
  Integer = 5,
  Float = 6,
  Str008 = 7,
  Str016 = 8,
  Str032 = 9,
  Str064 = 10,
  Str128 = 11,
  Str256 = 12,
  Check = 13,
  HandBook = 14,
  PickList = 15,
  CheckList = 16,
  Quantity = 17,
  Color = 18,
  DateTime = 19,
  Stream = 20,
  LoStr = Str008,
  HiStr = Str256,
  First = Money,
  Last = Check,
}

export enum ObjNameFlag {
  FullName = 0,
  ShortName = 1,
  ObjPrompt = 2,
  EditText = 3,
  Code = 4,
  Name = 5,
  Mask = 6,
}

export enum DayType {
  Normal = 0,
  Feast = 8,
  Before = 9,
  DayOff = 10,
  ForceDayOff = 11,
  ForceNormal = 12,
}

export enum DayMask {
  Monday = 65536,
  Tuesday = 131072,
  Wednesday = 262144,
  Thursday = 524288,
  Friday = 1048576,
  Saturday = 2097152,
  Sunday = 4194304,

  FiveDays = 2031616,
  SixDays = 4128768,
  AllWeek = 8323072,
  OffDays = 6291456,

  WorkMode = 8388608,

  Normal = 16777216,
  Feast = 33554432,
  DayOff = 67108864,
  Before = 134217728,

  AllTypes = 251658240,

  Charging = 268435456,
  Free = 536870912,
  Whole = 1073741824,

  AllKinds = 1879048192,
}

export enum HourMask {
  Day = 1,
  Evening = 2,
  Night = 4,
  Total = 7,
}

// export const Command = {
//   Insert: 'Insert',
//   Modify: 'Modify',
//   Delete: 'Delete',
// }

export enum SystemObject {
  Date = 2400,
  OrgTypeCls = 2773,
  TemporaryObject = 2655,
  Attribute = 2620,
}

export enum SystemClass {
  Books = "{55FFC859-62EB-11D4-A4F7-0000E8D9CBDB}",
  ABLUtils = "{42B8EC11-7E3A-11D4-B5EF-0001029B5254}",
}

// export const SystemModule = {
//   Programs: 'programs',
//   ABLUtils: 'ablutils',
//   Departments: 'departments',
//   Tools: 'tools',
//   UserCfg: 'usercfg',
// }

// export const Token = {
//   GetObjectName: 'GetObjectText',
//   SetObjectName: 'SetObjectText',
//   GetObjectValues: 'GetObjectValues',
//   GetDialogParams: 'GetDialogParams',
//   GetGroupTree: 'GetGroupTree',
//   ChangeNodeState: 'ChangeNodeState',
//   ReviewTreeNodes: 'ReviewTreeNodes',
//   GetTreeViewMenu: 'GetTreeViewMenu',
//   HandleTreeMenu: 'HandleTreeMenu',
//   ChangeTreeNodes: 'ChangeTreeNodes',
//   CreateObject: 'CreateObject',
//   DeleteObject: 'DeleteObject',

//   GetParamHistory: 'GetParamHistory',
//   SetParamHistory: 'SetParamHistory',

//   GetTableLayout: 'GetTableLayout',
//   HandleTable: 'HandleTable',
//   GetFileStream: 'GetFileStream',
//   PutFileStream: 'PutFileStream',
//   SelectObject: 'SelectObject',
//   MessageBox: 'MessageBox',
//   GetParamObjectText: 'GetParamObjectText',
//   SetParamObjectText: 'SetParamObjectText',
//   HandleRecords: 'HandleRecords',
//   ReopenData: 'ReopenData',
//   SynchBook: 'SynchBook',

//   GetFields: 'GetFields',
//   GetRecords: 'GetRecords',
//   PostRecord: 'PostRecord',
//   CreateRecord: 'CreateRecord',
//   DeleteRecord: 'DeleteRecord',
//   SortRecords: 'SortRecords',
//   Refresh: 'Refresh',
//   GetFieldText: 'GetFieldText',
//   GetKeys: 'GetKeys',
//   GetRecordCount: 'GetRecordCount',
//   CommitUpdates: 'CommitUpdates',
//   CancelUpdates: 'CancelUpdates',
//   CheckFieldText: 'CheckFieldText',
//   SearchRecord: 'SearchRecord',
//   FilterValues: 'FilterValues',
//   ApplyFilter: 'ApplyFilter',
//   GroupRecords: 'GroupRecords',
//   CollapseGroup: 'CollapseGroup',
//   RefreshRecord: 'RefreshRecord',
//   RecordCollapsed: 'RecordCollapsed',
//   GetRecordGroup: 'GetRecordGroup',

//   GetUserValue: 'GetUserValue',
//   SetUserValue: 'SetUserValue',
//   GetObjectRights: 'GetObjectRights',
//   SetObjectRights: 'SetObjectRights',

//   ExecToolProgram: 'ExecToolProgram',

//   GetObjectParamList: 'GetObjectParamList',
//   SetObjectParamValue: 'SetObjectParamValue',
// }

export enum DlgType {
  Warning = 0,
  Error = 1,
  Information = 2,
  Confirmation = 3,
}

export enum DlgRes {
  Ok = 1,
  Cancel = 2,
  Abort = 3,
  Retry = 4,
  Ignore = 5,
  Yes = 6,
  No = 7,
  Continue = 11,
  All = Continue + 1,
  NoToAll = All + 1,
  YesToAll = NoToAll + 1,
}

export const TITLE_MESSAGE_MODAL_WINDOW = {
  [DlgType.Warning]: "Предупреждение",
  [DlgType.Error]: "Ошибка",
  [DlgType.Information]: "Информация",
  [DlgType.Confirmation]: "Подтверждение",
};

export enum DelphiColors {
  clBlack = 0,
  clMaroon = 128,
  clGreen = 32768,
  clOlive = 32896,
  clNavy = 8388608,
  clPurple = 8388736,
  clTeal = 8421376,
  clGray = 8421504,
  clSilver = 12632256,
  clRed = 255,
  clLime = 65280,
  clYellow = 65535,
  clBlue = 16711680,
  clFuchsia = 16711935,
  clAqua = 16776960,
  clWhite = 16777215,
  clInfoBk = 14811135,
}
