// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.visuallyHiddenInput-cKGp8C{clip:rect(0 0 0 0);clip-path:inset(50%);white-space:nowrap;width:1px;height:1px;position:absolute;bottom:0;left:0;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/components/MainPage/NotWorkArea(Side&Head)/Header/SupportRequest/FileUploadList.module.css"],"names":[],"mappings":"AAAA,4BAAA,kBAAA,CAAA,oBAAA,CAAA,kBAAA,CAAA,SAAA,CAAA,UAAA,CAAA,iBAAA,CAAA,QAAA,CAAA,MAAA,CAAA,eAAA","sourcesContent":[".visuallyHiddenInput {\r\n  clip: rect(0 0 0 0);\r\n  clip-path: inset(50%);\r\n  height: 1px;\r\n  overflow: hidden;\r\n  position: absolute;\r\n  bottom: 0;\r\n  left: 0;\r\n  white-space: nowrap;\r\n  width: 1px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"visuallyHiddenInput": `visuallyHiddenInput-cKGp8C`
};
export default ___CSS_LOADER_EXPORT___;
