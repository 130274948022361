import React, { FC } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import { getBrowserType } from "../../Singin/SingIn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAtomValue } from "jotai";
import { appConfig } from "../../../atoms";

function getOS() {
  let detectOS = "Unknown OS";

  if (navigator.userAgent.indexOf("Win") != -1) detectOS = "windows";

  if (navigator.userAgent.indexOf("Mac") != -1) detectOS = "MacOS";

  if (navigator.userAgent.indexOf("Linux") != -1) detectOS = "linux";
  return detectOS;
}

const Link: FC<{ link: string; text?: string; style?: React.CSSProperties }> = ({ link, text, style }) => {
  return (
    <a
      style={{ cursor: "pointer", ...style }}
      onClick={() => {
        window.open(link);
      }}
    >
      {text || "ссылке"}
    </a>
  );
};

const LinkForOs = () => {
  const config = useAtomValue(appConfig);
  const os = getOS();
  return os === "linux" ? (
    <span>
      <Link link={config.smeta.pluginLinks.workspace.linux} text={"Linux."} />
      <ol style={{ textAlign: "left" }}>
        <li>Загрузить хост-приложение; </li>
        <li>Разархивировать в каталог;</li>
        <li>Запустить из каталога исполняемы файл "setup-webkcrypt";</li>
        <li>В случае успешной установки данный файл автоматически удаляется </li>
        <li>Перезапустить браузер.</li>
      </ol>
    </span>
  ) : (
    <span>
      <Link link={config.smeta.pluginLinks.workspace[os]} text={"Windows. "} />
      Установите следуя инструкциям установщика. После установки, перезагрузите браузер.
    </span>
  );
};

export default function WorkSpaceInstall() {
  const config = useAtomValue(appConfig);
  function forChrome() {
    return (
      <React.Fragment>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            Установка через магазин расширений.
          </AccordionSummary>
          <AccordionDetails>
            Для установки расширения пройдите по{" "}
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(config.smeta.pluginLinks.chrome.store);
              }}
            >
              ссылке
            </a>
            . Обновите страницу.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            Оффлайн установка.
          </AccordionSummary>
          <AccordionDetails>
            Для установки расширения пройдите по{" "}
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(config.smeta.pluginLinks.chrome.local);
              }}
            >
              ссылке
            </a>
            . После того как файл скачался, перейдите chrome://extensions/ и включите режим разработчика, и перетащите
            только что скачанный файл в окно браузера. Подтвердите установку.
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    );
  }

  function forMozilla() {
    return (
      <Accordion expanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          Оффлайн установка.
        </AccordionSummary>
        <AccordionDetails>
          Для установки расширения пройдите по{" "}
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(config.smeta.pluginLinks.firefox.local);
            }}
          >
            ссылке
          </a>
          . После того как файл скачался, перейдите Настройки - Расширения и темы. Нажимте на кнопку шестеренки и
          выберите ранее скачанный файл.
        </AccordionDetails>
      </Accordion>
    );
  }

  const browserContent: { [key: string]: () => JSX.Element } = {
    "Mozilla Firefox": forMozilla,
    default: forChrome,
  };

  function getContent() {
    const browser = getBrowserType();
    return (browserContent[browser] || browserContent["default"])();
  }

  // if (!isInstalledWordSpace()) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      sx={{ height: "max-content", overflowX: "hidden" }}
      // spacing={1}
    >
      <Grid item sx={{ textAlign: "center", m: 1 }}>
        Ваш браузер: <strong>{getBrowserType()}</strong>
      </Grid>
      <Grid item sx={{ m: 1 }}>
        Сначала необходимо установить расширение, а потом хост-приложение.
        <br />
        <Link
          style={{ display: config.smeta.pluginLinks.howToLink ? "" : "none" }}
          link={config.smeta.pluginLinks.howToLink}
          text={"Как это сделать?"}
        />
      </Grid>
      <Grid item sx={{ m: 1 }}>
        <a
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open("http://stimate.krista.ru/webkcrypt/index.html");
          }}
        >
          Страница проверка работоспособности расширения.
        </a>
      </Grid>
      <Grid item sx={{ textAlign: "center" }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            Установить расширение для браузера.
          </AccordionSummary>
          <AccordionDetails>{getContent()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            Установить хост-приложение для подписания
          </AccordionSummary>
          <AccordionDetails>
            Для установки хост приложения пройдите по ссылке для скачивания для <LinkForOs />.
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
  // }
}
