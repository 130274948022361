import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ru";
import dayjs from "dayjs";
import { Grid, IconButton, Popover } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import TodayIcon from "@mui/icons-material/Today";
import React, { useEffect } from "react";
import { getDateOfInt, getStrDateOfDateObj } from "../../MainPage/Tools/Tools";

export default function DateCalendarEditor(props) {
  const calendar = React.useRef();

  const [value, setValue] = React.useState();

  useEffect(() => {
    if ((!props.inputValue || props.inputValue === "..") && (!props.objRef || props.objRef === "0")) {
      const date = new Date();
      setValue(dayjs(props.getDateInFormat(getStrDateOfDateObj(date))));
    }
  }, []);

  useEffect(() => {
    if ((!props.inputValue || props.inputValue === "..") && (!props.objRef || props.objRef === "0")) {
      return;
    }

    setValue(dayjs(props.getDateInFormat(props.inputValue) || props.getDateInFormat(getDateOfInt(props.objRef))));
  }, [props.inputValue, props.objRef]);

  useEffect(() => {
    if (calendar.current) {
      const prev = calendar.current.querySelector(".MuiIconButton-edgeEnd");
      if (prev) {
        prev.title = "Предыдущий месяц";
      }
      const next = calendar.current.querySelector(".MuiIconButton-edgeStart");
      if (next) {
        next.title = "Следующий месяц";
      }
    }
  }, [calendar.current]);

  return (
    <Popover
      id={props.id}
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={() => {
        props.handleClose();
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div ref={calendar} calendar="">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
          <DateCalendar
            showDaysOutsideCurrentMonth
            views={["year", "month", "day"]}
            value={value}
            onChange={(newValue, comand) => {
              props.valueChanged.current = true;
              let value = `${newValue.get("date") < 10 ? `0${newValue.get("date")}` : newValue.get("date")}.${
                newValue.month() + 1 < 10 ? `0${newValue.month() + 1}` : newValue.month() + 1
              }.${newValue.year()}`;

              props.setInputValue(value);
              setValue(newValue);
              if (comand === "finish") {
                props.onEditValue(undefined, value);
                props.handleClose();
              }
            }}
          />
        </LocalizationProvider>

        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <IconButton
            size="small"
            title="В начало года"
            onClick={() => {
              const date = props.getDateInFormat(props.inputValue)
                ? new Date(props.getDateInFormat(props.inputValue))
                : new Date();
              props.setInputValue(`01.01.${date.getFullYear()}`);
              setValue(dayjs(props.getDateInFormat(`01.01.${date.getFullYear()}`)));
              props.valueChanged.current = true;
            }}
          >
            <KeyboardDoubleArrowLeftIcon />
          </IconButton>
          <IconButton
            title="Текущая дата"
            onClick={() => {
              const date = new Date();
              props.setInputValue(
                `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}.${
                  date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                }.${date.getFullYear()}`,
              );
              setValue(
                dayjs(
                  props.getDateInFormat(
                    `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}.${
                      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                    }.${date.getFullYear()}`,
                  ),
                ),
              );
              props.valueChanged.current = true;
            }}
            size="small"
          >
            <TodayIcon />
          </IconButton>
          <IconButton
            title="В конец года"
            onClick={() => {
              const date = props.getDateInFormat(props.inputValue)
                ? new Date(props.getDateInFormat(props.inputValue))
                : new Date();
              props.setInputValue(`31.12.${date.getFullYear()}`);
              setValue(dayjs(props.getDateInFormat(`31.12.${date.getFullYear()}`)));
              props.valueChanged.current = true;
            }}
            size="small"
          >
            <KeyboardDoubleArrowRightIcon />
          </IconButton>
        </Grid>
      </div>
    </Popover>
  );
}
