import { AxiosRequest } from "../../../Url";
import { parseGridState } from "../../Sections/ElementsSections/SectionDocuments/SectionDocuments";
import { PostHandleTable } from "../../Tools/Requests";
import { defaultFieldInfo, getModalSectionID, getRightsData } from "../../Tools/Tools";
import { CreateCollapsibleRecords } from "../GridTools/collapsibleRecordsTools";
import { parseColumns } from "../GridTools/parseColumnTools";
import { createGrid, createRecordSource } from "../rcsgrid";
import { getscheduleColumns } from "./Columns/scheduleColumns";

export async function scheduleGrid(grid: createGrid, source: createRecordSource, props, data) {
  source.onHandleRequest = handleRequest(props);
  //создание стилей для сворачивающихся записей
  source.CreateCollapsibleRecords = CreateCollapsibleRecords;

  await source.open();

  source.rights = getRightsData("-1");
  //   const keyFieldName = source.getKeyFieldName();
  //   if (!keyFieldName) source.setKeyFieldName("CODE");

  const codeField = source.fields[source.getFieldIndex("Code")];
  //создание дефолтных полей()
  let columns = getscheduleColumns(codeField);
  grid.setColumns(columns);

  if (data.setSelectedRecord) {
    source.onRecordIndexChanged = async function (source: createRecordSource) {
      data.setSelectedRecord(source.getFieldTextSync("ID"));
    };
  }

  await grid.setSource(source);

  source.onRecordCollapsed = async function (event) {
    let RecordIndex;
    if (event.currentTarget !== undefined) {
      RecordIndex = event.currentTarget.getAttribute("recordindex");
    } else {
      RecordIndex = event;
    }
    let params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "RecordCollapsed")
      .set("SectionID", props.SectionID)
      .set("ObjType", props.ObjType)
      .set("RecordIndex", RecordIndex);
    await AxiosRequest(true, params);
    await grid.refreshSource("!");
    if (data.setCountRecords) data.setCountRecords(source.recordCount);
    return;
  };

  await grid.refreshSource();

  await grid.updateGridSize();

  if (data.setSelectedRecord) {
    data.setSelectedRecord(source.getFieldTextSync("ID"));
  }
}

function handleRequest(props) {
  return function onHandleRequest(request, loadCursor, wsm, subParams) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "HandleTable")
      .set("ObjType", props.ObjType)
      .set("SectionID", props.SectionID)
      .set("GroupID", props.GroupID || "0");
    wsm && params.set("WSM", "1");
    if (subParams) {
      const sParams = Object.entries(subParams);
      sParams.forEach(([key, value]) => {
        params.set(key, value);
      });
    }
    return AxiosRequest(loadCursor !== undefined ? loadCursor : true, params, request);
  };
}
