import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import { Grid, ListItemIcon, Typography } from "@mui/material";
import { ImgURL, ImgURLSRC } from "../../Url";
import ContentCut from "@mui/icons-material/ContentCut";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ContentPaste from "@mui/icons-material/ContentPaste";
import SettingsIcon from "@mui/icons-material/Settings";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import SortIcon from "@mui/icons-material/Sort";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CheckIcon from "@mui/icons-material/Check";
import { NestedMenuItem } from "../Tools/NestedMenuOrigin/NestedMenuItem";
import { getIcons } from "../Tools/Tools";
import { checkAvailabilityFromCanModify } from "../rcsgrid/createGridTools";
import { CreateBackgroundForModal } from "../../TokenProcessing/TokenProcessing";
import ModalWindow from "../Module/ModalWindow";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  addressMenu,
  clsbooksGrid,
  defaultMenu,
  gridMenu,
  paramsMenu,
  personPhotoMenu,
  popupMenu,
  reportMenu,
  treeMenu,
} from "./ContextMenuData/ContextMenuTools";

// import ClickAwayListener from "@mui/material/ClickAwayListener";
// import Grow from "@mui/material/Grow";
// import Paper from "@mui/material/Paper";
// import Popper from "@mui/material/Popper";
// import MenuList from "@mui/material/MenuList";

export function GetDisable(ID, json, bool) {
  let backvalue;
  json.filter((Element) => {
    if (Element["ID"] == ID) return (backvalue = Element.Disabled);
  });
  return backvalue === "1" ? true : bool;
}

const gridsContext = [
  "grid",
  "clsbook",
  "accountsAnalitic",
  "accountsPlan",
  "personsChildren",
  "personsTable",
  "paytypes",
  "distractions",
  "schedule",
  "organizations",
  "NFA",
];

const GridMenuJson = {
  //grid
  Table: [
    // {
    //   Caption: "Копировать запись",
    //   Image: <ContentCopy fontSize="small" />,
    //   Disabled: true,
    // },
    // { доработка на след год
    //   Caption: "Вырезать запись",
    //   Image: <ContentCut fontSize="small" />,
    //   Disabled: true,
    // },
    // {
    //   Caption: "Вставить из буфера",
    //   Image: <ContentPaste fontSize="small" />,
    //   Disabled: true,
    // },
    // { Caption: "Divider" },
    {
      Caption: "Новая запись",
      value: "Insert",
      Image: <PostAddIcon fontSize="small" />,
    },
    {
      Caption: "Удалить запись",
      value: "Delete",
      Image: <DeleteIcon fontSize="small" />,
    },
    { Caption: "Divider" },
    {
      Caption: "Настройки...",
      Image: <SettingsIcon fontSize="small" />,
      value: "Settings",
    },
    { Caption: "Divider", value: "SettingsDivider" },
    { Caption: "Режим просмотра", value: "OnlyViewMode" },
    // { Caption: "Сохранять отметку", Disabled: true },
  ],
  Header: [
    { Caption: "Divider" },
    {
      Caption: "По возрастанию",
      Image: <SortIcon fontSize="small" />,
      value: "Sort",
    },
    {
      Caption: "По убыванию",
      Image: <SortIcon fontSize="small" style={{ transform: "scale(1,-1)" }} />,
      value: "Sort-",
    },
    {
      Caption: "Снять сортировку",
      value: "ClearSort",
    },
    { Caption: "Divider" },
    {
      Caption: "Группировать по этому полю",
      value: "GroupBy",
      Disabled: false,
    },
    {
      Caption: "Снять группировку по этому полю",
      value: "GroupByTakeOff",
      Disabled: false,
    },
    { Caption: "Область группировки", value: "GroupShow" },
    { Caption: "Расширенная группировка", Disabled: true },
    { Caption: "Поиск по столбцу", value: "searchByColumn" },
  ],
  Grouping: [
    { Caption: "Область группировки", value: "GroupShow" },
    { Caption: "Расширенная группировка", Disabled: true },
  ],
};

const ClassListForGridMain = ["grid-td", "grid-cell-inner", "grid-data"];

const ClassListForGridHead = [
  "column-header-text",
  "grid-column-title",
  "grid-column",
  "column-span",
  "grid-column-img",
];

const ClassListForGridGroup = ["grid-groups-text", "grid-groups"];

export default function ContextMenu(props) {
  const [contextMenu, setContextMenu] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [contextMenuJSX, setContextMenuJSX] = React.useState(null);
  const [AssMass, setAssMass] = React.useState(new Map());
  const [anchorElAss, setAnchorElAss] = React.useState(new Map());
  // const [parentName, setParentName] = React.useState(null);
  const parentNameRef = React.useRef(null);
  const gridRef = React.useRef(null);
  const ContextMenuBlockRef = React.useRef(null);

  React.useEffect(() => {
    props.getContextMenuFunction && props.getContextMenuFunction(handleContextMenu);
  }, []);

  const handleContextMenu = async (event) => {
    //тут мы отправляем на отрисовку список, смотря куда тыкнули
    //отмена стандартного ивента
    if (!event) return;
    const target = event.target;
    if (target) getParentNameRef(target);
    let items = [],
      clickMenu = [],
      buttonsArr = [];
    if (event.stopPropagation) event.stopPropagation();
    let menuForGrid = props.Menu ? props.Menu : GridMenuJson;
    //

    function getParentNameRef(target) {
      try {
        if (target.attributes.keyname !== undefined) {
          parentNameRef.current = target.attributes.keyname.value;
        } else if (target.firstChild.attributes !== undefined) {
          parentNameRef.current = target.firstChild.attributes.keyname.value;
        } else if (target.parentNode.attributes !== undefined) {
          parentNameRef.current = target.parentNode.attributes.keyname.value;
        } else {
          parentNameRef.current = target.textContent;
        }
      } catch {
        console.error("ContextMenu: Error in getParentNameRef");
        parentNameRef.current = "";
      }
    }

    function loadPopupMenu(out, json) {
      for (const value of Object.values(json)) {
        if (value && value.Type === "TMenuItem") {
          let outItem = [];
          loadPopupMenu(outItem, value);
          outItem.length > 0
            ? out.push({
                Name: value.Name,
                Caption: value.Caption,
                hidden: value.Visible == 0 ? true : false,
                items: outItem,
              })
            : out.push({
                Name: value.Name,
                Caption: value.Caption,
                hidden: value.Visible == 0 ? true : false,
              });
        }
      }
    }

    const data = {
      event,
      target,
      props,
      clickMenu,
      items,
      buttonsArr,
      menuForGrid,
      CreateMenuItem,
      AssignMenuItems,
      setContextMenu,
      gridRef,
      GetSortButtons,
      ClassListForGridMain,
      ClassListForGridHead,
      ClassListForGridGroup,
      loadPopupMenu,
    };

    const getContextMenu = {
      grid: gridMenu,
      clsbook: clsbooksGrid,
      tree: treeMenu,
      params: paramsMenu,
      addressParams: addressMenu,
      reports: reportMenu,
      popupmenu: popupMenu,
      accountsPlan: gridMenu,
      accountsAnalitic: gridMenu,
      personsChildren: gridMenu,
      personsTable: gridMenu,
      paytypes: gridMenu,
      distractions: gridMenu,
      personsPhoto: personPhotoMenu,
      schedule: gridMenu,
      organizations: gridMenu,
      NFA: gridMenu,
    };

    const createContextMenu = getContextMenu[props.for];
    (createContextMenu && (await createContextMenu(data))) || (await defaultMenu(props.data, items, CreateMenuItem));

    if (items.length > 0) setContextMenuJSX(items);
    if (props.for === "grid") {
    }
    // const { clientX, clientY } = event;
    if (!items.length) {
      return;
    }
    event.preventDefault();

    setOpen(true);
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
    // setContextMenu({
    //   getBoundingClientRect: () => ({
    //     width: 0,
    //     height: 0,
    //     top: clientY,
    //     right: clientX,
    //     bottom: clientY,
    //     left: clientX,
    //   }),
    // });
  };

  function AssignMenuItems(sectionItems, reqestItems, baseItems) {
    const sectItems = props.data ? (props.data[sectionItems] ? props.data[sectionItems] : []) : [];
    return [...sectItems, ...reqestItems, ...baseItems];
  }

  function GridContextMenuHandler(data) {
    //Обработчик команд для грида
    if (gridsContext.includes(props.for)) {
      const comand = data.value;
      const grid = gridRef.current;
      switch (comand) {
        // HEADER
        case "GroupShow": //открыть/закрыть область группировки
          grid.grid.setOptions(2);
          break;
        case "Sort-": //По убыванию
          data.textcomponent.field.sortDirect = "down";
          grid.grid.sortByRecord(data.textcomponent);
          break;
        case "Sort": //По возрастанию
          data.textcomponent.field.sortDirect = "up";
          grid.grid.sortByRecord(data.textcomponent);
          break;
        case "ClearSort":
          grid.grid.sortByRecord(data.textcomponent, true, true);
          break;
        // GRID
        case "OnlyViewMode": {
          if (grid.grid.doccfgid) {
            handleClick(data);
          } else {
            grid.grid.ChangeViewMode();
          }
          break;
        }
        case "Insert":
          grid.grid.insertRecord(true);
          break;
        case "SaveRedactor":
          grid.grid.postExicstedEditRec(true);
          break;
        case "CancelRedactor":
          grid.grid.cancelRecord();
          break;
        case "AddGroup":
          grid.grid.insertRecord(true);
          break;
        case "AddGroupInner":
          grid.grid.insertRecord(true, undefined, { Child: "1" });
          break;
        case "Delete":
          grid.grid.deleteRecord().then(() => {
            grid.grid.refreshSource("!");
          });
          break;
        case "HideColumn":
          //
          grid.grid.hideColumn(data.textcomponent.field);
          break;
        case "searchByColumn":
          setTimeout(() => {
            grid.grid.searchByColumn(undefined, data.textcomponent.field.el);
          }, 1);
          break;
        case "GroupBy": {
          grid.grid.groupByField(data.textcomponent.field);
          break;
        }
        case "GroupByTakeOff": {
          grid.grid.groupByField(data.textcomponent.field, true);
          break;
        }
        default:
          handleClick(data); //переход в функцию от родителя
          break;
      }
    } else {
      if (props.for === "popupmenu") {
        handlePopupClick(data);
      } else {
        handleClick(data);
      }
    }
    setContextMenu(null);
    setOpen(false);
  }

  async function CreateMenuItem(json) {
    //В цикле создаем кнопки, которые пришли от сервера + стандартные + по пропсам
    let items = [],
      availability,
      state,
      canGroupOrSort = props.canGroupOrSort ? props.canGroupOrSort : true;
    for (const [key, value] of Object.entries(json.MenuData)) {
      if (value) {
        if (value.ID) value.IDfix = value.ID;
        if (value.Caption === "Divider" || value.Caption === "-") {
          //разделитель
          if (shouldShow(value, json.addictionalInfo)) {
            items.push(<Divider key={key} />);
          }
        } else {
          if (value.items) {
            let currentValue = value;
            if (currentValue.ImageIndex) {
              currentValue.Image = getIcons(currentValue.ImageIndex);
            }
            if (currentValue.ProgID) {
              currentValue.ID = currentValue.ProgID;
            }
            CreateMap(value);
            // const openSet = AssMass.get(value.Caption);
            items.push(
              <NestedMenuItem
                key={key}
                style={{ paddingLeft: "26px", height: "32px", marginLeft: 2 }}
                leftIcon={
                  currentValue.Image === undefined ? (
                    <div style={{ paddingLeft: "16px" }}></div>
                  ) : (
                    ImgURL(currentValue.Image, {
                      height: "16px",
                      width: "16px",
                      marginLeft: -13,
                      marginTop: 3,
                      marginRight: 13,
                    })
                  )
                }
                label={currentValue.Caption}
                parentMenuOpen={true}
              >
                <Grid
                  item
                  style={{
                    backgroundColor: "#e6e6e6",
                    position: "absolute",
                    height: "100%",
                    width: props.for === "popupmenu" ? "0px" : "46px",
                    top: "0px",
                  }}
                />
                {await CreateMenuItem({
                  MenuData: currentValue.items,
                  addictionalInfo: json.addictionalInfo,
                })}
              </NestedMenuItem>,
            );
          } else {
            let currentValue = value;
            currentValue = updateValue(currentValue);
            if (currentValue.ImageIndex) {
              currentValue.Image = getIcons(currentValue.ImageIndex);
            }
            if (currentValue.ProgID) {
              currentValue.ID = currentValue.ProgID;
            }
            if (
              (currentValue.value && currentValue.value === "Insert") ||
              currentValue.value === "Delete" ||
              currentValue.value === "RecordCopy"
            ) {
              currentValue = await checkDisableByCanModify(currentValue);
              currentValue = await getSaveOrCancelVal(currentValue);
            }
            canGroupOrSort = shouldShow(currentValue, json.addictionalInfo);
            availability = currentValue.Disabled === undefined ? false : currentValue.Disabled === true ? true : false; //смотрим доступна ли кнопка к клику
            // availability = false;
            state = await json.State;
            availability = (await state) ? await GetDisable(currentValue.ID, state, availability) : availability;
            if (canGroupOrSort)
              items.push(
                <MenuItem
                  onClick={() => {
                    GridContextMenuHandler({
                      ...json.addictionalInfo,
                      ...currentValue,
                    });
                  }}
                  style={{ height: "32px", display: currentValue.hidden ? "none" : "" }}
                  disabled={availability}
                  value={currentValue.value}
                  id={currentValue.ID === undefined ? "" : currentValue.ID}
                  status={currentValue.Status === undefined ? "" : currentValue.Status}
                  key={key + "contextMenu"}
                  {...json.addictionalInfo}
                >
                  <ListItemIcon sx={{ display: props.for === "popupmenu" ? "none" : "" }}>
                    {(currentValue.Image && currentValue.ID) || currentValue.Status
                      ? ImgURL(currentValue.Image, { width: "20px" })
                      : null}
                    {currentValue.Image && !currentValue.ID && !currentValue.Status ? currentValue.Image : null}
                  </ListItemIcon>
                  <ListItemText
                    inset
                    style={{ padding: 0 }}
                    value={currentValue.value}
                    id={currentValue.ID === undefined ? "" : currentValue.ID}
                  >
                    {currentValue.Caption}
                  </ListItemText>
                  <Typography variant="body2" color="text.secondary">
                    {currentValue.ShortCutString}
                  </Typography>
                </MenuItem>,
              );
          }
        }
      }
    }
    return items;
  }

  function getSaveOrCancelVal(currentValue) {
    let returnButton = currentValue;
    if (!gridRef.current) return returnButton;

    const grid = gridRef.current;
    const isEditMode = grid.grid.getActiveSource().isEditModeState();
    const isMultiSelect = grid.grid.getMultiSelect().length > 0;
    let isEditModeText = isEditMode === 1 && "редактирование";
    isEditModeText = isEditMode === 3 ? "вставку" : isEditModeText;
    switch (currentValue.value) {
      case "Insert": {
        if (isEditMode === 0 || isEditMode === 3) {
          returnButton = {
            Caption: `Сохранить ${isEditModeText}`,
            value: "SaveRedactor",
            Image: <SaveAltIcon fontSize="small" />,
            Disabled: currentValue.Disable,
          };
        }

        break;
      }
      case "Delete": {
        if (isEditMode === 0 || isEditMode === 3) {
          returnButton = {
            Caption: `Отменить ${isEditModeText}`,
            value: "CancelRedactor",
            Image: <CancelIcon fontSize="small" />,
            Disabled: currentValue.Disable,
          };
        }

        break;
      }
      case "RecordCopy": {
        if (isMultiSelect) {
          returnButton = {
            Caption: `Создать копии документов`,
            value: "RecordCopy",
            Disabled: currentValue.Disable,
          };
        }
        break;
      }
    }
    return returnButton;
  }

  function checkDisableByCanModify(currentValue) {
    let returnButton = currentValue;
    if (!gridRef.current) return returnButton;

    const grid = gridRef.current;
    let canModifyDelete = checkAvailabilityFromCanModify(2, grid.grid),
      canModifyInsert = checkAvailabilityFromCanModify(0, grid.grid);
    switch (currentValue.value) {
      case "Insert": {
        returnButton = {
          ...currentValue,
          Disabled: currentValue.Disabled ? currentValue.Disabled : canModifyInsert,
        };
        break;
      }
      case "Delete": {
        returnButton = {
          ...currentValue,
          Disabled: currentValue.Disabled ? currentValue.Disabled : canModifyDelete,
        };
        break;
      }
      case "RecordCopy": {
        returnButton = {
          ...currentValue,
          Disabled: currentValue.Disabled ? currentValue.Disabled : canModifyInsert,
        };
        break;
      }
    }
    return returnButton;
  }

  function updateValue(currentValue, addictionalInfo) {
    if (gridRef.current && props.canGroupOrSort === undefined && currentValue.value) {
      const comand = currentValue.value;
      switch (comand) {
        case "OnlyViewMode": {
          return {
            ...currentValue,
            Image: gridRef.current.grid.OnlyViewMode ? <CheckIcon fontSize={"small"} /> : null,
          };
        }
        default:
          if (props.changeDataOfItem) {
            return {
              ...currentValue,
              ...props.changeDataOfItem(currentValue, addictionalInfo),
            };
          } else {
            return currentValue;
          }
      }
    } else {
      return currentValue;
    }
  }

  function shouldShow(currentValue, addictionalInfo) {
    if (gridRef.current && props.canGroupOrSort === undefined && currentValue.value) {
      let canShow = true;
      if (addictionalInfo && addictionalInfo.textcomponent) {
        const field = addictionalInfo.textcomponent.field;
        if (currentValue.value.includes("GroupBy")) {
          let isFieldGrouped = false;
          canShow = gridRef.current.grid.canGroup(field);
          for (const val of gridRef.current.grid.getGroups()) {
            if (val.fieldName === field.fieldName) isFieldGrouped = true;
          }
          if (canShow) {
            switch (currentValue.value) {
              case "GroupBy":
                if (isFieldGrouped) canShow = false;
                break;
              case "GroupByTakeOff":
                if (!isFieldGrouped) canShow = false;
                else canShow = true;
                break;
            }
          }
        }

        if (currentValue.value.includes("Sort") && gridRef.current) canShow = gridRef.current.grid.canSort(field);
        if (currentValue.value === "HideColumn" && gridRef.current.grid.detailsInDetail) canShow = false;
      } else {
        switch (currentValue.value) {
          case "Details": {
            if (!props.Details) canShow = false;
            break;
          }
          case "PrintSelectedRecords": {
            if (gridRef.current.grid.getMultiSelect().length === 0) {
              canShow = false;
            }
            break;
          }
          case "Settings": {
            if (!gridRef.current.grid.doccfgid) {
              canShow = false;
            }
            break;
          }
          case "SettingsDivider": {
            if (!gridRef.current.grid.doccfgid) {
              canShow = false;
            }
            break;
          }
        }
      }
      return canShow;
    } else {
      return true;
    }
  }

  function CreateMap(List) {
    for (const value of Object.values(List)) {
      setAnchorElAss(anchorElAss.set(value.Caption, null));
      setAssMass(AssMass.set(value.Caption, false));
    }
  }

  function GetSortButtons(sortDirect, arr) {
    switch (sortDirect) {
      case "down":
        arr.splice(2, 1);
        break;

      case "up":
        arr.splice(3, 1);
        break;

      default:
        arr.splice(4, 1);
        break;
    }
  }

  const handlePopupClick = (event) => {
    //обработчик клика на функцию от родителя
    if (props.onMenuItemClick) {
      props.onMenuItemClick(event, event.Name, null, null, parentNameRef.current);
    }
    setContextMenu(null);
  };

  const handleClick = (event) => {
    //обработчик клика на функцию от родителя
    if (props.onMenuItemClick) {
      props.onMenuItemClick(event, gridRef.current);
    }
    setContextMenu(null);
  };

  return (
    <div
      onContextMenu={handleContextMenu}
      style={{ cursor: "context-menu", height: "100%", width: "100%", ...props.style }}
    >
      {props.children}
      <Menu
        open={contextMenu !== null}
        onClose={() => {
          setContextMenu(null);
        }}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid
            item
            style={{
              backgroundColor: "#e6e6e6",
              position: "absolute",
              height: "100%",
              width: props.for === "popupmenu" ? "0px" : "46px",
            }}
          />
          <Grid
            item
            onLoad={() => {
              if (
                //КОСТЫЛЬ ДЛЯ ФАЕРФОКСА todo
                ContextMenuBlockRef.current &&
                ContextMenuBlockRef.current.parentElement.parentElement.parentElement.clientHeight <
                  ContextMenuBlockRef.current.scrollHeight
              ) {
                ContextMenuBlockRef.current.style.paddingRight = "15px";
              }
            }}
            ref={ContextMenuBlockRef}
          >
            {contextMenuJSX}
          </Grid>
        </Grid>
      </Menu>
      {/* <Popper
        open={open}
        anchorEl={contextMenu}
        sx={{ zIndex: 10 }}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={() => {
                  setContextMenu(null);
                  setOpen(false);
                }}
              >
                <MenuList
                  autoFocusItem={contextMenu !== null}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  // onKeyDown={handleListKeyDown}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid
                      item
                      style={{
                        backgroundColor: "#e6e6e6",
                        position: "absolute",
                        height: "100%",
                        width: "46px",
                      }}
                    />
                    <Grid item>{contextMenuJSX}</Grid>
                  </Grid>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper> */}
    </div>
  );
}
