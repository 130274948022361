import React, { FC, ReactNode, useState, useRef, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { PinnedIcon, UnPinnedIcon } from "./PinIcons";
import CheckIcon from "@mui/icons-material/Check";
import ContextMenu from "../../NotWorkArea(Side&Head)/ContextMenu";

interface SmetaTabsProps {
  children: ReactNode[];
  position?: "top" | "bottom" | "hidden"; // Вкладки сниху, сверху или скрыты
  onTabPin?: (pinned: boolean, index: number, data?: unknown) => Promise<void>; //Вкладки можно закреплять
  onTabClose?: (
    indices: { oldIndex: number; newIndex: number },
    data: { oldData: unknown; newData: unknown },
  ) => Promise<void>; //Вкладки можно закрывать
  onTabReClick?: (index: number) => Promise<void>; //Повторный клик на таб
  id?: string;
  onTabIndexChange?: (index: number, tabElement: HTMLElement, data?: unknown, actualIndex?: number) => Promise<void>; //Изменение индекса вкладки
  tabIndex?: number; //Контроллирование выбранной вкладки
  style?: React.CSSProperties;
}

interface ChildProps {
  label: string;
  pinned?: boolean;
  closable?: boolean;
  hidden?: boolean;
  data?: unknown;
  title?: string;
}

const SmetaTabs: FC<SmetaTabsProps> = ({
  children,
  position = "top",
  onTabPin,
  onTabClose,
  onTabIndexChange,
  onTabReClick,
  tabIndex,
  id,
  style,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [visitedTabs, setVisitedTabs] = useState<Set<number>>(new Set([0])); //сохранение посещенных вкладок
  const tabsRef = useRef<HTMLDivElement>();
  const tabRefs = useRef<(HTMLDivElement | null)[]>([]); //для передачи ссылок на вкладку при изменении индекса(в основном нужно для грида, т.к. он на чистом js)

  const updateActiveTab = (index: number) => {
    //обновление активной вкладки и запуск рендера контента вкладки
    setActiveTab(index);
    setVisitedTabs((prev) => new Set(prev).add(index));
  };

  useEffect(() => {
    //установка активной вкладки при контроллированнном компоненте
    if (tabIndex !== undefined) updateActiveTab(tabIndex || 0);
    if (tabsRef.current) tabsRef.current.getSelectedContent = () => tabRefs.current[tabIndex];
  }, [tabIndex]);

  const handleTabChange = async (index: number, actualIndex: number, propsData?: unknown) => {
    //функция для смены вкладки
    updateActiveTab(index); //todo обновление по актуальному инедксу
    if (tabRefs.current[index]) {
      setTimeout(async () => await onTabIndexChange?.(index, tabRefs.current[index]!, propsData, actualIndex), 0);
    }
  };

  const getNewTabIndexAfterClose = (index: number) => {
    if (activeTab === index) {
      return Math.max(0, activeTab - 1);
    }
    return activeTab;
  };

  const handleTabClose = async (index: number, data?: unknown) => {
    // Обновление активной вкладки на последний доступный индекс
    const newIndex = getNewTabIndexAfterClose(index);
    const newTabData = (React.Children.toArray(children)[newIndex] as React.ReactElement<ChildProps>).props.data;
    updateActiveTab(newIndex);
    if (onTabClose) {
      await onTabClose({ oldIndex: index, newIndex }, { oldData: data, newData: newTabData });
    }
  };

  const renderTabs = () => {
    //рендер вкладок
    let actualIndex = -1;
    const contextMenuData = React.Children.toArray(children).map((child, index) => ({
      //формирование данных для контекстного меню
      Caption: (child as React.ReactElement<ChildProps>).props.label,
      value: "select",
      index,
      Image: activeTab === index ? <CheckIcon /> : undefined,
    }));
    return (
      <ContextMenu
        style={{ height: `${position === "hidden" ? 0 : 30}px` }}
        onMenuItemClick={(data: { index: number }) => handleTabChange(data.index, actualIndex)}
        data={contextMenuData as { Caption: string; value: string; index: number }[]}
      >
        <Tabs
          value={activeTab}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            const childProps = (React.Children.toArray(children)[newValue] as React.ReactElement<ChildProps>).props;
            handleTabChange(newValue, actualIndex, childProps.data);
          }}
          aria-label="Smeta Tabs"
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { backgroundColor: "#dcd8cc" } }}
          sx={{
            minHeight: "30px",
            height: "30px",
            display: position === "hidden" ? "none" : "block",
            "& .MuiTabs-scrollButtons": {
              backgroundColor: "#dcd8cc",
            },
            "& .MuiTab-root": {
              minHeight: "30px",
              height: "30px",
              padding: "4px 4px",
              alignItems: "center",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
              "&.Mui-selected": {
                backgroundColor: "#e0e0e0",
                fontWeight: "bold",
              },
            },
          }}
        >
          {React.Children.toArray(children).map((child, index) => {
            const childProps = (child as React.ReactElement<ChildProps>).props; //получение пропсов вкладки
            const canPin = childProps.pinned !== undefined;
            if (childProps.hidden) {
              return null;
            }
            actualIndex++;
            return (
              <Tab
                title={childProps.title || childProps.label || ""}
                key={index}
                onClick={() => {
                  if (activeTab === index && onTabReClick) onTabReClick(index);
                }}
                label={
                  <>
                    {canPin && (
                      <IconButton onClick={() => onTabPin?.(!!childProps.pinned, index, childProps.data)} size="small">
                        {childProps.pinned ? <PinnedIcon /> : <UnPinnedIcon />}
                      </IconButton>
                    )}
                    {childProps.label}
                    {childProps.closable && (
                      <IconButton onClick={() => handleTabClose(index, childProps.data)} size="small">
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  </>
                }
                iconPosition="end"
              />
            );
          })}
        </Tabs>
      </ContextMenu>
    );
  };

  const renderContent = () => {
    //рендерим контент с проверкой: посещали ли вкладку ранее
    return React.Children.toArray(children).map((child, index) => {
      const childElement = child as React.ReactElement<ChildProps>;
      if (childElement.props.hidden) {
        return null;
      }
      return (
        <div
          key={index}
          ref={(el) => (tabRefs.current[index] = el)}
          style={{
            display: activeTab === index ? "block" : "none",
            boxSizing: "border-box",
            height: "100%",
            padding: 0,
            position: "relative",
          }}
        >
          {visitedTabs.has(index) && (child as React.ReactElement).props.children}
        </div>
      );
    });
  };

  return (
    <Box
      ref={tabsRef}
      selected-index={tabIndex}
      id={id || ""}
      style={{ display: "flex", flexDirection: position === "top" ? "column" : "column-reverse", ...style }}
    >
      {renderTabs()}
      <div style={{ height: "100%", flex: "1 1 auto", minHeight: 0 }}>{renderContent()}</div>
    </Box>
  );
};

export default SmetaTabs;
