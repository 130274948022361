import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, List, ListItem, ListItemText } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import styles from "./FileUploadList.module.css";
import React, { ChangeEvent } from "react";
import html2canvas from "html2canvas";
import { getCurrentDateTimeForScreenshot } from "./SupportRequestConst";

interface FileUploadListProps {
  files: File[];
  setFiles: (files: File[]) => void;
  height: number | string;
}

interface DeleteFileButtonProps {
  deleteFile: () => void;
}

export default function FileUploadList({ files, setFiles, height }: FileUploadListProps) {
  const shortenFileName = (fileName: string, maxLength: number = 23): string => {
    if (fileName.length <= maxLength) return fileName;
    const extension = fileName.split(".").pop() || "";
    const nameWithoutExtension = fileName.slice(0, -(extension.length + 1));
    const shortenedName = nameWithoutExtension.slice(0, maxLength - extension.length - 4);
    return `${shortenedName}...${extension}`;
  };

  const takeScreenshot = () => {
    const element = document.getElementById("root");
    if (element) {
      html2canvas(element).then((canvas) => {
        canvas.toBlob((blob) => {
          if (blob) {
            const fileName = "Screen_" + getCurrentDateTimeForScreenshot() + ".jpg";
            const file = new File([blob], fileName, { type: "image/jpeg" });
            setFiles([...files, file]);
          }
        }, "image/jpeg");
      });
    }
  };

  return (
    <Grid2 container direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
      <Grid2 item xs={12}>
        <Grid2 item container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Grid2 item>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              size="small"
              style={{ textTransform: "none", marginRight: "10px" }}
            >
              Выбрать
              <input
                type="file"
                className={styles.visuallyHiddenInput}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (event.target.files) {
                    setFiles([...files, ...Array.from(event.target.files)]);
                  }
                }}
                multiple
              />
            </Button>
          </Grid2>
          <Grid2 item>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              size="small"
              style={{ textTransform: "none", marginRight: "10px" }}
              onClick={takeScreenshot}
            >
              Экран
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 item xs={12} style={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            height: height,
            borderRadius: 1,
            bgcolor: "rgba(220, 216, 204, 1)",
            overflowY: "auto",
          }}
        >
          <List>
            {files.map((file, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <DeleteFileButton deleteFile={() => setFiles(files.filter((f) => file.name !== f.name))} />
                }
              >
                <ListItemText primary={shortenFileName(file.name)} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid2>
    </Grid2>
  );
}

function DeleteFileButton({ deleteFile }: DeleteFileButtonProps) {
  return (
    <IconButton edge="end" aria-label="delete" onClick={deleteFile}>
      <DeleteIcon />
    </IconButton>
  );
}
