import { Box, Button, FormControl, FormControlLabel, InputLabel, Radio, RadioGroup } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState, ChangeEvent, FormEvent } from "react";
import React from "react";

import IMemo from "../../../Sections/ElementsSections/Forms/Components/IMemo";
import FileUploadList from "./FileUploadList";

interface TicketsClosingDetailPanelProps {
  status: string;
  sendTaskFeedback: (feedback: {
    comment: string;
    close: number;
    grade: number;
    files: File[];
  }) => void;
}

export default function TicketsClosingDetailPanel({ status, sendTaskFeedback }: TicketsClosingDetailPanelProps) {
  const [comment, setComment] = useState<string>("");
  const [close, setIsClose] = useState<number>(1);
  const [grade, setGrade] = useState<number>(0);
  const [files, setFiles] = useState<File[]>([]);

  const isInputsDisabled = (): boolean => {
    return !(status === "Решено" || status === "Зарегистрировано");
  };

  const handleCloseChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsClose(Number(event.target.value));
  };

  const handleGradeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setGrade(Number(event.target.value));
  };

  const onCommentChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setComment(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    sendTaskFeedback({
      comment: comment,
      close: close,
      grade: grade,
      files: files,
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} style={{ width: "100%", height: "100%", paddingRight: "10px" }}>
      <Grid2 container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        <Grid2 item>
          <Box
            style={{
              paddingLeft: "1px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Статус заявки:</label>
            {status === "Решено" || status === "Зарегистрировано" ? (
              <FormControl
                style={{
                  marginLeft: "10px",
                }}
              >
                <RadioGroup row sx={{ height: "38px", overflow: "auto" }} value={close} onChange={handleCloseChange}>
                  <FormControlLabel value={1} control={<Radio size="small" />} label={"Закрыть"} />
                  {status !== "Зарегистрировано" && (
                    <FormControlLabel value={2} control={<Radio size="small" />} label={"Вернуть в работу"} />
                  )}
                </RadioGroup>
              </FormControl>
            ) : (
              <InputLabel
                style={{
                  marginLeft: "10px",
                }}
              >
                {status}
              </InputLabel>
            )}
          </Box>
        </Grid2>
        <Grid2 container item direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1}>
          <Grid2 item xs={8}>
            <InputLabel>Комментарий</InputLabel>
            <IMemo
              Text={comment}
              onChange={onCommentChange}
              readOnly={isInputsDisabled()}
              style={{
                height: "100%",
                width: "100%",
                resize: "none",
                overflow: "auto",
                outline: "none",
              }}
            />
          </Grid2>
          <Grid2 item xs={4}>
            <InputLabel htmlFor="raised-button-file">Файлы</InputLabel>
            <FileUploadList files={files} setFiles={setFiles} height={100} />
          </Grid2>
        </Grid2>
        <Grid2 container item direction="row" justifyContent="flex-start" alignItems="space-between" spacing={0}>
          <Grid2 item xs={10}>
            {close === 1 && (status === "Решено" || status === "Зарегистрировано") && (
              <Box
                style={{
                  paddingLeft: "1px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label>Оценка:</label>
                <FormControl
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <RadioGroup row sx={{ height: "38px", overflow: "auto" }} value={grade} onChange={handleGradeChange}>
                    <FormControlLabel value={5} control={<Radio size="small" />} label={"Положительная"} />
                    <FormControlLabel value={1} control={<Radio size="small" />} label={"Отрицательная"} />
                    <FormControlLabel value={0} control={<Radio size="small" />} label={"Без оценки"} />
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
          </Grid2>
          <Grid2 item xs={2} display="flex" justifyContent="flex-end" alignItems="center" size="grow">
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none" }}
              value="1"
              className="button_Modal_Select"
              type="submit"
              disabled={isInputsDisabled()}
            >
              Отправить
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
}
