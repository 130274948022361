import React, { FC } from "react";
import PersonIcon from "@mui/icons-material/Person";
import cn from "classnames";
import { useSelector } from "react-redux";
import { openModal } from "../../Tools/modalManager";
import Options from "./Options";
import IconMenuButton from "../../Tools/IconMenuButton";
import SupportRequest from "./SupportRequest/SupportRequest";

const UserProfile: FC<{ leave: () => void }> = ({ leave }) => {
  const theme = useSelector((state) => state.theme.theme);
  const openSettings = () => {
    openModal(<Options />, {
      Title: "Настройки",
      style: { height: `${400}px`, width: `${520}px` },
      hiddenButton: true,
      blockMaximize: true,
    });
  };

  return (
    <IconMenuButton
      title="Профиль"
      items={[
        { label: "Настройки", onClick: openSettings },
        { label: "Выйти", onClick: leave },
      ]}
    >
      <PersonIcon className={cn("iconButtonStimate", { light: theme === "light" })} />
    </IconMenuButton>
  );
};

export default UserProfile;
