import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

export async function treeMenu(data) {
  // получение корневого элемента дерева
  const tree = data.event.target.querySelector("#tree") || data.event.target.closest("#tree");

  if (!tree) return;

  // получение выбранного элемента
  let item = tree.querySelector(".tree-item-selected");
  // получение первого элемента если нет выбранного
  if (!item) item = tree.querySelector(".tree-item");
  //получение текста из выбранного item
  let id = "0";
  let itemText = "";
  if (item) {
    id = item.id;
    itemText = tree.querySelector(`#text-tree-item_${id}`).innerText;
  }

  // получение опций от сервера
  if (typeof data.props.onRightClick === "function") {
    data.event.preventDefault();

    data.clickMenu = await data.props.onRightClick(id);
    data.clickMenu = data.clickMenu ? data.clickMenu : {};

    // добавление разделяющей черты если она не пришла от сервера
    if (
      data.clickMenu.arr &&
      data.clickMenu.arr.length > 0 &&
      data.clickMenu.arr[data.clickMenu.arr.length - 1].Caption !== "Divider"
    ) {
      data.clickMenu.arr.push({ Caption: "Divider" });
    }
  }

  // стандартное меню
  const defaultMenu = [];

  const treeDefaultMenu = {
    add: {
      Caption: "Добавить раздел",
      value: "AddSection",
      item: item || tree,
      text: itemText,
      id: id,
    },
    addSub: {
      Caption: "Добавить подраздел",
      value: "AddSubSection",
      item: item || tree,
      text: itemText,
      id: id,
    },
    del: {
      Caption: `Удалить раздел ${itemText}`,
      value: "Delete",
      item: item,
      text: itemText,
      id: id,
      Disabled: !item || item.dataset.noDel === "1",
    },
  };

  // добавление стандартных опций в меню
  if (data.props.defaultMenu) {
    data.props.defaultMenu.forEach((selector) => {
      defaultMenu.push(treeDefaultMenu[selector]);
    });
  }

  // объединение всех меню
  data.items.push(
    await data.CreateMenuItem({
      MenuData: data.AssignMenuItems("Field", data.clickMenu.arr || [], defaultMenu),
    }),
  );
}

export async function paramsMenu(data) {
  if (data.event.target.tagName === "INPUT") return;
  let savedItems, currentSample;
  const endOptions = [];

  if (typeof data.props.onRightClick === "function") {
    data.event.preventDefault();
    const result = await data.props.onRightClick();

    savedItems = Object.values(result.json).map((item) => {
      return {
        Caption: `Шаблон ${typeof item === "object" ? item.text : item}`,
        value: "SelectSample",
        name: typeof item === "object" ? item.text : item,
      };
    });

    currentSample = result.Sample;

    endOptions.push({
      Caption: "Фильтр параметров",
      value: "FilterParams",
      Image: result.filter && <CheckIcon fontSize="small" />,
    });
  }

  const options = [];

  if (savedItems.length) {
    options.push({
      Caption: "Сохраненные шаблоны",
      items: { ...savedItems },
    });
    if (currentSample) {
      options.push({
        Caption: `Удалить шаблон: ${currentSample}`,
        value: "DeleteSample",
        name: currentSample,
      });
    }
  }
  data.items.push(
    await data.CreateMenuItem({
      MenuData: data.AssignMenuItems("Field", [], [...options, ...data.menuForGrid.Field, ...endOptions]),
    }),
  );
}

export async function addressMenu(data) {
  const rec = data.event.target.closest("TR");

  if (!rec) {
    data.setContextMenu(null);
    return;
  }

  data.items.push(
    await data.CreateMenuItem({
      MenuData: data.AssignMenuItems(
        "Field",
        [],
        [
          {
            Caption: `Очистить значение для: ${rec.dataset.name}`,
            value: "ClearValues",
            rec: rec,
          },
        ],
      ),
    }),
  );
}

export async function gridMenu(data) {
  data.gridRef.current = data.target.closest(".grid-panel");
  if (
    //ищем есть ли класс у элемента, если есть то это клик в грид
    data.ClassListForGridMain.includes(data.target.classList[0])
  ) {
    if (typeof data.props.onRightClick === "function") {
      // TO DO ПЕРЕНЕСТИ ВНУТРЬ ТЕЙБЛ
      //тут мы посылаем запрос из пропсов, и если надо добавляем кнопки к стандартному списку
      data.event.preventDefault();
      data.clickMenu = await data.props.onRightClick();
      data.clickMenu = data.clickMenu ? data.clickMenu : {};
      if (
        data.clickMenu.arr &&
        data.clickMenu.arr.length > 0 &&
        data.clickMenu.arr[data.clickMenu.arr.length - 1].Caption !== "Divider"
      ) {
        if (!data.clickMenu.arr[0].ID) {
          data.clickMenu.arr[0].ID = "0";
        }
        data.clickMenu.arr.push({ Caption: "Divider" });
      }
    }
    data.buttonsArr = data.AssignMenuItems(
      "Table",
      data.clickMenu.arr ? data.clickMenu.arr : [],
      data.menuForGrid.Table,
    );
    data.items.push(
      await data.CreateMenuItem({
        MenuData: [data.clickMenu.status, ...data.buttonsArr],
        State: data.clickMenu.state,
      }),
    ); //сохраняем в массив, кнопки
  } else if (data.ClassListForGridHead.includes(data.target.classList[0]) || data.target.closest("grid-column")) {
    //клик в хэдэр
    let fieldInfoElem,
      el = data.target;
    while (el) {
      fieldInfoElem = el.field;
      if (fieldInfoElem) break;
      el = el.parentElement;
    }
    data.buttonsArr = data.AssignMenuItems("Header", [], data.menuForGrid.Header);
    data.buttonsArr.unshift({
      Caption: `Скрыть столбец ${el.field.title}`,
      value: "HideColumn",
    });
    data.GetSortButtons(el.field.sortDirect, data.buttonsArr);
    data.items.push(
      await data.CreateMenuItem({
        MenuData: data.buttonsArr,
        addictionalInfo: {
          fieldName: el.field.fieldName,
          textcomponent: el,
        },
      }),
    );
  } else if (
    //клик в область группировки
    data.ClassListForGridGroup.includes(data.target.classList[0])
  ) {
    data.buttonsArr = data.AssignMenuItems("Grouping", [], data.menuForGrid.Grouping);
    data.items.push(await data.CreateMenuItem({ MenuData: data.buttonsArr }));
  } else {
    data.setContextMenu(null);
  }
}

export async function clsbooksGrid(data) {
  data.gridRef.current = data.target.closest(".grid-panel");
  const grid = data.gridRef.current.grid;
  if (
    //ищем есть ли класс у элемента, если есть то это клик в грид
    data.ClassListForGridMain.includes(data.target.classList[0])
  ) {
    if (typeof data.props.onRightClick === "function") {
      data.clickMenu = await data.props.onRightClick();
      data.clickMenu = data.clickMenu ? data.clickMenu : {};
      if (
        data.clickMenu.arr &&
        data.clickMenu.arr.length > 0 &&
        data.clickMenu.arr[data.clickMenu.arr.length - 1].Caption !== "Divider"
      ) {
        if (!data.clickMenu.arr[0].ID) {
          data.clickMenu.arr[0].ID = "0";
        }
        data.clickMenu.arr.push({ Caption: "Divider" });
      }
    }

    let clsMenu = [];
    await setClsMenu(clsMenu, grid);
    data.buttonsArr = data.AssignMenuItems("Table", data.clickMenu.arr ? data.clickMenu.arr : [], [
      ...data.menuForGrid.Table,
      ...clsMenu,
    ]);
    data.items.push(
      await data.CreateMenuItem({
        MenuData: [data.clickMenu.status, ...data.buttonsArr],
        State: data.clickMenu.state,
      }),
    );
  } else if (data.ClassListForGridHead.includes(data.target.classList[0]) || data.target.closest("grid-column")) {
    //клик в хэдэр
    let fieldInfoElem,
      el = data.target;
    while (el) {
      fieldInfoElem = el.field;
      if (fieldInfoElem) break;
      el = el.parentElement;
    }
    data.buttonsArr = data.AssignMenuItems("Header", [], data.menuForGrid.Header);
    data.buttonsArr.unshift({
      Caption: `Скрыть столбец ${el.field.title}`,
      value: "HideColumn",
    });
    data.GetSortButtons(el.field.sortDirect, data.buttonsArr);
    data.items.push(
      await data.CreateMenuItem({
        MenuData: data.buttonsArr,
        addictionalInfo: {
          fieldName: el.field.fieldName,
          textcomponent: el,
        },
      }),
    );
  } else if (
    //клик в область группировки
    data.ClassListForGridGroup.includes(data.target.classList[0])
  ) {
    data.buttonsArr = data.AssignMenuItems("Grouping", [], data.menuForGrid.Grouping);
    data.items.push(await data.CreateMenuItem({ MenuData: data.buttonsArr }));
  } else {
    data.setContextMenu(null);
  }
}

async function setClsMenu(clsMenu, grid) {
  clsMenu.push({ Caption: "Divider" });

  const recordData = await grid.source.getRecordData();

  if (recordData.info.hidden) {
    clsMenu.push({
      Caption: "Открыть классификатор",
      value: "ShowCls",
      state: "0",
      Image: <VisibilityOutlinedIcon />,
    });
  } else {
    clsMenu.push({
      Caption: "Скрыть классификатор",
      value: "HideCls",
      state: "1",
      Image: <VisibilityOffOutlinedIcon />,
    });
  }

  clsMenu.push({
    Caption: "Показывать только используемые",
    Image: grid.source.actual ? <CheckIcon /> : undefined,
    value: "ShowActualCls",
    state: grid.source.actual,
  });

  clsMenu.push({ Caption: "Divider" });

  let deleteDisabled;
  if (grid.source.getFieldTextSync("ID") < 0 || grid.source.getFieldTextSync("$CfgID")) {
    deleteDisabled = true;
  }

  clsMenu.push({
    Caption: "Удалить запись",
    value: "Delete",
    Image: <DeleteIcon fontSize="small" />,
    Disabled: deleteDisabled,
  });
  clsMenu.push({ Caption: "Divider" });
}

export async function reportMenu(data) {
  data.clickMenu = await data.props.onRightClick();
  if (data.clickMenu.Items) {
    data.items.push(await data.CreateMenuItem({ MenuData: data.clickMenu.Items.items }));
  }
}

export async function defaultMenu(data, items, CreateMenuItem) {
  // clickMenu = await props.onRightClick();
  if (data && data instanceof Array) {
    items.push(await CreateMenuItem({ MenuData: data }));
  }
}

export async function popupMenu(data) {
  let namePopupMenu,
    el = data.target;
  while (el) {
    namePopupMenu = el.getAttribute("namePopupMenu");
    if (namePopupMenu) break;
    el = el.parentElement;
  }
  const popupMenu = data.props.onRightClick();
  if (popupMenu && namePopupMenu) {
    let fixedItems = [];
    data.loadPopupMenu(fixedItems, popupMenu.get(namePopupMenu));
    data.items.push(await data.CreateMenuItem({ MenuData: fixedItems }));
  }
}

export async function personPhotoMenu(data) {
  data.items.push(
    await data.CreateMenuItem({
      MenuData: data.AssignMenuItems("Field", [], [...data.menuForGrid.Field]),
    }),
  );
}
