import { Base64 } from "./base64.js";
import { ASN1 } from "./asn1.js";

/* вспомогательные процедуры получения информации об OID субъекта и издателя
 */

function subject(n, name) {
  var value;
  if (
    n &&
    name &&
    Array.isArray(n.sub) &&
    n.sub.length === 3 &&
    Array.isArray(n.sub[0].sub) &&
    n.sub[0].sub.length >= 8
  ) {
    var subj = n.sub[0].sub[5];
    if (subj && Array.isArray(subj.sub)) {
      for (var i = 0; i < subj.sub.length; i++) {
        var oid = subj.sub[i].sub[0].sub[0].content();
        var off = oid.indexOf("\n");
        if (off >= 0) {
          oid = oid.substring(0, off);
        }
        if (oid === name) {
          value = subj.sub[i].sub[0].sub[1].content();
          break;
        }
      }
    }
  }
  return value;
}

function issuer(n, name) {
  var value;
  if (
    n &&
    name &&
    Array.isArray(n.sub) &&
    n.sub.length === 3 &&
    Array.isArray(n.sub[0].sub) &&
    n.sub[0].sub.length >= 8
  ) {
    var subj = n.sub[0].sub[3];
    if (subj && Array.isArray(subj.sub)) {
      for (var i = 0; i < subj.sub.length; i++) {
        var oid = subj.sub[i].sub[0].sub[0].content();
        var off = oid.indexOf("\n");
        if (off >= 0) {
          oid = oid.substring(0, off);
        }
        if (oid === name) {
          value = subj.sub[i].sub[0].sub[1].content();
          break;
        }
        if (oid === name) {
          value = subj.sub[i].sub[0].sub[1].content();
          break;
        }
      }
    }
  }
  return value;
}

/* новый API
 */

function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function webkcryptdata(method, args) {
  var data = {
    type: "webkcrypt-invoke",
    uuid: uuid(),
    invoke: {
      method: method,
    },
  };
  if (args) {
    data.invoke.args = args;
  }
  return data;
}

function WebkcryptCallback(uuid, callback) {
  this.callback = callback;
  this.uuid = uuid;
  this.handle = (event) => {
    var handled = false;
    if (event && event.data) {
      if (event.data["type"] && event.data.type == "webkcrypt-reply") {
        if (event.data["uuid"] && event.data.uuid == this.uuid) {
          handled = this.callback(event);
          if (handled) {
            window.removeEventListener("message", this.handle);
          }
        }
      }
    }
  };
}

function webkcrypt_invoke(method, args, callback) {
  var data = webkcryptdata(method, args);
  if (callback) {
    window.addEventListener("message", new WebkcryptCallback(data.uuid, callback).handle, false);
  }
  window.postMessage(data, "*");
}

function CallbackResult(data, timeoutms = 90000) {
  this.uuid = data.requestId;
  this.value = new Promise((resolve, reject) => {
    this.callback = { resolve, reject };
    if (timeoutms > 0) {
      this.timer = setTimeout(() => {
        reject(undefined);
      }, timeoutms);
    }
  });
  this.handle = (event) => {
    if (event && event.data) {
      if (event.data["type"] && event.data.type == "workspace-response") {
        if (event.data["requestId"] && event.data.requestId == this.uuid) {
          clearTimeout(this.timer);
          window.removeEventListener("message", this.handle);
          if (typeof event.data.result === "object" && event.data.result !== null) {
            data = event.data.result;
          } else {
            data = event.data;
          }
          this.callback.resolve(data.result);
        }
      }
    }
  };
  window.addEventListener("message", this.handle, false);
  window.postMessage(data, "*");
}

function CallbackResult2(value) {
  this.value = value;
}

function webkcrypt_check_promise() {
  return new Promise((resolve, reject) => {
    var el = document.getElementById("WorkspaceEx");
    if (el) {
      if (el.getAttribute("is-installed") === "true") {
        var p = new Promise((resolve, reject) => {
          webkcrypt_invoke("webkcrypt-about", null, (event) => {
            resolve(event);
          });
          setTimeout(() => {
            reject(undefined);
          }, 90000);
        });
        p.then((value) => {
          resolve(value);
        }).catch((e) => {
          reject(e);
        });
      } else {
        reject(failed_no_plugin());
      }
    } else {
      reject(failed_no_plugin());
    }
  });
}

function webkcrypt_plain_text_certificate_list() {
  var data = {
    type: "workspace-request",
    requestId: uuid(),
    params: {
      command: "certList",
    },
  };
  var p = new CallbackResult(data);
  return p;
}

function webkcrypt_make_pkcs7_signature(sn, data, detached = true, check = false) {
  var data = {
    type: "workspace-request",
    requestId: uuid(),
    params: {
      command: "sign",
      format: "CMS",
      type: detached ? "detached" : "",
      serialNumber: sn,
      data: data,
    },
  };
  var p = new CallbackResult(data);
  return p;
}

function webkcrypt_about() {
  var p = new Promise((resolve, reject) => {
    webkcrypt_invoke("webkcrypt-about", null, (event) => {
      var value = {};
      var data = event.data;
      if (data["invoke"]) {
        if (data.invoke["out"]) {
          value.plugin = data.invoke.out.plugin;
          value.hostapp = {};
          value.hostapp.version = {};
          value.hostapp.version.value = data.invoke.out.webkcrypt.version.value;
        }
      }
      resolve(value);
    });
    setTimeout(() => {
      reject(undefined);
    }, 90000);
  });
  var callback2 = new CallbackResult2(p);
  return callback2;
}

function webkcrypt_make_soap_xmldsig(sn, data, xpath = "", check = false) {
  var data = {
    type: "workspace-request",
    requestId: uuid(),
    params: {
      command: "fssSign",
      format: "XMLDsig",
      type: "attached",
      serialNumber: sn,
      data: data,
      xPath: xpath,
    },
  };
  var p = new CallbackResult(data);
  return p;
}

function webkcrypt_make_soap_xml_encrypt(sn, data, other) {
  var data = {
    type: "workspace-request",
    requestId: uuid(),
    params: {
      command: "fssEncryptXml",
      serialNumber: sn,
      data: data,
      fssCert: other,
    },
  };
  var p = new CallbackResult(data);
  return p;
}

function webkcrypt_make_soap_xml_decrypt(sn, data) {
  var data = {
    type: "workspace-request",
    requestId: uuid(),
    params: {
      command: "fssDecryptXml",
      serialNumber: sn,
      data: data,
    },
  };
  var p = new CallbackResult(data);
  return p;
}

function webkcrypt_make_image_scan() {
  var data = {
    type: "workspace-request",
    requestId: uuid(),
    params: {
      command: "scan",
    },
  };
  var p = new CallbackResult(data);
  return p;
}

function webkcrypt_xml_text_certificate_list() {
  var data = {
    type: "workspace-request",
    requestId: uuid(),
    params: {
      command: "certListAsXml",
    },
  };
  var p = new CallbackResult(data);
  return p;
}

function webkcrypt_initialize() {
  window.webkcrypt["type"] = "webkcrypt";
  window.webkcrypt.plain_text_certificate_list = webkcrypt_plain_text_certificate_list;
  window.webkcrypt.make_pkcs7_signature = webkcrypt_make_pkcs7_signature;
  window.webkcrypt.about = webkcrypt_about;
  window.webkcrypt.make_soap_xmldsig = webkcrypt_make_soap_xmldsig;
  window.webkcrypt.make_soap_xml_encrypt = webkcrypt_make_soap_xml_encrypt;
  window.webkcrypt.make_soap_xml_decrypt = webkcrypt_make_soap_xml_decrypt;
  window.webkcrypt.make_image_scan = webkcrypt_make_image_scan;
  window.webkcrypt.xml_text_certificate_list = webkcrypt_xml_text_certificate_list;
}

function failed_no_plugin() {
  var text =
    "Не удалось подключиться к расширению. Возможные причины: \n" +
    "  1. Расширение не установлено;\n" +
    "  2. Расширение установлено, но отключено;\n" +
    "  3. Расширение включено, но страница не была обновлена;\n" +
    "  4. У расширения нет прав для работы с данной страницей (адресом).";
  return text;
}

function patch_failed_no_plugin() {
  window.webkcrypt["type"] = "none";
  window.webkcrypt.plain_text_certificate_list = function () {
    var p = new Promise((resolve, reject) => {
      reject(failed_no_plugin());
    });
    var callback2 = new CallbackResult2(p);
    return callback2;
  };
  window.webkcrypt.make_pkcs7_signature = function (sn, data, detached = true, check = false) {
    var p = new Promise((resolve, reject) => {
      reject(failed_no_plugin());
    });
    var callback2 = new CallbackResult2(p);
    return callback2;
  };
  window.webkcrypt.about = function () {
    var p = new Promise((resolve, reject) => {
      reject(failed_no_plugin());
    });
    var callback2 = new CallbackResult2(p);
    return callback2;
  };
  window.webkcrypt.make_soap_xmldsig = function (sn, data, xpath = "", check = false) {
    var p = new Promise((resolve, reject) => {
      reject(failed_no_plugin());
    });
    var callback2 = new CallbackResult2(p);
    return callback2;
  };
  window.webkcrypt.make_soap_xml_encrypt = function (sn, data, other) {
    var p = new Promise((resolve, reject) => {
      reject(failed_need_plugin_replace());
    });
    var callback2 = new CallbackResult2(p);
    return callback2;
  };
  window.webkcrypt.make_soap_xml_decrypt = function (sn, data) {
    var p = new Promise((resolve, reject) => {
      reject(failed_need_plugin_replace());
    });
    var callback2 = new CallbackResult2(p);
    return callback2;
  };
  window.webkcrypt.make_image_scan = function () {
    var p = new Promise((resolve, reject) => {
      reject(failed_need_plugin_replace());
    });
    var callback2 = new CallbackResult2(p);
    return callback2;
  };
  window.webkcrypt.xml_text_certificate_list = function () {
    var p = new Promise((resolve, reject) => {
      reject(failed_need_plugin_replace());
    });
    var callback2 = new CallbackResult2(p);
    return callback2;
  };
}

function failed_need_plugin_replace() {
  var text =
    "Для использования расширенного криптографического функционала необходимо:\n" +
    "  1. Запретить или удалить текущий плагин от Крипто-Про (Cades);\n" +
    "  2. Установить специализированный плагин для АС 'Сметы'.";
  return text;
}

export default function webkcrypt_create() {
  /* проверяем на повторную инициализацию
   */
  if (window.webkcrypt) {
    return;
  }
  var webkcrypt = {
    type: undefined,
  };
  window.webkcrypt = webkcrypt;
  /* заглушки на методы
   */
  var p = new Promise((resolve, reject) => {
    if (!!cadesplugin) {
      webkcrypt["type"] = "cades";
      cadesplugin
        .then((value) => {
          webkcrypt.plain_text_certificate_list = function () {
            var p = new Promise((resolve, reject) => {
              cadesplugin.async_spawn(function* (args) {
                var store;
                try {
                  store = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
                  yield store.Open(
                    cadesplugin.CAPICOM_CURRENT_USER_STORE,
                    cadesplugin.CAPICOM_MY_STORE,
                    cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
                  );
                  var listof = yield store.Certificates;
                  var total = yield listof.Count;
                  var value = "";
                  for (var i = 1; total >= i; i++) {
                    var c = yield listof.Item(i);
                    var sn = yield c.SerialNumber;
                    if (sn) {
                      value += sn;
                    }
                    value += ";";
                    if (yield c.HasPrivateKey()) {
                      value += "Личный";
                    } else {
                      value += "Публичный";
                    }
                    value += ";";
                    var cn = yield c.GetInfo(cadesplugin.CAPICOM_CERT_INFO_SUBJECT_SIMPLE_NAME);
                    if (cn) {
                      value += cn;
                    }
                    value += ";";
                    var year, month, day, hours, minutes, seconds;
                    var validfrom = new Date(yield c.ValidFromDate);
                    if (validfrom) {
                      year = validfrom.getFullYear().toString();
                      month = ("0" + (validfrom.getMonth() + 1).toString()).slice(-2);
                      day = ("0" + validfrom.getDate().toString()).slice(-2);
                      value += `${day}.${month}.${year}`;
                    }
                    value += ";";
                    var validto = new Date(yield c.ValidToDate);
                    if (validto) {
                      year = validto.getFullYear().toString();
                      month = ("0" + (validto.getMonth() + 1).toString()).slice(-2);
                      day = ("0" + validto.getDate().toString()).slice(-2);
                      value += `${day}.${month}.${year}`;
                    }
                    value += ";";
                    var der = ASN1.decode(Base64.decode(yield c.Export(cadesplugin.CADESCOM_ENCODE_BASE64)));
                    var snils = subject(der, "1.2.643.100.3");
                    if (snils) {
                      value += snils;
                    }
                    value += "\n";
                    // console.log(subject(value, "1.2.643.100.3")); // СНИЛС
                    // console.log(issuer(value, "2.5.4.3")); // CN
                    // https://habr.com/ru/articles/588681/
                    // INN      1.2.643.3.131.1.1       (12 цифр)
                    // INNLE    1.2.643.100.4           (10 цифр или 00 + 10 цифр)
                  }
                  yield store.Close();
                  resolve(value);
                } catch (e) {
                  if (store) {
                    yield store.Close();
                  }
                  reject(e);
                }
              });
            });
            var callback2 = new CallbackResult2(p);
            return callback2;
          };
          webkcrypt.make_pkcs7_signature = function (sn, data, detached = true, check = false) {
            sn = sn.toLowerCase();
            var p = new Promise((resolve, reject) => {
              cadesplugin.async_spawn(function* (args) {
                var store;
                try {
                  store = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
                  yield store.Open(
                    cadesplugin.CAPICOM_CURRENT_USER_STORE,
                    cadesplugin.CAPICOM_MY_STORE,
                    cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
                  );
                  var listof = yield store.Certificates;
                  var total = yield listof.Count;
                  for (var i = 1; total >= i; i++) {
                    var c = yield listof.Item(i);
                    if ((yield c.SerialNumber).toLowerCase() === sn) {
                      var signer = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
                      yield signer.propset_Certificate(c);
                      yield signer.propset_CheckCertificate(check);
                      var signeddata = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                      yield signeddata.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
                      yield signeddata.propset_Content(data);
                      var value = yield signeddata.SignCades(signer, cadesplugin.CADESCOM_PKCS7_TYPE, detached);
                      yield store.Close();
                      resolve(value);
                    }
                  }
                  yield store.Close();
                  reject(`Сертификат с серийным номером ${sn} не найден в хранилище сертификатов.`); // сертификат не найден
                } catch (e) {
                  if (store) {
                    yield store.Close();
                  }
                  reject(e);
                }
              });
            });
            var callback2 = new CallbackResult2(p);
            return callback2;
          };
          webkcrypt.about = function () {
            var p = new Promise((resolve, reject) => {
              cadesplugin.async_spawn(function* (args) {
                var about = yield cadesplugin.CreateObjectAsync("CAdESCOM.About");
                var plugin = yield about.Version;
                var value = {};
                value.plugin = {
                  version: {
                    value: plugin,
                  },
                };
                resolve(value);
              });
            });
            var callback2 = new CallbackResult2(p);
            return callback2;
          };
          webkcrypt.make_soap_xmldsig = function (sn, data, xpath = "", check = false) {
            var p = new Promise((resolve, reject) => {
              reject(failed_need_plugin_replace());
              /*
                cadesplugin.async_spawn(function* (args) {
                  var store;
                  try {
                    store = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
                    yield store.Open(
                      cadesplugin.CAPICOM_CURRENT_USER_STORE,
                      cadesplugin.CAPICOM_MY_STORE, cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);
                    var listof = yield store.Certificates;
                    var total = yield listof.Count;
                    for(var i = 1; total >= i; i++) {
                      var c = yield listof.Item(i);
                      if ((yield c.SerialNumber).toLowerCase() === sn) {
                        var signer = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
                        yield signer.propset_Certificate(c);
                        yield signer.propset_CheckCertificate(check);
                        var xml = yield cadesplugin.CreateObjectAsync("CAdESCOM.SignedXML");
                        yield xml.propset_Content(data);
                        var value = yield xml.Sign(signer);
                        yield store.Close();
                        resolve(value);
                      }
                    }
                    reject(); // сертификат не найден
                  }
                  catch(e) {
                    if (store) {
                      yield store.Close();
                    }
                    reject(e);
                  }
                });
*/
            });
            var callback2 = new CallbackResult2(p);
            return callback2;
          };
          webkcrypt.make_soap_xml_encrypt = function (sn, data, other) {
            var p = new Promise((resolve, reject) => {
              reject(failed_need_plugin_replace());
            });
            var callback2 = new CallbackResult2(p);
            return callback2;
          };
          webkcrypt.make_soap_xml_decrypt = function (sn, data) {
            var p = new Promise((resolve, reject) => {
              reject(failed_need_plugin_replace());
            });
            var callback2 = new CallbackResult2(p);
            return callback2;
          };
          webkcrypt.make_image_scan = function () {
            var p = new Promise((resolve, reject) => {
              reject(failed_need_plugin_replace());
            });
            var callback2 = new CallbackResult2(p);
            return callback2;
          };
          webkcrypt.xml_text_certificate_list = function () {
            var p = new Promise((resolve, reject) => {
              reject(failed_need_plugin_replace());
            });
            var callback2 = new CallbackResult2(p);
            return callback2;
          };
        })
        .catch((e) => {
          reject(e);
        });
    } else {
      reject(undefined);
    }
  });
  p.then((value) => {
    // nothing
  }).catch((e) => {
    webkcrypt["type"] = "unknown";
    webkcrypt.plain_text_certificate_list = function () {
      var p = new Promise((resolve, reject) => {
        var p = webkcrypt_check_promise();
        p.then((event) => {
          webkcrypt_initialize();
          webkcrypt_plain_text_certificate_list()
            .value.then((value) => {
              resolve(value);
            })
            .catch((e) => {
              reject(e);
            });
        }).catch((e) => {
          patch_failed_no_plugin();
          reject(e);
        });
      });
      var callback2 = new CallbackResult2(p);
      return callback2;
    };
    webkcrypt.make_pkcs7_signature = function (sn, data, detached = true, check = false) {
      var p = new Promise((resolve, reject) => {
        var p = webkcrypt_check_promise();
        p.then((event) => {
          webkcrypt_initialize();
          webkcrypt_make_pkcs7_signature(sn, data, detached, check)
            .value.then((value) => {
              resolve(value);
            })
            .catch((e) => {
              reject(e);
            });
        }).catch((e) => {
          patch_failed_no_plugin();
          reject(e);
        });
      });
      var callback2 = new CallbackResult2(p);
      return callback2;
    };
    webkcrypt.about = function () {
      var p = new Promise((resolve, reject) => {
        var p = webkcrypt_check_promise();
        p.then((event) => {
          webkcrypt_initialize();
          var value = {};
          var data = event.data;
          if (data["invoke"]) {
            if (data.invoke["out"]) {
              value.plugin = data.invoke.out.plugin;
              value.hostapp = {};
              value.hostapp.version = {};
              value.hostapp.version.value = data.invoke.out.webkcrypt.version.value;
            }
          }
          resolve(value);
        }).catch((e) => {
          patch_failed_no_plugin();
          reject(e);
        });
      });
      var callback2 = new CallbackResult2(p);
      return callback2;
    };
    webkcrypt.make_soap_xmldsig = function (sn, data, xpath = "", check = false) {
      var p = new Promise((resolve, reject) => {
        var p = webkcrypt_check_promise();
        p.then((event) => {
          webkcrypt_initialize();
          webkcrypt_make_soap_xmldsig(sn, data, xpath, check)
            .value.then((value) => {
              resolve(value);
            })
            .catch((e) => {
              reject(e);
            });
        }).catch((e) => {
          patch_failed_no_plugin();
          reject(e);
        });
      });
      var callback2 = new CallbackResult2(p);
      return callback2;
    };
    webkcrypt.make_soap_xml_encrypt = function (sn, data, other) {
      var p = new Promise((resolve, reject) => {
        var p = webkcrypt_check_promise();
        p.then((event) => {
          webkcrypt_initialize();
          webkcrypt_make_soap_xml_encrypt(sn, data, other)
            .value.then((value) => {
              resolve(value);
            })
            .catch((e) => {
              reject(e);
            });
        }).catch((e) => {
          patch_failed_no_plugin();
          reject(e);
        });
      });
      var callback2 = new CallbackResult2(p);
      return callback2;
    };
    webkcrypt.make_soap_xml_decrypt = function (sn, data) {
      var p = new Promise((resolve, reject) => {
        var p = webkcrypt_check_promise();
        p.then((event) => {
          webkcrypt_initialize();
          webkcrypt_make_soap_xml_decrypt(sn, data)
            .value.then((value) => {
              resolve(value);
            })
            .catch((e) => {
              reject(e);
            });
        }).catch((e) => {
          patch_failed_no_plugin();
          reject(e);
        });
      });
      var callback2 = new CallbackResult2(p);
      return callback2;
    };
    webkcrypt.make_image_scan = function () {
      var p = new Promise((resolve, reject) => {
        var p = webkcrypt_check_promise();
        p.then((event) => {
          webkcrypt_initialize();
          webkcrypt_make_image_scan()
            .value.then((value) => {
              resolve(value);
            })
            .catch((e) => {
              reject(e);
            });
        }).catch((e) => {
          patch_failed_no_plugin();
          reject(e);
        });
      });
      var callback2 = new CallbackResult2(p);
      return callback2;
    };
    webkcrypt.xml_text_certificate_list = function () {
      var p = new Promise((resolve, reject) => {
        var p = webkcrypt_check_promise();
        p.then((event) => {
          webkcrypt_initialize();
          webkcrypt_xml_text_certificate_list()
            .value.then((value) => {
              resolve(value);
            })
            .catch((e) => {
              reject(e);
            });
        }).catch((e) => {
          patch_failed_no_plugin();
          reject(e);
        });
      });
      var callback2 = new CallbackResult2(p);
      return callback2;
    };
  });
}
