import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Popper from "@mui/material/Popper";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React, { useEffect, useState } from "react";
import { AxiosRequest } from "../../../Url";
import { AutoTextTranslate, getParamsObject, isEmptyObject } from "../Tools";

export default function EditorAdress(props) {
  const [lazylist, setLazylist] = useState([]);
  const [value, setValue] = useState({
    text: props.text === undefined ? "" : props.text,
  });
  const [inputValue, setInputValue] = useState("");
  const [load, setLoad] = useState(false);
  const [caption, setCaption] = useState("");
  const [timer, setTimer] = useState(null);
  const [noOptionsText, setNoOptionsText] = useState("Введите адрес для поиска");

  //отслеживание входящих значений
  useEffect(() => {
    setValue({ text: props.text === undefined ? "" : props.text });
  }, [props.text]);

  useEffect(() => {
    if (props.setCaption && caption) props.setCaption(caption);
  }, [caption]);

  async function getLazyList(text) {
    if (text.length > 3 && text !== value.text && load === false && text !== props.text) {
      setLazylist([]);
      setLoad(true);
      let params = new Map();
      params.set("prefix", "kladr");
      params.set("comand", "LazyList");
      params.set("Text", text);
      params.set("smart", "1");
      params.set("dv", props.division ? props.division : "0");
      params.set("WSM", "1");
      const json = await AxiosRequest(true, params);
      let arr = [];
      if (!isEmptyObject(json.Items)) {
        for (const value of Object.values(json.Items)) {
          arr.push(value);
        }
      }
      setLoad(false);
      setLazylist(arr);
      setNoOptionsText("Ничего не найдено");
    }
  }

  function assignAddress(id) {
    if (id && id.text !== undefined) {
      //Проверка на наличие модального окна с подтверждением
      if (props.confirmation !== undefined) {
        props.confirmation(props.confirmationText, assign(id), setInputValue, value.text);
      } else {
        assign(id)();
      }
    }
  }

  function assign(id) {
    return () => {
      //установка значения input после подтверждения
      if (props.confirmation !== undefined) setInputValue(id.text);
      const params = new Map();
      params.set("prefix", "kladr");
      params.set("comand", "AssignAddress");
      params.set("ObjRef", props.ObjRef ? props.ObjRef : "0");
      params.set("Key", id.id);
      params.set("dv", props.division ? props.division : "0");
      AxiosRequest(true, params).then(async (response) => {
        if (id.id === 0 || !isEmptyObject(response)) {
          //если у адреса нет ID он присылается сервером, далее вызывается функция для установки Адреса без первоначального ID
          if (!props.ObjRef) await setAddress(response.ID);
          setValue({ text: id.text });
          setCaption(response.Caption);
          if (props.ClickForm) {
            props.ClickForm(undefined, props.keyName, undefined, response.Current);
          }
          if (props.setData) {
            props.setData(
              getParamsObject(
                response,
                props.paramsData.GroupID,
                props.paramsData.address,
                props.paramsData.parentModule,
              ),
            );
          }
        } else {
          setInputValue(value.text);
          console.error("Empty response AssignAddress");
        }
      });
    };
  }

  //функция для установки Адреса без ID
  async function setAddress(id) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "SetAddress")
      .set("Type", "0")
      .set("ID", id)
      .set("GroupID", props.GroupID);
    return AxiosRequest(true, params);
  }

  const popperMy = function (props) {
    return <Popper {...props} style={{ width: "fit-content", zIndex: "100000" }} />;
  };

  return (
    <Autocomplete
      className="text-field__input"
      selectOnFocus
      autoHighlight
      disabled={props.disabled || props.ReadOnly}
      freeSolo={props.onlySelectValue ? (lazylist.length ? true : false) : true}
      id={props.keyName}
      filterOptions={(options) => options}
      size="small"
      disableListWrap
      handleHomeEndKeys
      style={{ border: "none" }}
      sx={{
        display: "inline-block",
        width: "100%",
        // height:"100%"
      }}
      value={value}
      onChange={(event, newValue) => {
        // (newValue, "value")
        assignAddress(newValue);
        // setValue(newValue)
        // props.setSelected(newValue)
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        if (newInputValue.length < 3) setNoOptionsText("Введите адрес для поиска");
        //Если есть подтверждение, то при Клике на значение из списка новое значение не установится
        if (!(event && event["_reactName"] === "onClick" && props.confirmation !== undefined)) {
          setInputValue(newInputValue);

          if (event !== null && event["_reactName"] === "onChange") {
            clearTimeout(timer);
            const newTimer = setTimeout(() => {
              if (newInputValue === "" && props.ClickForm) {
                assignAddress({ text: "", id: 0 });
              } else {
                getLazyList(AutoTextTranslate(newInputValue));
              }
            }, 600);
            setTimer(newTimer);
          }
        }
      }}
      noOptionsText={noOptionsText}
      onClose={() => {
        if (props.onlySelectValue) {
          setInputValue(value.text);
          setNoOptionsText("Введите адрес для поиска");
        }
      }}
      // options={lazylist.map((opion:any)=> opion.text)}
      options={lazylist}
      loading={props.onlySelectValue && load}
      loadingText={<CircularProgress size={20} />}
      getOptionLabel={(option) => option.text || ""}
      PopperComponent={props.defaultPopper ? undefined : popperMy}
      renderInput={(params) => (
        <div ref={params.InputProps.ref} style={{ position: "relative" }}>
          <input
            placeholder={props.placeholder}
            className="text-field__input"
            type={"text"}
            style={props.style}
            {...params.inputProps}
          />
          {!props.onlySelectValue && load ? (
            <CircularProgress
              color="primary"
              size={20}
              style={{
                top: "6px",
                right: "10px",
                position: "absolute",
              }}
            />
          ) : null}
        </div>
      )}
      renderOption={(props, option, { inputValue }) => {
        const text = option.text;
        const matches = match(text, inputValue);
        const parts = parse(text, matches);

        return (
          <li {...props} key={`${props.key}_${props.id}`}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
}
