import { ConfigLk, ConfigSmeta } from "../../Url";

export enum ViewClass {
  HTMLView = "{55D200F8-A5EE-4BB8-B9AD-762B6FB815D1}",
  DocSite = "{03338F11-FC1E-11D4-A605-0000E8D9CBDB}",
  DocBook = "{48C155C0-1336-11D6-9CF2-DB1D5BC3046D}",
  DocReps = "{A358FF4E-4CE5-4CDF-B32D-38CC28448C61}",
  RepSite = "{B357E5B2-137F-4253-BBEF-E5CFD697E362}",
  StgBook = "{E39CC90D-5F17-4FDF-883E-E75DEA69BE60}",
  OrgBook = "{2AC0E428-23CD-49F6-AF96-3DB9D6B7AD0A}",
  MultiTab = "{353FD9D7-651E-4840-9319-A8578806C496}",
  TableRep = "{295EA015-4573-4AD9-922A-A14CE0FD9C78}",
  BookObj = "{7C0F4F9E-42BF-409A-A8DB-C92A9AA93C1B}",
  ParamDlg = "{D8402CE6-6582-4F0D-A82D-C2D9CA73F79E}",
  Address = "{AE5A67E0-EBEB-11D5-900D-000021E6A31F}",
  TreeView = "{5D1A1010-A394-43EC-A84B-AB401C1C2640}",
  RepTabs = "{18CCCA1A-CD3D-41B3-8C20-9F80AA3ED8CE}",
  CellObj = "{408E20A3-4BE3-4DCD-98BD-2613A8968783}",
  Preview = "{E8377696-9AE6-4A44-932B-FF229BDACFC6}",
  Account = "{A958A9C0-92D0-11D4-91A4-000021670179}",
  Persons = "{D9958041-524D-11D4-91A4-000021670179}",
  Salary = "{2700EC50-32E1-11D4-A4A3-0000E8D9CBDB}",
  Staff = "{8A973980-247F-11D4-B977-9F6E89353703}",
  Transact = "{ECEA4BF8-D2F2-4642-BB8F-FED0351B2339}",
  Excells = "{9096D2C6-8BD0-40E0-964C-7000D309474B}",
  WorkTimes = "{D500DB82-9585-487D-98DB-11B77EB51D42}",
  Calendar = "{B52AB815-36CC-4921-A1C0-6C3649D9D0E9}",
  DepTree = "{1C947E18-2CFA-4394-9BCF-F393EBE3CE98}",
  Amounts = "{7313DD64-4C2A-4499-BB41-31E1E594AA52}",
  Leaves = "{EC0477CD-4CB3-40A0-9771-A444F94E0984}",
  Ranges = "{DE3EDCEB-F7A0-4B02-92B6-03A070B7BEA8}",
  PayTypes = "{B9D28843-34BE-11D4-B977-C74803C52043}",
  Tariff = "{B71DDB5C-0391-4EF0-8E90-44D37CF7EE54}",
  WModes = "{B6F6D3C0-6159-11D4-91A4-000021670179}",
  PersonAddr = "{C83806B3-80D6-4983-A53D-1FA792B60130}",
  Grades = "{C3A20435-640D-4653-A821-C6C6E76A1B63}",
  CalcRange = "{3748C5C5-C0E9-4D45-8E05-17A046FD5C56}",
  Children = "{8790A231-377B-4927-B744-9443F386989C}",
  LongTime = "{C525B0F8-232B-4136-A5F2-AB22C77F55F0}",
  ChildSel = "{D828B658-CBC4-46E4-A512-1E27F7C9BCBA}",
  TimeCfg = "{231F49EB-85F0-4226-9AC6-E3BD853CD7DA}",
  SelApp = "{7D4264B1-7DA7-4009-A7BF-830D17590A4F}",
  JobHist = "{D370E2F6-166E-4519-8A55-7A582F73F533}",
  WorkRanges = "{2A10A1C9-DAA5-42ED-A452-AB062FA33064}",
  PersonSearch = "{64DE7386-1BAC-416A-9B93-2C94368F179C}",
  CreateObject = "{5F859354-243D-449C-A4BC-B89EA9A5D1B2}",
  BaseSum = "{90C47D6E-A8F0-4AE4-8EAB-BE17E434855E}",
  ObjLinksDlg = "{AC4B1ADC-D6F2-4F1A-B385-CA7A22D6FC9C}",
  SalaryRanges = "{579A7731-6949-4A22-B09A-E3C601717ECB}",
  TimeTable = "{59E41F61-ABF6-11D4-A558-0000E8D9CBDB}",
  MonthRange = "{5FEE058E-8083-453F-B31E-FB8DD7ED5174}",
  DateRange = "{3D16C8B0-9C78-474C-9D3D-10112A3F96A6}",
  DocObjEdit = "{C5E1165E-9829-4789-BD08-18E875D33FB3}",
  UserReps = "{A94BF9FA-2115-4803-B33F-FE36D1525AE4}",
  PersonBook = "{7FAC2B20-109D-4406-A757-61674277B71F}",
  Chart = "{476E908B-D276-488D-82CD-E5C80D676CCF}",
  Cubes = "{19C8F31A-7D2C-4804-B5BE-75A242B670D3}",
  DataUpdates = "{2CF2C8EE-2890-4AF1-A9B0-34E1521818B4}",
  CallCenter = "{9378D70A-605A-4CE6-A86D-BA4E0268373D}",
  PhoneBook = "{37FC90E4-43AE-4C9B-808C-20F9DCB69EDE}",
  EMailBook = "{800FDE74-F636-4757-8F18-818105047B54}",
  SelectLinks = "{3BDD2452-3860-4997-ACC7-495FB1BFC7FD}",
  FastRep = "{D0DFAB41-C45B-4934-B924-12A9BFC41171}",
  NotePad = "{4A1F9326-CA89-4D8C-AD64-FC80C3741027}",
  WareBook = "{03A94B88-CBC2-40C8-86BB-BA0184EF57E0}",
  Xromium = "{35222CB2-9DD5-4D9D-80AF-DF93604432C9}",
  HTMLEdit = "{AC183F1C-2254-4B7C-AEBE-250EF345B74D}",
  PayTypeSet = "{2C80242F-2190-4DFC-BCB4-675065E27379}",
  Designer = "{2B55A78C-85D0-4525-A190-1CA16FD86E78}",
  Forms = "{C0CED968-8834-405D-8801-A3838BF536F3}",
  Remains = "{378B8494-F562-43EF-BBA5-06D5FE0E2A71}",
  LazyStat = "{D22DFDA0-DB27-4FB4-865F-B478DB85F2A4}",
  PDFView = "{A5CDFCEB-A95A-4ABC-839E-77D1D1F5CD86}",
  LazyAddress = "{D9DE8B26-C68B-4318-96EA-FD75497A5C91}",
  // administrator
  Departs = "{C79B4449-B9CE-41C2-A28F-FB40EBA7E9DA}",
  Audit = "{B467C12A-3650-4161-AE58-CC54161596CA}",
  RegEdit = "{BF011912-27D9-11D4-A493-0000E8D9CBDB}",
  DBView = "{A759DBA0-9FA2-11D5-B97A-C2A4095B2C3B}",
  AccCors = "{010C6A4E-4954-4380-9E9F-40712B45D0DE}",
  ClsBook = "{37912C20-59B2-415F-8F59-DE7F85AABB00}",
  Support = "{8638FB9A-52C0-46C4-8F49-5BD30C27C575}",
  Recycle = "{4124FBDE-F767-406B-B976-E703E0DE410C}",
  DocObjs = "{B6F6A5FB-DF1C-4A11-8262-8DC05C20A166}",
  // configurator
  WPAdmin = "{D8C2A6A0-8FA8-11D4-A4C6-008048DEC2B9}",
  Params = "{003ADE80-8149-4CA2-B028-35ADF600D183}",
  DocMan = "{28310E52-82FA-11D4-A51F-0000E8D9CBDB}",
  RepMan = "{DD36FCC6-7F05-4792-84CE-D53738D35A9B}",
  Analytics = "{90BC38C5-FED4-4100-BB3C-66B518FB7AA6}",
  ABLEdit = "{8841C134-FBAA-4C91-A960-C04247AB5F5F}",
  LinkCfgDlg = "{F86045F1-F4E6-49F7-B199-DAB7EF3B6880}",
  UserRepCfg = "{E8C5E63F-6DCB-4EAA-AB27-F6DAE42CF338}",
  GroupObj = "{9C9E79F7-A955-4330-B615-66497724D424}",
  Absences = "{14D42154-88F2-45F0-A8FF-DC191EEEC659}",
  BookMan = "{36C892D3-1F05-4516-917A-56770D35871A}",
  Merging = "{CABC4B10-23A7-412C-A588-9F640422558E}",
  Updates = "{BDA4ADC2-C4DB-4496-BEA6-6803BB79B709}",
  Skinner = "{4E039850-ED97-44F8-B461-6EE5F026C8F5}",
  ABLText = "{B0EC7D97-D997-4FBA-A80C-4689C487F977}",
}

export enum ObjType {
  Department = 2790,
  RendLeave = 2640,
  HourTime = 2644,
  RendMode = 2646,
  Document = 2200,
  RendValue = 2586,
  OrgTypeCls = 2773,
  Organization = 2310,
  Bank = 2330,
  BankAccount = 2320,
  ChildOrg = 2778,
  WorkMode = 2004,
  Person = 2040,
  PayType = 2380,
  Rendering = 2010,
  BasicAmounts = 2611,
  Appointment = 2710,
  RendHistory = 2035,
  HourType = 2721,
  OpenDirDialog = 2797,
  TimeTable = 24043,
  DocCfgType = 2788,
  DocField = 2370,
  DocColumn = 2521,
  DocForm = 2807,
  DocButton = 2808,
  Program = 2001,
  Grade = 2776,
  BaseValue = 2782,
  ParamSet = 2789,
  ProjectSection = 24045,
  DocStatus = 24051,
  DocSection = 24052,
  ABLClass = 2063,
  WorkPlace = 24047,
  Module = 1390,
  Section = 1391,
  ProjectModule = 24053,
  PrjUpdater = 2071,
  RendBase = 2780,
  PhoneNumber = 2742,
  EMailAddress = 2744,
  CallInfo = 2745,
  HandBook = 2210,
  BaseStgResource = 11000,
  Absence = 2720,
  UserAccount = 2420,
  DataMemo = 2221,
  Picture = 2101,
  Sequence = 2935,
  DayHourCfgCode = 2722,
}

const obj = {
  Street: 5000,
  City: 5001,
  Country: 5005,
  Region: 5006,
  District: 5007,
  Community: 5010,
  PlanStruct: 5009,
};

export enum KLADRLevel {
  Country = obj.Country,
  Region = obj.Region,
  District = obj.District,
  City = obj.City,
  Village = obj.Community,
  Street = obj.Street,
  PreviousLevel = -1,
  House = 1,
  PlanStruct = obj.PlanStruct,
}

export enum DocColumnOption {
  Markable = 1,
  Sortable = 2,
  ReadOnly = 4,
  NotEditing = 8,
  VerticalText = 16,
  ShortObjName = 32,
  Fixed = 64,
  RedNegative = 128,
  HiddenInGrid = 256,
  HiddenInInsp = 512,
  Filterable = 1024,
  Reference = 2048,
  InParams = 4096,
  CanGroup = 8192,
  Invisible = 16384,
  Hidden = 32768,
}

export enum DocFieldType {
  UnitMoney = 0,
  String = 1,
  Integer = 2,
  Float = 3,
  Boolean = 4,
  HandBook = 5,
  IntDate = 6,
  PickList = 7,
  MultiList = 8,
  Note = 9,
  RefString = 10,
  UnitQuantity = 11,
  RefGUID = 12,
  DateTime = 13,
  Picture = 14,
}

// export const DocFieldName = {
//   Title: 'Doc$Title',
//   DSign: 'Doc$DSign',
//   Links: 'Doc$Links',
// }

export enum ShowDoc {
  Modal = 1,
  Single = 2,
  Changed = 4,
  Selecting = 8,
  OnlyDoc = 16,
  LockUsers = 32,
}

export const searchGroupID = 12;

export enum DSignValidateRes {
  SignError = 1,
  TimeError = 2,
  IntfError = 4,
  InitError = 8,
  KeyNotFound = 16,
  InvalidImage = 32,
  UnknownError = 512,
}

export enum DSignOption {
  ArchiveImage = 1,
  XMLImage = 2,
  HasErrors = 4,
  ShowDialog = 1024,
  LockDialog = 2048,
  IgnoreTimeErrors = 4096,
  SimpleDSignature = 8192,
}

export enum Run {
  Wait = 1,
  OutPut = 2,
  Hide = 4,
  Server = 8,
}

export enum TimeType {
  Days = 0,
  Hours = 268435456,
  Units = 536870912,
}

export enum NormMode {
  Days = 1,
  Hours = 2,
  Units = 4,
  MappedDays = 8,
}

export enum RegDataType {
  Compressed = 256,
}

export enum RegEntryType {
  ABLProgID = 7,
  Image = 8,
}

export enum PersonsOption {
  Locked = 1,
  Archived = 2,
}

// export const ArchType = {
//   zip: 'zip',
// }

export enum ProjectTools {
  SetupProjectParams = 101,
}

export enum RegSearch {
  Node = 1,
  Name = 2,
  Text = 4,
}

// export const StdObjDlgType = {
//   Complex: 'complex',
//   Tree: 'tree',
//   Grid: 'grid',
//   TreeGrid: 'treeGrid',
// }

export const imageList = {
  161601: 161601,
  252501: 252501,
};

// export const Dialog = {
//   SplitSize: 'SplitSize',
//   ParamModified: 'ParamModified',
//   SelectedIndex: 'SelectedIndex',
//   RestorePropIndex: 'RestorePropIndex',
//   HelpContext: 'HelpContext',
//   Caption: 'Caption',
//   Pages: 'Pages',
//   PageIndex: 'PageIndex',
//   PageEvent: 'PageEvent',
//   Buttons: 'Buttons',
//   ButtonEvent: 'ButtonEvent',
//   Processor: 'Processor',
// }

// export const Prop = {
//   ID: 'ID',
//   Name: 'Name',
//   ShortName: 'ShortName',
//   EditStyle: 'EditStyle',
//   EditMask: 'EditMask',
//   CLSID: 'CLSID',
//   DataType: 'DataType',
//   ObjRef: 'ObjRef',
//   Value: 'Value',
//   DefaultValue: 'DefaultValue',
//   PickList: 'Values',
//   Data: 'Data',
//   Level: 'Level',
//   ServerID: 'ServerID',
//   CheckState: 'CheckState',
//   EditVal: 'EditVal',
//   OnEdit: 'OnEdit',
//   OnDropDown: 'OnDropDown',
//   OnChange: 'OnChange',
//   OnCloseUp: 'OnCloseUpList',
//   ObjNameFlag: 'ObjNameFlag',
//   EditHints: 'EditHints',
//   NameColor: 'NameColor',
//   NameStyle: 'NameStyle',
//   Options: 'Options',
//   Visible: 'Visible',
//   Locked: 'Locked',
//   ValType: 'ValType',
//   MultiCheckSet: 'MultiCheckSet',
//   Marked: 'Marked',
//   MultiValues: 'MultiValues',
//   Accumulating: 'Accumulating',
//   Disabled: 'Disabled',
//   Initialized: 'Initialized',
//   PageIndex: Dialog.PageIndex,
//   InitialValue: 'InitialValue',
//   OnInitialize: 'OnInitialize',
//   Modified: 'Modified',
//   TextColor: 'TextColor',
//   TextStyle: 'TextStyle',
//   Selection: 'Selection',
//   Conditions: 'Conditions',
//   Condition: 'Condition',
// }

// export const CellHandler = {
//   EditRef: 'EditRef',
//   CloseUp: 'CloseUp',
//   DropDown: 'DropDown',
//   SetText: 'SetText',
//   Sizing: 'Sizing',
//   Delete: 'Delete',
//   Append: 'Append',
// }

// export const Command = {
//   Customize: 'customize',
// }

// export const Request = {
//   Parallel: 'smart',
// }

export enum Mcs {
  Unchecked = 0,
  Checked = 1,
  LeftArrow = 2,
  UpArrow = 3,
  Horizontal = 4,
  Vertical = 5,
  RightArrow = 6,
  DownArrow = 7,
  Bullet = 8,
  Required = 9,
  LeftArrowBlue = 10,
  RightArrowRed = 11,
  Unknown = 12,
  Reject = 13,
}

export enum Rem {
  Month = 0,
  Day = 1,
  Number = 2,
  Source = 3,
  All = 4,
}

export enum PayTypeOption {
  AutoRecalc = 1,
  Nonwritable = 2,
  EndOfBranch = 4,
  Transaction = 8,
  Constant = 16,
  Unused = 32,
  Calculated = 64,
  UserProcessing = 128,
}

export enum PayTypeSet {
  ByPerson = 1,
}

export enum RegEntryDialog {
  RegEntryDialog_SingleFolder = 65536,
  RegEntryDialog_SingleDataType = 131072,
  RegEntryDialog_SingleRegType = 262144,
  RegEntryDialog_ReportView = 524288,
  RegEntryDialog_SaveMode = 1048576,
}

// export const Storage = {
//   ClientFS: 'ClientFS',
//   ServerFS: 'ServerFS',
// }

// export const ChartType = {
//   Lines: 'Lines',
//   Pie: 'Pie',
//   Bar: 'Bar',
// }

export enum NotifyFlag {
  Immediately = 1,
  CanClose = 2,
  Balloon = 4,
  ImdClose = 8,
}

export enum ParamHistory {
  Prev = 1,
  Next = 2,
}

export enum MergeStatus {
  WaitFor = 1,
  Opening = 2,
  Process = 3,
  IsError = 4,
}

export enum RemainsStatus {
  WaitFor = 1,
  Opening = 2,
  Process = 3,
  IsError = 4,
}

// export const Token = {
//   SaveConfigNodes: 'SaveConfigNodes',
//   LoadConfigNodes: 'LoadConfigNodes',
//   LoadForm: 'LoadForm',
//   SaveForm: 'SaveForm',
//   DropForm: 'DropForm',
//   LazyList: 'LazyList',
//   SelectSequence: 'SelectSequence',
//   GetScanStream: 'GetScanStream',
//   SelectEntry: 'SelectEntry',
//   ShellDocPreview: 'ShellDocPreview',
//   CurrentSelection: 'CurrentSelection',
//   ExecuteTCPRequest: 'ExecuteTCPRequest',
//   EnchiferData: 'EnchiferData',
//   DechiferData: 'DechiferData',
//   ScanBarCode: 'ScanBarCode',
//   GetUserRights: 'GetUserRights',
//   SetUserRights: 'SetUserRights',
//   ChangeUserName: 'ChangeUserName',
//   GetModuleTools: 'GetTools',
//   GetExternalValue: 'GetExternalValue',
//   GetSectionList: 'GetSectionList',
//   MoveObjects: 'MoveObjects',
//   ResetLicense: 'ResetLicense',
//   ReopenProject: 'ReopenProject',
//   ConfigRequest: 'ConfigRequest',
//   ResetPWTimes: 'ResetPWTimes',
//   SetSessionInfo: 'SetSessionInfo',
//   ExecABLProgram: 'ExecABLProgram',
//   SetParamProperty: 'SetParamProperty',
//   GetParamValues: 'GetParamValues',
//   ParamCollapsed: 'ParamCollapsed',
//   GetParamDialog: 'GetParamDialog',
//   FixParamHistory: 'FixParamHistory',
//   EditParamProperty: 'EditParamProperty',
//   StopProcess: 'StopProcess',
//   SetParam: 'SetParam',
//   GetParam: 'GetParam',
//   DebugMode: 'DebugMode',
//   DialogButtonClick: 'DialogButtonClick',
//   OpenDebugProgram: 'OpenDebugProgram',
//   LoadABLScript: 'LoadABLScript',
//   SaveABLScript: 'SaveABLScript',
//   CompileABLScript: 'CompileABLScript',
//   GenerateProgramKey: 'GenerateProgramKey',
//   GetABLScriptPrompt: 'GetABLScriptPrompt',
//   RefreshData: 'RefreshData',
//   OpenABLScript: 'OpenABLScript',
//   GetProgramList: 'GetProgramList',
//   ProcessUpdates: 'ProcessUpdates',
//   ChangePropIndex: 'ChangePropIndex',
//   ExecuteParamDialog: 'ExecuteParamDialog',
//   ShowDialogButtons: 'ShowDialogButtons',
//   HideDialogButtons: 'HideDialogButtons',
//   RunNativeProgram: 'RunNativeProgram',
//   FileExists: 'FileExists',
//   CopyFile: 'CopyFile',
//   RenameFile: 'RenameFile',
//   DeleteFile: 'DeleteFile',
//   GetDirectory: 'GetDirectory',
//   SetDirectory: 'SetDirectory',
//   CheckPath: 'CheckPath',
//   RemoveDir: 'RemoveDir',
//   SelectFile: 'SelectFile',
//   InputText: 'InputText',
//   ShowOutPut: 'ShowOutPut',
//   HideOutPut: 'HideOutPut',
//   OutPutText: 'OutPutText',
//   ClearOutPut: 'ClearOutPut',
//   SaveOutPut: 'SaveOutPut',
//   ShowCalculator: 'ShowCalculator',
//   SearchFiles: 'SearchFiles',
//   GetFileSize: 'GetFileSize',
//   GetFileTime: 'GetFileTime',
//   SelectFromList: 'SelectFromList',
//   GetFileAttributes: 'GetFileAttributes',
//   SetFileAttributes: 'SetFileAttributes',
//   SelectListIndex: 'SelectListIndex',
//   ExecuteXMLTree: 'ExecuteXMLTree',
//   ChangeProjectStatus: 'ChangeProjectStatus',
//   ShowDataSet: 'ShowDataSet',
//   ShowProgressDialog: 'ShowProgressDialog',
//   HideProgressDialog: 'HideProgressDialog',
//   StepProgressDialog: 'StepProgress',
//   SetProgressLabel: 'SetProgressLabel',
//   SetProgressTitle: 'SetProgressTitle',
//   SetProgressSection: 'SetProgressSection',
//   ExecuteModalDialog: 'ExecuteModalDialog',
//   ChangeStatusProgress: 'ChangeStatusProgress',
//   CreateOleObject: 'CreateOleObject',
//   InvokeOleObject: 'InvokeOleObject',
//   ReleaseOleObject: 'ReleaseOleObject',
//   GetOleObject: 'GetOleObject',
//   Navigate: 'Navigate',
//   GetSectionDocs: 'GetSectionDocs',
//   GetQueryParams: 'GetQueryParams',
//   GetGridColumns: 'GetGridColumns',
//   HandleDocument: 'HandleDocument',
//   ResetDocument: 'ResetDocument',
//   GetDocumentDetails: 'GetDocumentDetails',
//   GetDocumentLayout: 'GetDocumentLayout',
//   GetToolButtons: 'GetToolButtons',
//   HandleToolButton: 'HandleToolButton',
//   GetDocumentForm: 'GetDocumentForm',
//   GetImageHint: 'GetImageHint',
//   GetStatusList: 'GetStatusList',
//   ChangeStatus: 'ChangeStatus',
//   GetColumnHint: 'GetColumnHint',
//   EditColumnField: 'EditColumnField',
//   GetColumnValues: 'GetColumnValues',
//   CloseUpList: 'CloseUpList',
//   GetMenuState: 'GetMenuState',
//   GetDocSignImage: 'GetDocSignImage',
//   SetActiveSite: 'SetActiveSite',
//   AttachFiles: 'AttachFiles',
//   GetColumnDetails: 'GetColumnDetails',
//   SetDetailValue: 'SetDetailValue',
//   GetColumnParams: 'GetColumnParams',
//   SaveDocumentState: 'SaveDocumentState',
//   GetDocFieldText: 'GetDocFieldText',
//   SetDocFieldText: 'SetDocFieldText',
//   SetTreeGroup: 'SetTreeGroup',
//   CreateRecordCopy: 'CreateRecordCopy',
//   GetDocTitles: 'GetDocTitles',
//   ExecDetailHandler: 'ExecDetailHandler',
//   GetColumnStyle: 'GetColumnStyle',
//   HandleSamples: 'HandleSamples',
//   ApplyFilter: 'ApplyFilter',
//   HandlePage: 'HandlePage',
//   InitContext: 'InitContext',
//   ScrollToDocument: 'ScrollToDocument',
//   SelectDocument: 'SelectDocument',
//   SelectDocBook: 'SelectDocBook',
//   EditDocumentField: 'EditDocumentField',
//   UpdateSiteLayout: 'UpdateSiteLayout',
//   GetMasterDoc: 'GetMasterDoc',
//   ExposeDocument: 'ExposeDocument',
//   RefreshDocument: 'RefreshDocument',
//   CertificateInfo: 'GetCertificateInfo',
//   CertificateList: 'GetCertificateList',
//   SelectCertificate: 'SelectCertificate',
//   CreateSign: 'CreateSign',
//   VerifySign: 'VerifySign',
//   SignXML: 'SignXML',
//   SaveSign: 'SaveSign',
//   CertificateChain: 'GetCertificateChain',
//   GetObjectParams: 'GetObjectParams',
//   GetRangeSourceList: 'GetRangeSourceList',
//   GetABLObjectList: 'GetABLObjectList',
//   GetABLObjectInfo: 'GetABLObjectInfo',
//   TypeListChanged: 'TypeListChanged',
//   GetDateRange: 'GetDateRange',
//   SetDateRange: 'SetDateRange',
//   GetMemoText: 'GetMemoText',
//   SetMemoText: 'SetMemoText',
//   SelectFolder: 'SelectFolder',
//   SelectDates: 'SelectDates',
//   SelectDate: 'SelectDate',
//   SelectMonthRange: 'SelectMonthRange',
//   SelectRange: 'SelectRange',
//   ShellExecute: 'ShellExecute',
//   MessageBeep: 'MessageBeep',
//   GetClipboardText: 'GetClipboardText',
//   SetClipboardText: 'SetClipboardText',
//   GetClipboardFiles: 'GetClipboardFiles',
//   ClipboardAvailable: 'ClipboardAvailable',
//   BalloonMessage: 'BalloonMessage',
//   GetImage: 'GetImage',
//   DeleteImage: 'DeleteImage',
//   InsertImage: 'InsertImage',
//   FindImage: 'FindImage',
//   LoadImage: 'LoadImage',
//   CountImage: 'CountImage',
//   GetReportParams: 'GetReportParams',
//   ExecuteReport: 'ExecuteReport',
//   GetReportSettings: 'GetReportSettings',
//   CellDocToExcel: 'CellDocToExcel',
//   QueryDocPrint: 'QueryDocPrint',
//   CheckPrintedDoc: 'CheckPrintedDoc',
//   GetDocumentMenu: 'GetDocumentMenu',
//   CallCellHandler: 'CallCellHandler',
//   ExecMenuHandler: 'ExecMenuHandler',
//   GetRepDocContent: 'GetRepDocContent',
//   PrintDocument: 'PrintDocument',
//   PreviewDocument: 'PriviewDocument',
//   PreliminatyDoc: 'PreliminaryDoc',
//   EditDocument: 'EditDocument',
//   SaveDocPictures: 'SavDocPictures',
//   GetPageContent: 'GetPageContent',
//   PageChanged: 'PageChanged',
//   FixupTabPage: 'FixupTabPage',
//   CloseTabPage: 'CloseTabPage',
//   GetTransFilterParams: 'GetTransFilterParams',
//   ApplyTransFilter: 'ApplyTransFilter',
//   HandlePlanTable: 'HandlePlanTable',
//   HandleAnalyticTable: 'HandleAnalyticTable',
//   GetInitialInfo: 'GetInitialInfo',
//   GetAccPlans: 'GetAccPlans',
//   SetAccPlan: 'SetAccPlan',
//   GetFieldValues: 'GetFieldValues',
//   HandleRemains: 'HandleRemains',
//   EditTransactions: 'EditTransactions',
//   DeleteSequence: 'DeleteSequence',
//   SetSequenceParams: 'SequenceSetParams',
//   GetSequenceParams: 'SequenceGetParams',
//   GetNextNumber: 'GetNextNumber',
//   GetObjectSigns: 'GetObjectSigns',
//   DeleteSign: 'DeleteSign',
//   GetSignOptions: 'GetSignOptions',
//   GetSignStream: 'GetSignStream',
//   ServerSignStream: 'ServerSignStream',
//   ClientSignStream: 'ClientSignStream',
//   ServerValidateStream: 'ServerValidateStream',
//   ClientValidateStream: 'ClientValidateStream',
//   SignDialog: 'SignDialog',
//   GetSignDialogOptions: 'GetSignDialogOptions',
//   SetSignDialogOptions: 'SetSignDialogOptions',
//   GetObjectsDifferences: 'GetObjectsDifferences',
//   GetAuditOptions: 'GetAuditOptions',
//   SetAuditOptions: 'SetAuditOptions',
//   ApplyAuditParams: 'ApplyAuditParams',
//   GetAuditParamValues: 'GetAuditParamValues',
//   HandleNotifications: 'HandleNotifications',
//   CheckCryptoLibs: 'CheckCryptoLibs',
//   SetAddress: 'SetAddress',
//   AddressInfo: 'GetAddressInfo',
//   SelectAddress: 'SelectAddress',
//   AddressUseKladr: 'GetAddressUseKladr',
//   AddressEditKladrObj: 'AddressEditKladrObj',
//   SetFilter: 'SetFilter',
//   SelectKLADRObject: 'SelectKLADRObject',
//   AssignAddress: 'AssignAddress',
//   GetSearchParams: 'GetSearchParams',
//   InitSearch: 'InitSearch',
//   StopSearch: 'StopSearch',
//   GetBookContextMenu: 'GetBookContextMenu',
//   BookContextMenuHandler: 'BookContextMenuHandler',
//   GetDepNameHistory: 'GetDepNameHistory',
//   SetDepNameHistory: 'SetDepNameHistory',
//   GetDepUsers: 'GetDepUsers',
//   LinkUserToDep: 'LinkUserToDep',
//   GetParamSection: 'GetParamSection',
//   HandleFavorites: 'HandleFavorites',
//   GetMembers: 'GetMembers',
//   GetSectionTools: 'GetSectionTools',
//   ExecToolProgram: 'ExecToolProgram',
//   SetupProjectParams: 'SetupProjectParams',
//   GetWorkPlaceTools: 'GetWorkPlaceTools',
//   GetHTMLHelpPage: 'GetHTMLHelpPage',
//   GetSectionBitmap: 'GetSectionBitmap',
//   SetSectionBitmap: 'SetSectionBitmap',
//   GetWPFieldValues: 'GetWPFieldValues',
//   GetDBVersions: 'GetDBVersions',
//   SendToSupport: 'SendToSupport',
//   GetSupportInfo: 'GetSupportInfo',
//   CloseSupportReq: 'CloseSupportReq',
//   SetOrgTypeCls: 'SetOrgTypeCls',
//   RestoreClsCode: 'RestoreClsCode',
//   GetClsParams: 'GetClsParams',
//   GetConfigItems: 'GetConfigItems',
//   ChangeConfigItem: 'ChangeConfigItem',
//   ReopenClassifier: 'ReopenClassifier',
//   ShowOnlyActual: 'ShowOnlyActual',
//   GetRegistryUsers: 'GetRegistryUsers',
//   DeleteRegUserInfo: 'DeleteRegUserInfo',
//   ReloadRegistry: 'ReloadRegistry',
//   SetUserName: 'SetUserName',
//   ExportRegistry: 'ExportRegistry',
//   ImportRegistry: 'ImportRegistry',
//   GetRegistryInfo: 'GetRegistryInfo',
//   SetRegistryInfo: 'SetRegistryInfo',
//   SearchText: 'SearchText',
//   GetPayTypeSets: 'GetPayTypeSets',
//   RestorePayType: 'RestorePayType',
//   GetPayTypeParams: 'GetPayTypeParams',
//   GetPayTypeLinks: 'GetPayTypeLinks',
//   SetPayTypeFilter: 'SetPayTypeFilter',
//   SearchPayTypes: 'SearchPayTypes',
//   SelectLinks: 'SelectLinks',
//   GetPersonBlocks: 'GetPersonBlocks',
//   GetPersonPhoto: 'GetPersonPhoto',
//   SetPersonPhoto: 'SetPersonPhoto',
//   PersonTabChange: 'PersonTabChange',
//   PersonAddrDelete: 'PersonAddrDelete',
//   PersonAddrMain: 'PersonAddrMain',
//   GetPersonAddr: 'GetPersonAddr',
//   HandleWorkRanges: 'HandleWorkRanges',
//   GetWorkRangesLayout: 'GetWorkRangesLayout',
//   SetWorkRanges: 'SetWorkRanges',
//   SearchPerson: 'SearchPerson',
//   GetPersonGroup: 'GetPersonGroup',
//   SetCurrentPerson: 'SetCurrentPerson',
//   SetOptions: 'SetOptions',
//   SetShowArchive: 'SetShowArchive',
//   ChangeSelection: 'ChangeSelection',
//   ResetRenderings: 'ResetRenderings',
//   ResetValues: 'ResetValues',
//   SetRendSelection: 'SetRendSelection',
//   CreateRange: 'CreateRange',
//   ActivateRange: 'ActivateRange',
//   RefreshRange: 'RefreshRange',
//   ClearRange: 'ClearRange',
//   DeleteRange: 'DeleteRange',
//   SetCloseDate: 'SetCloseDate',
//   ResetSelection: 'ResetSelection',
//   GetRangeList: 'GetRangeList',
//   SetCurrentRendering: 'SetCurrentRendering',
//   GetRangeInfo: 'GetRangeInfo',
//   GetRangeState: 'GetRangeState',
//   CheckParamRange: 'CheckParamRange',
//   SpecialSelection: 'SpecialSelection',
//   RefreshAmounts: 'RefreshAmounts',
//   SelectMonth: 'SelectMonth',
//   AccCorrRestore: 'RestoreAccCorr',
//   GetFilterParams: 'GetFilterParams',
//   ApplyCorrFilter: 'ApplyCorrFilter',
//   GetFiltered: 'GetFiltered',
//   ResetFilter: 'ResetFilter',
//   GetGroupParams: 'GetGroupParams',
//   SetGroupParams: 'SetGroupParams',
//   HandleParams: 'HandleParams',
//   TabChange: 'TabChange',
//   GenerateObjects: 'GenerateObjects',
//   SetInvNumber: 'SetInvNumber',
//   FindInvNumber: 'FindInvNumber',
//   GetAbsenceList: 'GetAbsenceList',
//   GetTimeCfgList: 'GetTimeCfgList',
//   RestoreTimeCfg: 'RestoreTimeCfg',
//   ChangeRendTime: 'ChangeRendTime',
//   FixupHourType: 'FixupHourType',
//   SetupDeclinations: 'SetupDeclinations',
//   CloseRange: 'CloseRange',
//   SetParentTimes: 'SetParentTimes',
//   SelectLinkedLeaves: 'SelectLinkedLeaves',
//   GetWorkModeItems: 'GetWorkModeItems',
//   GetMonthCalendar: 'GetMonthCalendar',
//   SetDayParams: 'SetDayParams',
//   GetNormValues: 'GetNormValues',
//   GetDayRange: 'GetDayRange',
//   SetDayRange: 'SetDayRange',
//   GetWorkModeParams: 'GetWorkModeParams',
//   SetWorkModeValues: 'SetWorkModeValues',
//   SetWorkModeNorm: 'ChangeWorkModeNorm',
//   SetupWorkMode: 'SetupWorkMode',
//   GetMainOrg: 'GetMainOrg',
//   FindDuplicates: 'FindDuplicates',
//   JoinDuplicates: 'JoinDuplicates',
//   JoinChangeOrg: 'JoinChangeOrg',
//   HandleJoined: 'HandleJoined',
//   HandleAccounts: 'HandleAccounts',
//   CheckAll: 'CheckAll',
//   GetPersonChildren: 'GetPersonChildren',
//   GetRendHistoryList: 'GetRendHistoryList',
//   RendHistoryBeforeOperation: 'RendHistoryBeforeOperation',
//   GetAppointmentsStringList: 'GetAppointmentsStringList',
//   GetJobHistory: 'GetJobHistory',
//   GetRenderingsList: 'GetRenderingsList',
//   GetStaffParams: 'GetStaffParams',
//   GetJobModes: 'GetJobModes',
//   GetRendHistoryParams: 'GetRendHistoryParams',
//   GetPriorHistory: 'GetPriorHistory',
//   ChangeRendering: 'ChangeRendering',
//   CanDeleteAppointment: 'CanDeleteAppointment',
//   GetStaffSettings: 'GetStaffSettings',
//   GetRenderingStaff: 'GetRenderingStaff',
//   GetNewRenderingID: 'GetNewRenderingID',
//   GetRenderingOnDateParams: 'GetRenderingOnDateParams',
//   GetStaffObjectParams: 'GetStaffObjectParams',
//   SetStaffObjectValues: 'SetStaffObjectValues',
//   MoveRendHistories: 'MoveRendHistories',
//   GetAmountModesList: 'GetAmountModesList',
//   GetCodesList: 'GetCodesList',
//   GetRendBaseList: 'GetRendBaseList',
//   CheckBaseFieldValue: 'CheckBaseFieldValue',
//   BaseDelete: 'BaseDelete',
//   GetRendBaseValuesList: 'GetRendBaseValuesList',
//   CodeDelete: 'CodeDelete',
//   CheckCodeFieldValue: 'CheckCodeFieldValue',
//   GetNewAmountDate: 'GetNewAmountDate',
//   GetPayTypeLink: 'GetPayTypeLink',
//   GetRendBaseSums: 'GetRendBaseSums',
//   SetRendBaseSums: 'SetRendBaseSums',
//   ChangeAmountLink: 'ChangeAmountLink',
//   GetAmountDocTypes: 'GetAmountDocTypes',
//   ChangeAmountParam: 'ChangeAmountParam',
//   ValuesBeforeOperation: 'ValuesBeforeOperation',
//   GetTarificationParams: 'GetTarificationParams',
//   GetDocFieldTypes: 'GetDocFieldTypes',
//   GetDocFieldTypeModules: 'GetDocFieldTypeModules',
//   GetDocCfgParams: 'GetDocCfgParams',
//   GetDocInitialScript: 'GetDocInitialScript',
//   SetDocCfgProperty: 'SetDocCfgProperty',
//   GetDocCfgParamValues: 'GetDocCfgParamValues',
//   AddDocColumn: 'AddDocColumn',
//   DelDocColumn: 'DelDocColumn',
//   AddDocSection: 'AddDocSection',
//   DelDocSection: 'DelDocSection',
//   GetDocObjs: 'GetDocObjs',
//   GetGradeScope: 'GetGradeScope',
//   ChangeGradeValue: 'ChangeGradeValue',
//   GetGradeParams: 'GetGradeParams',
//   SetGradeParams: 'SetGradeParams',
//   GetParamDataTypes: 'GetParamDataTypes',
//   GetParamModules: 'GetParamModules',
//   ResetParams: 'ResetParams',
//   SetParamValues: 'SetParamValues',
//   GetAttrValues: 'GetAttrValues',
//   GetParamIdentifiers: 'GetParamIdentifiers',
//   HandleSQLScript: 'HandleSQLScript',
//   SetScriptType: 'SetScriptType',
//   SetConnectionNo: 'SetConnectionNo',
//   GetConnectionList: 'GetConnectionList',
//   GenerateSQL: 'GenerateSQL',
//   ExecuteSQL: 'ExecuteSQL',
//   SaveTable: 'SaveTable',
//   LoadTable: 'LoadTable',
//   GetStorageItems: 'GetStorageItems',
//   ChangeStorageItem: 'ChangeStorageItem',
//   GetObjTypeItems: 'GetObjTypeItems',
//   GetObjUpdateItems: 'GetObjUpdateItems',
//   GetSourceList: 'GetSourceList',
//   GetSourceInfo: 'GetSourceInfo',
//   GetFieldProps: 'GetFieldProps',
//   SaveUserReport: 'SaveUserReport',
//   LoadUserReport: 'LoadUserReport',
//   GetFieldTypes: 'GetFieldTypes',
//   GetReportDesc: 'GetReportDesc',
//   GetUserReportSettings: 'GetUserReportSettings',
//   GetUsRepTemplateList: 'GetUsRepTemplateList',
//   DeleteUserReportSettings: 'DeleteUserReportSettings',
//   ChangeReportGroup: 'ChangeReportGroup',
//   HasReportGroup: 'HasReportGroup',
//   InsertGroup: 'InsertGroup',
//   ModifyGroup: 'ModifyGroup',
//   DeleteGroup: 'DeleteGroup',
//   GetGroupParentTypes: 'GetGroupParentTypes',
//   GetGroupObjValues: 'GetGroupObjValues',
//   GetNotes: 'GetNotes',
//   SetNotes: 'SetNotes',
//   BuildPivotTable: 'BuildPivotTable',
//   MakePhoneCall: 'MakePhoneCall',
//   CreateMailItem: 'CreateMailItem',
//   RegisterCall: 'RegisterCall',
//   GetUserNumber: 'GetUserNumber',
//   GetCallDocInfo: 'GetDocCallInfo',
//   SetCallDocInfo: 'SetDocCallInfo',
//   ChangeFavourite: 'ChangeFavourite',
//   GetUserLinkSite: 'GetUserLinkSite',
//   ShowMailLogin: 'ShowMailLogin',
//   ChangeGroup: 'ChangeGroup',
//   StartMerging: 'StartMerging',
//   StopMerging: 'StopMerging',
//   GetMergeInfo: 'GetMergeInfo',
//   GetMergeLog: 'GetMergeLog',
//   GetUpdatesList: 'GetUpdatesList',
//   GetUpdatesFile: 'GetUpdatesFile',
//   GetUpdatesHelp: 'GetUpdatesHelp',
//   PlaceEvent: 'PlaceEvent',
//   GetEvents: 'GetEvents',
//   ResetObjects: 'ResetObjects',
//   SearchObjects: 'SearchObjects',
//   GetSerialList: 'GetSerialList',
//   GetRenderings: 'GetRenderings',
//   PrepareRecycle: 'PrepareRecycle',
//   RestoreObjects: 'RestoreObjects',
//   EmptyRecycle: 'EmptyRecycle',
//   GetSectionForm: 'GetSectionForm',
//   GetInitialScript: 'GetInitialScript',
//   ElementEvent: 'ElementEvent',
//   CloseQuery: 'CloseQuery',
//   GetUpdateSectionForm: 'GetUpdateSectionForm',
//   ClearFormOnSection: 'ClearFormOnSection',
//   CommitFormOnSection: 'CommitFormOnSection',
//   GetRemainsInfo: 'GetRemainsInfo',
//   StartRemains: 'StartRemains',
//   StopRemains: 'StopRemains',
//   TicketState: 'TicketState',
//   ExitProcess: 'ExitProcess',
//   GetWebURL: 'GetWebURL',
// }

// export const Module = {
//   Client: 'Client',
//   Project: 'project',
//   Programs: 'programs',
//   Documents: 'documents',
//   Sign: 'sign',
//   ABLUtils: 'ABLUtils',
//   Resources: 'resources',
//   Reports: 'reports',
//   CellObj: 'cellobj',
//   RepTabs: 'reptabs',
//   Account: 'accounts',
//   Sequence: 'sequence',
//   Audit: 'audit',
//   Address: 'address',
//   KLADR: 'kladr',
//   Departments: 'departments',
//   Pages: 'pages',
//   Tools: 'tools',
//   Config: 'config',
//   ClsBook: 'clsbook',
//   Registry: 'registry',
//   CfgRegs: 'cfgregs',
//   LocRegs: 'locregs',
//   PayTypes: 'paytypes',
//   Persons: 'persons',
//   Salary: 'salary',
//   AccTools: 'acctools',
//   StorObj: 'storobj',
//   Tables: 'tables',
//   Schedule: 'schedule',
//   Organizations: 'organizations',
//   Tariff: 'tariff',
//   Books: 'books',
//   Cadres: 'cadres',
//   Staff: 'staff',
//   Amounts: 'amounts',
//   DocCfg: 'doccfg',
//   Grades: 'grades',
//   Params: 'params',
//   DBView: 'dbview',
//   StmPort: 'stmport',
//   ObjLinks: 'objlinks',
//   UserRep: 'userrep',
//   Groups: 'groups',
//   Charts: 'charts',
//   Cubes: 'cubes',
//   DataBase: 'database',
//   Office: 'office',
//   Mail: 'mail',
//   Hierarchy: 'hierarchy',
//   Merging: 'merging',
//   FastRep: 'fastrep',
//   Updates: 'updates',
//   Monitor: 'monitor',
//   Storage: 'storage',
//   Recycle: 'recycle',
//   Forms: 'forms',
//   Crypto: 'crypto',
//   Remains: 'remains',
//   Queue: 'queue',
//   WebSite: 'website',
// }

// export const TransactionFilterPath = 'TransactionFilter';

// export const FavMode = {
//   AddFav: 'AddFav',
//   DelFav: 'DelFav',
//   AddGroup: 'AddGroup',
//   DelGroup: 'DelGroup',
//   GetGroups: 'GetGroups',
// }

export enum ClsMode {
  Base = 0,
  BaseConfig = 2,
  Config = 3,
}

// export const AccCorsFilterPath = 'AccCorsFilterPath';

/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////Конфиги/////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
export const pluginLinks = {
  chrome: {
    store:
      "https://chromewebstore.google.com/detail/%D0%BA%D1%80%D0%B8%D0%BF%D1%82%D0%BE-%D0%BF%D0%BB%D0%B0%D0%B3%D0%B8%D0%BD-%D0%B0%D1%81-%D1%81%D0%BC%D0%B5%D1%82%D0%B0/lbabfglcibjnhihfgjkdefgjmlmieeal?hl=ru",
    local: "http://stimate.krista.ru/webkcrypt/chrome/webkcrypt.crx",
  },
  firefox: {
    local: "http://stimate.krista.ru/webkcrypt/mozilla/webkcrypt.xpi",
  },
  workspace: {
    windows: "http://stimate.krista.ru/webkcrypt/WebKCryptSetup.exe",
    debian: "http://stimate.krista.ru/webkcrypt/webkcrypt.deb",
    rpm: "http://stimate.krista.ru/webkcrypt/webkcrypt-1.3.180713-2.x86_64.rpm",
  },
};

export const configs: { [key: string]: { smeta: ConfigSmeta; lk: ConfigLk } } = {
  configStimate: {
    smeta: {
      basename: "",
      signOptions: {
        signButtons: {
          signCryptoButton: false,
          lkButton: false,
          helpKristaButton: true,
        },
        headerSettings: {
          image: true,
          imageWidth: "",
          title: "Web-Смета",
        },
      },
      pluginLinks: pluginLinks,
    },
    lk: {
      checkNotifyme: false,
      baseCaptcha: "1",
      basePassRecovery: "0",
      baseUmi: "0",
      baseLinkText: "Забыли пароль? Пройдите повторную регистрацию",
      hideEsiaButton: "0",
      showMobileQR: "1",
      doEsia: {
        auth: "https://stim-srv.krista.ru/esia",
        logout: "https://stim-srv.krista.ru/esia?what=esia_logout",
        org: "https://stim-srv.krista.ru/esia?what=esia_org",
      },
      url: {
        captcha: "http://stimate.krista.ru:7777/web~captcha",
        signIn: "http://stimate.krista.ru:7777/web~account?single=1",
        signUP: "http://stimate.krista.ru:7777/web~registration/create",
        org: "http://stimate.krista.ru:7777/web~registration/values?type=inn",
        fio: "http://stimate.krista.ru:7777/web~registration/values?type=snils",
        changePassword: "http://stimate.krista.ru:7777/web~ChangePasswordLK",
        restorePassword: "http://stimate.krista.ru:7777/web~RestorePasswordLK",
      },
    },
  },
  configStimSrv: {
    smeta: {
      pluginLinks: pluginLinks,
      basename: "/web2v",
      signOptions: {
        signButtons: {
          signCryptoButton: false,
          lkButton: true,
          helpKristaButton: true,
        },
        headerSettings: {
          image: true,
          imageWidth: "",
          title: "Web-Смета",
        },
      },
    },
    lk: {
      checkNotifyme: false,
      baseCaptcha: "1",
      basePassRecovery: "0",
      baseSnilsOrFio: undefined,
      baseUmi: "0",
      baseLinkText: "Забыли пароль? Пройдите повторную регистрацию",
      hideEsiaButton: "0",
      doEsia: {
        auth: "https://stim-srv.krista.ru/esia",
        logout: "https://stim-srv.krista.ru/esia?what=esia_logout",
        org: "https://stim-srv.krista.ru/esia?what=esia_org",
      },
      url: {
        captcha: "https://stim-srv.krista.ru/web~captcha",
        signIn: "https://stim-srv.krista.ru/web~account?single=1",
        signUP: "https://stim-srv.krista.ru/web~registration/create",
        org: "https://stim-srv.krista.ru/web~registration/values?type=inn",
        fio: "https://stim-srv.krista.ru/web~registration/values?type=snils",
        changePassword: "https://stim-srv.krista.ru/web~ChangePasswordLK",
        restorePassword: "https://stim-srv.krista.ru/web~RestorePasswordLK",
      },
      showMobileQR: "1",
    },
  },
};
