import { AxiosRequest } from "../../../../Url";
import BackupTableIcon from "@mui/icons-material/BackupTable";

export async function getImageHint(doccfgId: string, index: string, docId: string) {
  let json;
  const params = new Map();
  params
    .set("prefix", "documents")
    .set("comand", "GetImageHint")
    .set("DocCfgID", doccfgId)
    .set("ImageIndex", index)
    .set("DocID", docId)
    .set("smart", "1");
  json = await AxiosRequest(true, params);
  return json.Text;
}

export function saveDocumentState(doccfgId: string, id: string, state: unknown, activeDetails: number) {
  if (state) {
    const params = new Map();
    params
      .set("prefix", "documents")
      .set("comand", "SaveDocumentState")
      .set("DocCfgID", doccfgId)
      .set("SectionID", id)
      .set("ActiveDetail", activeDetails ? activeDetails + 1 : 0);
    AxiosRequest(true, params, { GridState: state });
  }
}

export function createGridStateString(gridState: string) {
  if (gridState) {
    let FinalStr = "";
    let valueForString;
    for (const [key, value] of Object.entries(gridState)) {
      if (value) {
        if (typeof value === "object") {
          valueForString = `${key}=`;
          for (const [keyPoint, data] of Object.entries(value)) {
            if (keyPoint === "9") {
              valueForString += `${data}`;
            } else {
              valueForString += `${data}•`;
            }
          }
        } else {
          valueForString = `${key}=${value}`;
        }
        FinalStr += valueForString + "\r\n";
      }
    }
    //
    return FinalStr;
  }
}

export function parseGridState(gridState: any) {
  if (gridState) {
    let arr = gridState.split("\r\n");
    let obj = {};
    if (arr.length > 0) {
      let valueForObj;
      for (const value of Object.values(arr)) {
        if (value !== "") {
          const valueArr = value.split("=");
          if (valueArr[1] && valueArr[1].includes("•")) {
            valueForObj = Object.assign({}, valueArr[1].split("•"));
          } else {
            valueForObj = valueArr[1];
          }
          Object.assign(obj, { [valueArr[0]]: valueForObj });
        }
      }
      return obj;
    }
  }
}
