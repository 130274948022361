import { Button } from "@mui/material";
import cn from "classnames";
import { useAtomValue } from "jotai";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { configEnabledAtom } from "../../../../atoms";
import { tokenProcessingTest } from "../../../TokenProcessing/TokenProcessing";
import { AxiosRequest } from "../../../Url";
import { Button as DlgButton, DlgRes, DlgType } from "../../Tools/ProjectX";
import { getDateOfInt, getStyleWindow } from "../../Tools/Tools";
import { closeModal, openModal } from "../../Tools/modalManager";
import { GridLoader } from "../../rcsgrid/createGridTools";
import { createGrid, createRecordSource } from "../../rcsgrid/rcsgrid";
import MessageBox from "../MessageBox";
import CheckBoxWithLabel from "./Components/CheckBoxWithLabel";
import NotesButton from "./Components/dialogInstallScriptsUpdates/NotesButton";
import {
  getColumnsDlgInstScrptsUpdt,
  getDefaultFieldsDlgInstScrptsUpdt,
} from "./Components/dialogInstallScriptsUpdates/dialogInstallScriptsUpdatesTools";
import styles from "./Components/styles.module.css";

const getUpdatesList = () => {
  const params = new Map([
    ["prefix", "updates"],
    ["comand", "GetUpdatesList"],
    ["Client", "1"],
  ]);
  return AxiosRequest(true, params);
};

const getUpdatesFile = (id: string) => {
  const params = new Map([
    ["prefix", "updates"],
    ["comand", "GetUpdatesFile"],
    ["Client", "1"],
    ["ID", id],
    ["WSM", "1"],
  ]);
  return AxiosRequest(true, params);
};

interface GridObj {
  grid: createGrid;
  source: createRecordSource;
}

interface Props {
  props: {
    Buttons?: number | string;
  };
}

interface Data {
  AlfaTesting?: string;
  BetaTesting?: string;
  ConfigVersion?: string;
  SupLicGUID?: string;
  items: { Версия?: string; items: [] };
}

export interface CheckData {
  CheckState: string;
}

interface Item {
  ID: string;
  Name: string;
  Date: string;
  Version: string;
  Status: string;
  Notes: string;
  Help: string;
  GUID: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function DialogInstallScriptsUpdates({ props }: Props) {
  const configEnable = useAtomValue(configEnabledAtom);
  const [disableInstallButton, setDisableInstallButton] = useState(!configEnable);
  const { Buttons } = props;

  const [mainGrid, setMainGrid] = useState<GridObj | undefined>();
  const [selectedRecordId, setSelectedRecordId] = useState("0");

  const [allUpdts, setAllUpdts] = useState("0");

  const gridPanel = useRef<HTMLDivElement | null>(null);

  const records = useRef<Array<Item>>([]);

  //   const selections = useRef<Array<string>>([]);
  //   const nextStep = useRef<number>(-1);

  const data = useRef<Data>();

  useEffect(() => {
    loadGrid();
  }, []);

  const loadGrid = async () => {
    const json = await getUpdatesList();
    data.current = json;
    if (!data.current || !data.current.items || !data.current.items.items) data.current = { items: { items: [] } };

    records.current =
      allUpdts === "1"
        ? (data.current && data.current.items.items) || []
        : (data.current && data.current.items.items.filter(filterRecords)) || [];
    setDisableInstallButton(records.current.length !== 0 ? !configEnable : true);
    const res = await GridLoader(gridPanel.current, props, {
      localGrid: true,
      refresh: false,
      Columns: getColumnsDlgInstScrptsUpdt(),
      defaultFields: getDefaultFieldsDlgInstScrptsUpdt(),
      keyField: "ID",
      recordKeys: ["ID", "Name", "Date", "Version", "Status", "Notes", "Help", "GUID"],
      getRecords: getRecords,
      Rights: "0",
      setSelectedRecord: setSelectedRecordId,
    });
    setMainGrid(res);
  };

  const getRecords = () => {
    return getFixRecords(records.current);
  };

  const getFixRecords = (records: Array<Item>) => {
    return records.map((item: Item) => {
      const fullDate = getDateOfInt(item.Date);
      return { ...item, Date: fullDate };
    });
  };

  const getLabelForRef = () => {
    if (!mainGrid) return "";
    return mainGrid.source.getFieldTextSync("Name");
  };

  const filterRecords = (record: Item) => {
    if (!record.Status) return true;
    if (!record.Status.match(/\[вер.[\d]\]/)) return false;

    const text = new RegExp(`вер.${record.Version}`);
    if (record.Status.match(text)) return false;

    return true;
  };

  const onCheck = async (checkData: CheckData) => {
    setAllUpdts(checkData.CheckState);
    records.current =
      checkData.CheckState === "1"
        ? (data.current && data.current.items.items) || []
        : records.current.filter(filterRecords);
    setDisableInstallButton(records.current.length !== 0 ? !configEnable : true);
    setTimeout(async () => {
      await mainGrid?.grid.refreshSource();
      setSelectedRecordId(mainGrid?.source.getFieldTextSync("ID"));
    });
  };

  const updateStatus = () => {
    if (mainGrid?.source.activeRecord === undefined) return;
    records.current[mainGrid?.source.activeRecord].Status = "Установлено";
    mainGrid?.grid.refreshSource();
  };

  const installUpdt = async (id: string | undefined) => {
    if (!id) return;
    const json = await getUpdatesFile(id);
    if (json.Token) {
      tokenProcessingTest(json, {
        func: (data: { ID: string }) => {
          const reg = new RegExp(`${id}`);
          if (data.ID.match(reg)) {
            updateStatus();
          }
        },
      });
    } else {
      updateStatus();
    }
  };

  const createConfirmation = (caption: string, onSelect: (id?: string) => Promise<void>, id?: string) => {
    openModal(
      <MessageBox
        DlgType={DlgType.Confirmation}
        Buttons={DlgButton.Yes + DlgButton.No}
        Message={caption}
        onSelect={(ev: MouseEvent) => {
          if ((ev.target as HTMLButtonElement).value === DlgRes.Yes.toString()) onSelect(id);
        }}
      />,
      {
        style: getStyleWindow("MessageBox"),
        hiddenButton: true,
        blockMaximize: true,
        blockSaveLocation: true,
        blockResize: true,
        Title: "Подтверждение",
      },
    );
  };

  //   const multiSelSelect = async () => {
  //     const data = await CheckMultiSelect(mainGrid?.grid, "ID", ";");
  //     nextStep.current = 1;
  //     selections.current = data.split(";");
  //   };

  const onSelect = async () => {
    if (true) {
      const id = await mainGrid?.source.getFieldText("ID"),
        status = await mainGrid?.source.getFieldText("Status");
      createConfirmation(`Установить обновление "${getLabelForRef()}" ${status ? "повторно" : ""}?`, installUpdt, id);
    } else {
      // В разработке
      //   createConfirmation(
      //     `Установить выделенные обновления ${mainGrid?.grid.getMultiSelect().length}шт. ?`,
      //     multiSelSelect,
      //   );
    }
  };

  return (
    <div className={styles.mainDialogBlock}>
      <div className={`${styles.fullDialogFlexContainer} ${styles.dialogDayTypeContainer}`}>
        <div ref={gridPanel} style={{ height: "100%", width: "100%", position: "relative" }}></div>
      </div>

      <div className={styles.dialogButtonsContainer}>
        <div className={styles.dialogFutterInfo}>
          <CheckBoxWithLabel label="Все обновления" onEdit={onCheck} CheckState={allUpdts} />
          <NotesButton id={selectedRecordId} label={getLabelForRef()} />
        </div>
        <Button
          onClick={onSelect}
          size="small"
          variant="outlined"
          className={`${styles.dialogButton} button_Modal_Select ${cn(
            Buttons && (Number(Buttons) & 1) === 0 && "hidden",
          )}`}
          value="1"
          disabled={disableInstallButton}
        >
          Установить
        </Button>

        <Button
          size="small"
          variant="outlined"
          value="2"
          className={`${styles.dialogButton} button_Modal_Close`}
          onClick={() => {
            closeModal();
          }}
        >
          Закрыть
        </Button>
      </div>
    </div>
  );
}
