import { IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
export default function PickListEditor(props) {
  return (
    <IconButton
      ref={props.buttonRef}
      disabled={props.disabled}
      aria-describedby={props.id}
      key={`picklist_${props.itemId}`}
      className="list-button"
      style={{
        width: "20px",
        height: "100%",
        borderRadius: "0px",
        padding: "0",
        display: props.EditStyle_PickList ? "" : "none",
      }}
      onClick={(ev) => {
        if (props.disabled) return;
        props.onPickListClick(ev);
      }}
    >
      <ExpandMoreIcon style={{ maxHeight: "20px" }} />
    </IconButton>
  );
}
