import { Box, Button, Grid, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { RenderMessageBox } from "../../../../TokenProcessing/TokenProcessing";
import { AxiosRequest } from "../../../../Url";
import { closeModal } from "../../../Tools/modalManager";
import FileUploadList from "./FileUploadList";
import { getAttachedFiles } from "./SupportRequestConst";
import React from "react";

interface FormValues {
  surname: string;
  name: string;
  secondName: string;
  email: string;
  phone: string;
  appointment: string;
  topic: string;
  description: string;
  files: File[];
}

export default function CreateTicket() {
  const form = useForm<FormValues>({
    initialValues: {
      surname: "",
      name: "",
      secondName: "",
      email: "",
      phone: "",
      appointment: "",
      topic: "",
      description: "",
      files: [],
    },
    validate: {
      surname: (value) => (value ? null : "Фамилия обязательна"),
      name: (value) => (value ? null : "Имя обязательно"),
      secondName: (value) => (value ? null : "Отчество обязательно"),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Некорректный email"),
      phone: (value) => (value ? null : "Телефон обязателен"),
      topic: (value) => (value ? null : "Тема обязательна"),
      description: (value) => (value ? null : "Описание обязательно"),
    },
  });

  const handleSubmit = (values: FormValues) => {
    const params = new Map<string, string>();
    params.set("prefix", "config");
    params.set("comand", "SendToSupport");

    getAttachedFiles(values.files).then((attachedFiles) => {
      const body = {
        Фамилия: values.surname,
        Имя: values.name,
        Отчество: values.secondName,
        ЭлАдрес: values.email,
        Телефон: values.phone,
        Должность: values.appointment,
        Тема: values.topic,
        Вложения: attachedFiles,
        $content: values.description,
      };

      AxiosRequest(true, params, body).then((res) => {
        if (res.error) {
          RenderMessageBox(1, 1, "При отправке заявки возникла ошибка!");
        } else {
          RenderMessageBox(2, 1, res.Info);
        }
      });
    });
  };

  return (
    <Box component="form" onSubmit={form.onSubmit(handleSubmit)} style={{paddingRight: "5px"}}>
      <Grid gutter="xs">
        <Grid.Col span={6}>
          <TextInput
            label="Фамилия"
            required
            {...form.getInputProps("surname")}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Эл. адрес"
            required
            {...form.getInputProps("email")}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Имя"
            required
            {...form.getInputProps("name")}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Телефон"
            required
            {...form.getInputProps("phone")}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Отчество"
            required
            {...form.getInputProps("secondName")}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Должность"
            {...form.getInputProps("appointment")}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            label="Тема"
            required
            {...form.getInputProps("topic")}
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <Textarea
            label="Описание"
            required
            minRows={10}
            {...form.getInputProps("description")}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <FileUploadList
            files={form.values.files}
            setFiles={(files) => form.setFieldValue("files", files)}
            height={220}
          />
        </Grid.Col>
        <Grid.Col span={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="submit" variant="outline" style={{ marginRight: "10px" }}>
            Отправить
          </Button>
          <Button variant="outline" onClick={() => closeModal()}>
            Отмена
          </Button>
        </Grid.Col>
      </Grid>
    </Box>
  );
}
